import React, {useState, useEffect} from "react";

const SettingsAccuarcy = () => {
    const settingsAccuracy = sessionStorage.getItem("settingsAccuracy") ? sessionStorage.getItem("settingsAccuracy") : 90;
    const handleAccuracy = (e) => {
        e.preventDefault();
        sessionStorage.setItem("settingsAccuracy", e.target.value);
    }
    useEffect(() => {
        if(settingsAccuracy){
            document.querySelector('#settingsAccuracy').value = settingsAccuracy;
        }
        
    }, [])
    return (
        <div className="settingsAccuracy">
             <table style={{width:"100%"}}>
                <tbody>
                    <tr>
                        <td style={{width:"60%"}}>
                            Change Accuracy
                        </td>
                        <td>
                            <select id="settingsAccuracy" onChange={(e)=>handleAccuracy(e)}>
                                <option value="100">100%</option>
                                <option value="95">95%</option>
                                <option value="90">90%</option>
                                <option value="85">85%</option>
                                <option value="80">80%</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    )
} 

const SettingsSpeed = () => {
    const settingsSpeed = sessionStorage.getItem("settingsSpeed") ? sessionStorage.getItem("settingsSpeed") : 50;
    const handleSpeed = (e) => {
        e.preventDefault();
        sessionStorage.setItem("settingsSpeed", e.target.value);
    }
    useEffect(() => {
        if(settingsSpeed){
            document.querySelector('#settingsSpeed').value = settingsSpeed;
        }
        
    }, [])
    return (
        <div className="settingsSpeed">
            <table style={{width:"100%"}}>
                <tbody>
                    <tr>
                        <td style={{width:"60%"}}>
                            Change Speed
                        </td>
                        <td>
                            <select id="settingsSpeed" onChange={(e)=>handleSpeed(e)}>
                                <option value="50">50</option>
                                <option value="55">55</option>
                                <option value="60">60</option>
                                <option value="65">65</option>
                                <option value="70">70</option>
                                <option value="75">75</option>
                                <option value="80">80</option>
                                <option value="85">85</option>
                                <option value="90">90</option>
                                <option value="95">95</option>
                                <option value="100">100</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    )
}
const SettingsShowKeyboard = () => {
    const settingsShowKeyboard = sessionStorage.getItem("settingsShowKeyboard") ? sessionStorage.getItem("settingsShowKeyboard") : "On";
    const handleShowKeyboard = (e) => {
        e.preventDefault();
        sessionStorage.setItem("settingsShowKeyboard", e.target.value);
    }
    useEffect(() => {
        if(settingsShowKeyboard){
            document.querySelector('#settingsShowKeyboard').value = settingsShowKeyboard;
        }
        
    }, [])
    return (
        <div className="settingsShowKeyboard" >
            <span className="">Show Keyboard </span>
            <select id="settingsShowKeyboard" onChange={(e)=>handleShowKeyboard(e)}>
            <option value="On">On</option>
                <option value="Off">Off</option>
            </select>
        </div>
    )
}
const SettingsKeyboardSound = () => {
    const settingsKeyboardSound = sessionStorage.getItem("settingsKeyboardSound") ? sessionStorage.getItem("settingsKeyboardSound") : "Off";
    const handleKeyboardSound = (e) => {
        e.preventDefault();
        sessionStorage.setItem("settingsKeyboardSound", e.target.value);
    }
    useEffect(() => {
        if(settingsKeyboardSound){
            document.querySelector('#settingsKeyboardSound').value = settingsKeyboardSound;
        }
        
    }, [])
    return (
        <div className="settingsKeyboardSound" >
            <span className="">Keyboard Sound </span>
            <select id="settingsKeyboardSound" onChange={(e)=>handleKeyboardSound(e)}>
                <option value="On">On</option>
                <option value="Off">Off</option>
            </select>
        </div>
    )
}
const SettingsBibleVersion = () => {
    const settingsBibleVersion  = sessionStorage.getItem("settingsBibleVersion") ? sessionStorage.getItem("settingsBibleVersion") : "kjv_text";
    const handleBibleVersion = (e) => {
        e.preventDefault();
        sessionStorage.setItem("settingsBibleVersion", e.target.value);
    }
    useEffect(() => {
        if(settingsBibleVersion){
            document.querySelector('#settingsBibleVersion').value = settingsBibleVersion;
        }
        
    }, [])
    return (
        <div className="settingsBibleVersion" >
            <table>
                <tbody>
                    <tr>
                        <td>
                            Bible Version 
                        </td>
                        <td>
                            <select id="settingsBibleVersion" onChange={(e)=>handleBibleVersion(e)}>
                                <option value="kjv_text">KJV</option>
                                <option value="asv_text">ASV</option>
                                <option value="web_text">WEB</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    )
}
export { SettingsAccuarcy, SettingsSpeed, SettingsShowKeyboard, SettingsKeyboardSound, SettingsBibleVersion };