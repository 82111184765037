export const blogData = [
    { 
        title: 'Benefits of Typing', 
        summary: 'Learning typing, or touch typing, has several benefits that can positively impact both personal and professional aspects of life. Here are some of the key benefits:', 
        text: '',
        author: 'Typlogos/ChatGPT', 
        link:'typing-benefit',
        id: 1 
    },
    { title: 'Welcome party!', body: 'lorem ipsum...', author: 'Typlogos/ChatGPT', id: 2 },
    { title: 'Web dev top tips', body: 'lorem ipsum...', author: 'Typlogos/ChatGPT', id: 3 }
]