import React, {useEffect, useState, useRef} from "react";
import {ChapterVerseCount} from '../data/main/bible-book-chapter-verse-count'
import {bookNames} from '../data/main/book-names'
import { NavLink, useNavigate, Redirect } from "react-router-dom";
import { SettingsBibleVersion, SettingsKeyboardSound, SettingsShowKeyboard } from "./Settings";
const MainSettings = () => {
    const navigate = useNavigate();
    console.log(ChapterVerseCount[61]["chapters"]);
    useEffect(() => {
        //console.log(ChapterVerseCount[0]);
    }, []);
    const handleOnClick = (e) => {
        //document.querySelector(".main-settings").style.display = "none";
        document.querySelector(".main-settings").classList.add('main-settings-hide');
    }
    const handleOnChange = (e) => {
        e.preventDefault();
        sessionStorage.setItem("version", e.target.value);
        //setVersion(e.target.value)
        console.log(e.target.value);
        console.log(sessionStorage);
        document.querySelector(".main-settings").classList.add('main-settings-hide');
        window.location.reload();
    }
    const handleOnClickLink = (e) => {
        e.preventDefault();
        console.log(e.target.parentNode.id, e.target.parentNode.href)
        //navigate(e.target.parentNode.id)
        //return <Redirect to={e.target.parentNode.href} />
        document.querySelector(".main-settings").classList.add('main-settings-hide');
        return
    }
    const bookOnChange = (e) => {
        e.preventDefault();
        console.log(e.target.value);
        let chapterHTML = '';
        /*for(let i=1; i<=e.target.value; i++){
            chapterHTML += "<option>Chapter "+ i +"<option>";
        }*/ChapterVerseCount[e.target.value]["chapters"].map((b) =>{
            console.log(b.chapter)
        })
        document.querySelector("#chapterOnChange").innerHTML = chapterHTML;
    }
    const chapterOnChange = (e) => {
        e.preventDefault();
    }
    const verseOnChange = (e) => {
        e.preventDefault();
    }

    return (
        <div className="main-settings">
            <div className="main-settings-box">
                <h3>Settings</h3>
                <span className="close" onClick={(e) => handleOnClick(e)}><span className="material-symbols-outlined">cancel</span></span>
                <div style={{display:"inline-flex"}}>
                    {<SettingsBibleVersion></SettingsBibleVersion>}
                    &nbsp;&nbsp;&nbsp;
                    {<SettingsShowKeyboard />}
                    &nbsp;&nbsp;&nbsp;
                    {<SettingsKeyboardSound></SettingsKeyboardSound>}
                </div>
                {/*<div>
                    <span className="main-settings-span">Show Keyboard </span>
                    <select>
                        <option>On</option>
                        <option>Off</option>
                    </select>
                </div>
                <div>
                    <span className="main-settings-span">Key Sounds </span>
                    <select>
                        <option>On</option>
                        <option>Off</option>
                    </select>
                </div>*/}
                <div className="tests-row" style={{margin: "15px"}}>
                        { bookNames.map((b) => //{
                                //v = sessionStorage.getItem("book-verse-"+b.id) ? sessionStorage.getItem("book-verse-"+b.id):0
                                ( b.id > 39 ?
                                <div className="main-column" key={b.id}  style={{width:"20%"}}>
                                    <div className={"tests-card white-bk" }>
                                        { 
                                            //v = parseInt(sessionStorage.getItem("book-verse-"+b.id) ? sessionStorage.getItem("book-verse-"+b.id):0)
                                        }
                                        <NavLink to={b.id} id={b.id} onClick={() => window.location.href='/'+b.id}>
                                            {/*<h3 className=""><span className="material-symbols-outlined" style={{verticalAlign: "bottom"}}>auto_stories</span> {b.book}</h3>*/}
                                            <p className="txt-sm txt-blue" style={{lineHeight: "15px"}}>{b.book} <br></br> [{b.chapter} Chapter(s), {b.verse} Verses]</p>
                                            <div className="main-progress-bar nav-bg1">
                                                <div className={"main-progress-percent-Gospels css-"+ b.category}>{Math.round((sessionStorage.getItem("book-verse-"+b.id)/b.verse)*100)}% Completed</div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div> : '')
                                //}
                            )
                        }
                </div>
                {/*<div>
                    <span className="main-settings-span">Book</span>
                    <select onChange={(e)=>bookOnChange(e)}>
                        <option value="61">1 John</option>
                        <option value="42">John</option>
                        <option value="39">Mat</option>
                    </select>
                    <select id="chapterOnChange" onChange={(e)=>chapterOnChange(e)}>
                    </select>
                    <select id="verseOnChange" onChange={(e)=>verseOnChange(e)}>
                    </select>
                </div>*/}
                
                                
            </div>
        </div>
    )
}

export default MainSettings