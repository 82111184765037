import React, {useEffect}from "react";
import { NavLink } from "react-router-dom";
import { useParams} from "react-router";
import { useAuth } from "../components/Auth";
import './nav.css'

const Nav = () => {
    const params = useParams();
    const param = params;
    let i = 0;
    let txt = 'TYPLOGOS.com'; /* The text */
    let speed = 80; /* The speed/duration of the effect in milliseconds */
    console.log('param nav', param)

    const typeLogo = () =>  {
       // document.querySelector("#logo_txt").innerHTML += ''
        
        //document.querySelector("#logo_txt").innerHTML = <span className="material-symbols-outlined">keyboard_keys</span>;
        //txt = 'TYPLOGOS.com'
        if (i < txt.length) {
            //document.querySelector("#logo_txt").innerHTML 
            document.querySelector("#logo_txt").innerHTML += txt.charAt(i);
            i++;
            setTimeout(typeLogo, speed);
        }
    }
    const auth = useAuth()
    const user = sessionStorage.getItem('email')
    const eKeys = sessionStorage.getItem('errorKeysLocal')
    let eKeysArray = eKeys && eKeys.split(",")
    //let unique = [...new Set(eKeysArray)];
    const unique = eKeysArray  && eKeysArray.filter((value, index, eKeys) => eKeys.indexOf(value) === index);
    const openNav = (e) => {
        //e.preventDefault();
        document.querySelector("#rightSideNav").style.width = "15%";
        //document.querySelector("main").style.marginRight = "12%";
        //document.querySelector("nav").style.marginRight = "12%";
        //document.querySelector("#loginSignup").style.display = "none";
        document.querySelector("#navDefaultOpen").click();
    }
    const closeNav = () => {
        document.querySelector("#rightSideNav").style.width = "0";
        document.querySelector("main").style.marginRight= "0";
        document.querySelector("nav").style.marginRight= "0";
    }
    const openNavTab =(evt, tab) => {

        let i, tabcontent, tablinks;
        tabcontent = document.querySelectorAll(".nav-tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.querySelectorAll(".nav-tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.querySelector("#"+tab).style.display = "block";
        evt.currentTarget.className += " active";
    }

    useEffect(() => {
        //document.querySelector("#navDefaultOpen").click();
        //document.querySelector("#loginSignupOpen").click();
        typeLogo()
        //document.querySelector("#logo_txt").innerHTML = '<span class="material-symbols-outlined">keyboard_keys</span>';
        // document.querySelector("#logo_txt").innerHTML += '';
        
        console.log('auth email', user, param)
    }, [])
    return (
        <nav className="bg-nav">
            <div className="nav-left">
                {/*<img src="logo192.png" alt="Typlogos.com" className="logo"/>*/}
                <div className="logo">
                <NavLink id="logo_txt" to="/genesis"><span className="material-symbols-outlined">keyboard_keys</span>
                </NavLink>
                </div>
                
                {/*<ul>
                    <li>
                        <span className="material-symbols-outlined">home</span>
                        About
                    </li>
                    
                </ul>*/}
            </div>
            <div className="nav-right">
                <ul>
                     <li>
                        <NavLink to="books">
                            <span className="material-symbols-outlined nav-li">auto_stories</span>
                            <span className="hide-mobile">Bible Books</span>
                        </NavLink>
                    </li>
                    
                    <li>
                        <NavLink to="tests/2">
                            <span className="material-symbols-outlined nav-li">edit_note</span>
                            <span className="hide-mobile">Test</span>
                        </NavLink>
                    </li>
                    
                    <li>
                        <NavLink to="lessons">
                            <span className="material-symbols-outlined nav-li">list</span>
                            <span className="hide-mobile">Lessons</span>
                        </NavLink>
                    </li>
                    
                    { user && 
                        <li>
                            <NavLink to="page/login">
                                <span className="material-symbols-outlined nav-li">manage_accounts</span>
                            </NavLink>
                        </li>
                    }
                    { !user && 
                        <li>
                            <NavLink to="page/login">
                                <span className="material-symbols-outlined nav-li">login</span>
                                <span className="hide-mobile">Sign In/Up</span>
                            </NavLink>
                        </li>
                    }
                    
                    <li>
                        <NavLink to="#" onClick={(e)=>openNav(e)}>
                            <span className="material-symbols-outlined nav-li">more_vert</span>
                        </NavLink>
                    </li>
                </ul>
            </div>


        <div id="rightSideNav" className="sidenav">
                <a href="#" className="closebtn" onClick={closeNav}>&times;</a>

            <div className="nav-tab">
                <button className="nav-tablinks" onClick={(e)=>openNavTab(e, 'Verses')} id="navDefaultOpen">
                    <span className="material-symbols-outlined">auto_stories</span> Type
                </button>
                {/*<button className="nav-tablinks" onClick={(e)=>openNavTab(e, 'Tests')} >
                    <span className="material-symbols-outlined nav-li">edit_note</span> Tests
                </button>*/}
                <button className="nav-tablinks" onClick={(e)=>openNavTab(e, 'Progress')}>
                    <span className="material-symbols-outlined">monitoring</span> Progress
                </button>
            </div>

            {/*<div id="Tests" className="nav-tabcontent">
                <div className="nav-tests-card tests-b11">
                    <NavLink to="tests/1-min-test-1">
                        <p>One Minute Test</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">A quick brown fox jumps over the little lazy dog.</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card quotes-b24">
                    <NavLink to="tests/1-min-test-2">
                        <p>One Minute Test</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">For God so loved the world that he gave His only Son so that whoever...</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card quotes-b22">
                    <NavLink to="tests/1-min-test-3">
                        <p>One Minute Test</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">The Lord is my shepherd, I will not be in need. He lets me lie...</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card quotes-b23">
                    <NavLink to="tests/1-min-test-4">
                        <p>One Minute Test</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">Blessed are those whose way is blameless, Who walk in the ...</p>
                    </NavLink>
                </div>
            </div>*/}

            <div id="Verses" className="nav-tabcontent">
                <div className="nav-tests-card nav-bg6">
                    <NavLink to="quotes/bible-random">
                        <h2>Random Bible Verses</h2>
                        <p className="txt-sm">31102 verses / 40 Authors</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg2">
                    <NavLink to="quotes/bible-random-ot">
                        <h2>Random Bible Verses(OT)</h2>
                        <p className="txt-sm">39 Books / 23145 Verses</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg3">
                    <NavLink to="quotes/bible-random-nt">
                        <h2>Random Bible Verses(NT)</h2>
                        <p className="txt-sm">27 Books / 9 Authors</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg1">
                    <NavLink to="quotes/random">
                    <h2>Random Quotes</h2>
                        <p className="txt-sm">1500k+ quotes / 100+ Authors</p>
                    </NavLink>
                </div>
                {/*<div className="nav-tests-card nav-bg2">
                    <NavLink to="quotes/10-commandments">
                        <span className="txt-yellow9">The Ten Commandments</span>
                        <p className="txt-sm">Exodus 20</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg3">
                    <NavLink to="quotes/bible-love">
                        <span className="txt-yellow9">Bible Verses</span>
                        <p className="txt-sm">On Love</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg4">
                    <NavLink to="quotes/bible-faith">
                    <span className="txt-yellow9">Bible Verses</span>
                        <p className="txt-sm">On Faith</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg5">
                    <NavLink to="quotes/bible-wisdom">
                        <span className="txt-yellow9">Bible Verses</span>
                        <p className="txt-sm">On Wisdom</p>
                    </NavLink>
                </div>*/}
                
            </div>
            <div id="Progress" className="nav-tabcontent">
            <div className="nav-tests-card nav-bg4">
                    <NavLink to="page/login">
                        <h2 style={{fontSize:"1rem"}}><span className="material-symbols-outlined nav-li" style={{verticalAlign:"middle"}}>login</span> Login</h2>
                        <p className="txt-sm">to save your progress!</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg6">
                    <NavLink to="lessons">
                        <h2>Problem Keys</h2>
                        <span className="txt-yellow">{unique ? unique.join(" "): "No error key yet!"}</span>
                        <p className="txt-sm">Click to Practice and Improve!</p>
                    </NavLink>
                </div>
                <div className="nav-tests-card nav-bg1">
                    <NavLink to="tests/1">
                        <table className="nav-table" style={{width:"100%"}}>
                            <tbody>
                                <tr>
                                    <td><h2>Avg Accuracy: {sessionStorage.getItem('avgAccuracy') ? sessionStorage.getItem('avgAccuracy') : 0}%</h2></td>
                                </tr>
                                <tr>
                                    <td><h2>Avg. Speed: {sessionStorage.getItem('avgWPM') ? sessionStorage.getItem('avgWPM') : 0} wpm</h2></td>
                                </tr>
                                <tr>
                                    <td><h2>Time Spen: {sessionStorage.getItem('totSeconds') ? sessionStorage.getItem('totSeconds') : 0} Secst</h2></td>
                                </tr>
                            </tbody>
                        </table>
                    </NavLink>
                </div>
            </div>
        </div>


        
            

        </nav>
        
    )
}

export default Nav;