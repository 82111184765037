import React, {useState, useEffect} from "react";
//import { useAuth } from "./Auth";
import { useAuth } from "../components/Auth";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

const Contact = () => {
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [summary, setSummary] = useState("");
    const [error, setError] = useState(null);
    const auth = useAuth();
    const location = useLocation()
    const crumbs = location.pathname.split('/')
    console.log("bread", crumbs[2], crumbs[3])

    const navigate = useNavigate();
    //if(auth.user)
    
    const loginSignupTab =(evt, tab) => {
        console.log("tab",tab);
        let i, tabcontent, tablinks;
        tabcontent = document.querySelectorAll(".login-tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.querySelectorAll(".nav-tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.querySelector("#"+tab).style.display = "block";
        evt.currentTarget.className += " active";
    }
    const handleLogin = async (e) => { 
        e.preventDefault();
        
        const user = {email: email,subject:subject, summary:summary}
        //console.log(user)
        const response = await fetch('/api/users/contact', {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        //console.log(user)
        //console.log(response)
        
        const json = await response.json();
        console.log(json)
        if(!response.ok){
            setError(json.error)
        }
        if(response.ok){
            setError(null)
            console.log("contact", json)
            //auth.login(json.email)
            navigate("/")
            //console.log("auth", auth, "uswr", user);
            //setIsLoggedIn(true)
        }

    }
    



    useEffect(() => {
        document.querySelector("#LoginOpen").click();
    },[])

    return (


        <div id="contact">


                    

                        <div className="nav-tab nav-tab-contact" >
                            <button className="nav-tablinks" onClick={(e)=>loginSignupTab(e, 'Login')} id="LoginOpen" style={{width:"100%"}}> 
                                <span className="material-symbols-outlined nav-li">login</span> Report the Issue / Request a Feature / Contact Us
                            </button>
                            {/*<button className="nav-tablinks" onClick={(e)=>loginSignupTab(e, 'Signup')} id="SignupOpen">
                                <span className="material-symbols-outlined">app_registration</span> Sign Up
                            </button>*/}
                        </div>
     
                        <div id="Login" className="login-tabcontent">
                            
                            <form  method="post" onSubmit={handleLogin}>
                                <div className="container">
                                {error && <p className="red error">{error }</p>}
                                        <label htmlFor="uname">Email</label>
                                        <input type="email" placeholder="Enter Email"  value={email} required 
                                        onChange={(e) => setEmail(e.target.value)} />

                                        <label htmlFor="subject">Subject</label>
                                        <input type="text" placeholder="Enter Title/Subject"  value={subject} required 
                                        onChange={(e) => setSubject(e.target.value)} />

                                        <label htmlFor="subject">Summary</label>
                                        <textarea placeholder="Enter Summary/Description"  value={summary} required 
                                        onChange={(e) => setSummary(e.target.value)} />
                                </div>

                                <div className="container">
                                    <button type="submit" className="btn nav-bg5">Send</button>
                                </div>
                            </form>
                        </div>

                        {/*<div id="Signup" className="login-tabcontent">
                            <form  name="Signup" onSubmit={handleSignUp} method="post">
                                    <div className="container">
                                        <p>Please fill in this form to create an account.</p>
                                        {error && <p className="red error">{error }</p>}
                                        <label htmlFor="uname">Email</label>
                                        <input type="email" placeholder="Enter Email"  value={emailSignup} required 
                                        onChange={(e) => setEmailSignup(e.target.value)} />

                                        <label htmlFor="psw">Password</label>
                                        <input type="password" placeholder="Enter Password" value={passwordSignup} required 
                                        onChange={(e) => setPasswordSignup(e.target.value)} />

                                        <label htmlFor="psw">Repeat Password</label>
                                        <input type="password" placeholder="Repeat Password" value={rePasswordSignup} required 
                                        onChange={(e) => setRePasswordSignup(e.target.value)}  />
                                        
                                        <label>
                                        <input type="checkbox" checked="checked" name="remember" readOnly /> Remember me
                                        </label>
                                    </div>

                                    <div className="container">
                                        <button type="submit" className="btn nav-bg5">Sign Up</button>
                                    </div>
                                </form>
                            </div>*/}


                    

                    

                    

                </div>



    )
  }

  export default Contact