export default function Faq() {
    return (
      <div className="page">
        <h2 className="page-title"><span className="material-symbols-outlined">quiz</span> Frequently Asked Questions</h2>
        <div className="page-text">
            <br></br>
            <h3>Question: What is typed Word?</h3>
            <p>Since words can have many character, so for typing calculation 5 letter/chars typed is considerd a word. </p>
            <hr></hr><br></br>
            <h3>Question: What is Gross Words?</h3>
            <p>Gross Words is total number of words typed, which includes both correct and incorrect words. </p>
            <hr></hr><br></br>
            <h3>Question: What is Gross WPM?</h3>            
            <p>Gross WPM is total number of gross words typed in a minute</p>
            <p> <b>Gross WPM = Gross Words / Time taken in minutes</b> </p>
            <pre>
              Example 1. - If you typed total 305 letters/chars out of which 300 are correct and 5 are incorrect in a duration of
              1 minute. Hence, <br />
              Total Typed Chars = 305, Total Typed Words = 305/5 = 61 words<br />
              Correct Words = 300, Total Typed Words = 300/5 = 60 words<br />
              Incorrect Words = 2<br />
              Gross words per Minute (GWPM) = 61/1 = 61 words per minute<br />
              Net words per Minute (NWPM) = 60/1 = 60 words per minute<br />
              Accuracy = 61*60 = 98.36 % 
            </pre>
            <hr></hr><br></br>
            <h3>Question: What is Net Words?</h3>
            <p>Net Words is total number of correct words typed. </p>
            <hr></hr><br></br>
            <h3>Question: What is Net WPM?</h3>            
            <p>Net WPM is total number of net words typed in a minute</p>
            <p> Net WPM = Net Words / Time taken in minutes </p>
            <hr></hr><br></br>
            
            <h3>Question: What is Accuracy?</h3>
            <p>Accuracy i  number of correct words typed. </p>
            <p> <b>Accuracy = Net WPM * 100 / Gross WPM </b> </p>
            <hr></hr><br></br>
        </div>
     </div>
    )
  }