export default function Pricavy() {
    return (
      <div className="page">
        <h2 className="page-title bg-main-title-99"><span className="material-symbols-outlined">policy</span> Privacy Policy</h2>
        <div className="page-text">
          <p>This privacy page discloses what information we gather and how we use it</p>
          <p>When you Sign in/sign up for a account, we require certain information, such as your name, email address, phone number, date of birth, gender, and location, etc.</p>
          <p>We may place a text file called a “cookie” in the browser files of your computer. The cookie itself does not contain Personal Information although it will enable us to relate your use of this site to information that you have specifically and knowingly provided. But the only personal information a cookie can contain is information you supply yourself. A cookie can’t read data off your hard disk or read cookie files created by other sites.</p>
          <p>TYPLOGOS.com uses any information voluntarily given by our users to enhance their experience on our site, provide great customer service, a safer and trusted environment.</p>
          <p>TYPLOGOS.com reserves the right to modify this Privacy Policy at any time in accordance with this provision. We will periodically make changes to this Policy, and will post the revised Policy on the TYPLOGOS.com platform. If you disagree with any revisions to our Privacy Policy, you may terminate this Agreement immediately. If you do not terminate your Agreement before the date of the newest Privacy Policy is posted, your continued access of the platform will constitute acceptance of the revised Policy.</p>
          <p>You may contact us at: typlogos@gmail.com</p>
        </div>
     </div>
    )
  }