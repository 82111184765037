import { Outlet} from "react-router-dom";
import Nav from './nav'
import Footer from "./footer";
import Breadcrumbs from "../components/Breadcrumbs";
import { useState, useEffect } from "react";
import {StickyShareButtons} from 'sharethis-reactjs';
import {setDefaultSession} from '../components/common'

export default function HomeLayout() {
  useEffect(() => {
    setDefaultSession()
    //document.head.innerHTML+=``
  }, []);
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <div className="home-layout">
        {/*<div className="share-this">
            <div className="fb-like"  data-href="https://typlogos.com" target="_top" data-width="150" data-layout="box_count" data-action="" data-size="large" data-share=""></div><br></br>
            <div className="fb-share-button" data-href="https://typlogos.com" data-layout="box_count" data-size="large"><a target="_blank" href="https://typlogos.com" className="fb-xfbml-parse-ignore">Share</a></div>
      </div>*/}
      <div className="share-this-2">
            {/*<div className="fb-like" data-href="https://typlogos.com" target="_top" data-width="450" data-layout="box_count" data-action="" data-size="" data-share="true"></div>*/}
                <StickyShareButtons
                    config={{
                        alignment: 'left',    // alignment of buttons (left, right)
                        color: 'social',      // set the color of buttons (social, white)
                        enabled: true,        // show/hide buttons (true, false)
                        font_size: 16,        // font size for the buttons
                        hide_desktop: false,  // hide buttons on desktop (true, false)
                        labels: 'counts',     // button labels (cta, counts, null)
                        language: 'en',       // which language to use (see LANGUAGES)
                        min_count: 0,         // hide react counts less than min_count (INTEGER)
                        networks: [           // which networks to include (see SHARING NETWORKS)
                        'facebook',
                        'twitter',
                        'pinterest',
                        'linkedin',
                        'email'
                        ],
                        padding: 12,          // padding within buttons (INTEGER)
                        radius: 4,            // the corner radius on each button (INTEGER)
                        show_total: true,     // show/hide the total share count (true, false)
                        show_mobile: false,    // show/hide the buttons on mobile (true, false)
                        show_toggle: true,    // show/hide the toggle buttons (true, false)
                        size: 48,             // the size of each button (INTEGER)
                        top: 200,             // offset in pixels from the top of the page


                        // OPTIONAL PARAMETERS

                        url: 'https://typlogos.com', // (defaults to current url)
                        image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                        description: 'custom text',       // (defaults to og:description or twitter:description)
                        title: 'custom title',            // (defaults to og:title or twitter:title)
                        message: 'custom email text',     // (only for email sharing)
                        subject: 'custom email subject',  // (only for email sharing)
                        username: 'custom twitter handle' // (only for twitter sharing)

                    }}
                />           
            </div>
      <header>
       <Nav></Nav>
      </header>
      {/*<div className="breadcrumb"> Home &raquo; About</div>*/}
      {/*<Breadcrumbs></Breadcrumbs> */}
      <main>
        <Outlet />
      </main>
      
      <footer>
        <Footer></Footer>
      </footer>
      <audio id="errorKeyAudio">
        <source src="/sound/error.wav" type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
      <audio id="keyAudio">
        <source src="/sound/computer-key.wav" type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
    </div>
  )
}