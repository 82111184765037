import React, { useContext} from "react"
import KeyBoardContext  from "../tests/typingTest";

import './keyboard.css';
import { object } from "prop-types";

const KeyboardActiveKey = (key) => {
    //return (
    document.querySelectorAll('.key').forEach(el => el.classList.remove('is-active'))
    if(key){
        if(key.trim() === ''){
            document.querySelector(".space-bar").classList.add('is-active');
        } else if(key === ','){
            document.querySelector(".key-comma").classList.add('is-active');
        } else if(key === ';' || key === ':'){
            document.querySelector(".key-semicolon").classList.add('is-active');
        } else if(key === '('){
            document.querySelector(".key-lbracket").classList.add('is-active');
        } else if(key === '{' || key === '['){
            document.querySelector(".key-lbraces").classList.add('is-active');
        } else if(key === '}' || key === ']'){
            document.querySelector(".key-rbraces").classList.add('is-active');
        } else if(key === ')'){
            document.querySelector(".key-rbracket").classList.add('is-active');
        } else if(key === "?"){
            document.querySelector(".key-question").classList.add('is-active');
        } else if(key === '.'){
            document.querySelector(".key-fullstop").classList.add('is-active');
        } else if(key === "'"){
            document.querySelector(".key-quote").classList.add('is-active');
        } else if(key === '"'){
            document.querySelector(".key-quote").classList.add('is-active');
        } else if(key === "-"){
            document.querySelector(".key-hyphen").classList.add('is-active');
        } else if(key === "!"){
            document.querySelector(".key-1").classList.add('is-active');
        } else if(key === "1"){
            document.querySelector(".key-1").classList.add('is-active');
        } else if(key === "2" || key === "@"){
            document.querySelector(".key-2").classList.add('is-active');
        } else if(key === "3" || key === "#"){
            document.querySelector(".key-3").classList.add('is-active');
        } else if(key === "4" || key === "$"){
            document.querySelector(".key-4").classList.add('is-active');
        } else if(key === "5" || key === "%"){
            document.querySelector(".key-5").classList.add('is-active');
        } else if(key === "6" || key === "^"){
            document.querySelector(".key-6").classList.add('is-active');
        } else if(key === "7" || key === "&"){
            document.querySelector(".key-7").classList.add('is-active');
        } else if(key === "8" || key === "*"){
            document.querySelector(".key-8").classList.add('is-active');
        } else if(key === "9"){
            document.querySelector(".key-9").classList.add('is-active');
        } else if(key === "0"){
            document.querySelector(".key-0").classList.add('is-active');
        } else if(key === "+" || key === "="){
            document.querySelector(".key-0").classList.add('is-active');
        } else {
            document.querySelector(".key-"+key.toLowerCase()).classList.add('is-active');
        }
    }
    //)
}

const KBHandleKeyDown = (object) => {

}

const KBHandleKeyUp = (e) => {
    
}

const CapsLockOverlay = (e, caps) => {
    
    if(caps){
        //keyState++;
        console.log('caps on..');
        document.querySelector(".overlay").classList.remove('overlay-hide');
        document.querySelector(".overlay").classList.add('overlay-show');
        e.preventDefault();
        e.stopPropagation();
        return false;
    } else {
        document.querySelector(".overlay").classList.add('overlay-hide');
    }
}

const Keyboard = (props) => {

    
    let kb = props.KeyboardFrom;
    //let KBKey = useContext(KeyBoardContext);

    

    const handleOverlay = (e) =>{
        document.querySelector(".overlay").classList.add('overlay-hide');
        return; 
    }

    
    
    return (
        
        <div className="keyboard">
            <div className="overlay" onClick={handleOverlay}>
                <div className="overlay-text">WARNING! <br />Your Keyboard's Caps Lock is on.</div>
            </div>
                <div className="keyboard-row thin">
                    <div className="key lowercase">Esc</div>
                    <div className="key function lo-bright"><span>f1</span></div>
                    <div className="key function hi-bright"><span>f2</span></div>
                    <div className="key function expose"><span>f3</span></div>
                    <div className="key function tilez"><span>f4</span></div>
                    <div className="key function"><span>f5</span></div>
                    <div className="key function"><span>f6</span></div>
                    <div className="key function rewind"><span>f7</span></div>
                    <div className="key function play-pause"><span>f8</span></div>
                    <div className="key function ffwd"><span>f9</span></div>
                    <div className="key function mute"><span>f10</span></div>
                    <div className="key function lo-vol"><span>f11</span></div>
                    <div className="key function hi-vol"><span>f12</span></div>
                    <div className="key eject"></div>
                </div>
                <div className="keyboard-row">
                    <div className="key"><span>~<br/>`</span></div>
                    <div className="key key-1"><span>!<br/>1</span></div>
                    <div className="key key-2"><span>@<br/>2</span></div>
                    <div className="key key-3"><span>#<br/>3</span></div>
                    <div className="key key-4"><span>$<br/>4</span></div>
                    <div className="key key-5"><span>%<br/>5</span></div>
                    <div className="key key-6"><span>^<br/>6</span></div>
                    <div className="key key-7"><span>&amp;<br/>7</span></div>
                    <div className="key key-8"><span>*<br/>8</span></div>
                    <div className="key key-lbracket key-9 key-bracket"><span>(<br/>9</span></div>
                    <div className="key key-rbracket key-0 key-bracket"><span>)<br/>0</span></div>
                    <div className="key key-hyphen"><span>_<br/>-</span></div>
                    <div className="key"><span>+<br/>=</span></div>
                    <div className="key extra-size lowercase lower-right"><span className="lower-row-text">delete</span></div>
                </div>
                <div className="keyboard-row">
                    <div className="key extra-size lowercase lower-left"><span className="lower-row-text">tab</span></div>
                    <div className="key key-q">Q</div>
                    <div className="key key-w">W</div>
                    <div className="key key-e">E</div>
                    <div className="key key-r">R</div>
                    <div className="key key-t">T</div>
                    <div className="key key-y">Y</div>
                    <div className="key key-u">U</div>
                    <div className="key key-i">I</div>
                    <div className="key key-o">O</div>
                    <div className="key key-p">P</div>
                    <div className="key key-lbraces"><span>&#123;<br/>[</span></div>
                    <div className="key key-rbraces"><span>&#125;<br/>]</span></div>
                    <div className="key"><span>|<br/>\</span></div>
                </div>
                <div className="keyboard-row">
                    <div className="key lowercase lower-left extra-size-two"><span className="lower-row-text">caps lock</span><span className="absolute-left caps-dot">&bull;</span></div>
                    <div className="key key-a key-65">A</div>
                    <div className="key key-s key-83">S</div>
                    <div className="key key-d key-68">D</div>
                    <div className="key key-f key-70">F</div>
                    <div className="key key-g key-71">G</div>
                    <div className="key key-h key-72">H</div>
                    <div className="key key-j key-74">J</div>
                    <div className="key key-k key-75">K</div>
                    <div className="key key-l key-76">L</div>
                    <div className="key key-semicolon key-colon"><span>:<br/>;</span></div>
                    <div className="key key-quote"><span>"<br/>'</span></div>
                    <div className="key extra-size-two lowercase lower-right"><span className="lower-row-text">return</span><span className="absolute-right">enter</span></div>
                </div>
                <div className="keyboard-row">
                    <div className="key double-size lowercase lower-left"><span className="lower-row-text">shift</span></div>
                    <div className="key key-z">Z</div>
                    <div className="key key-x">X</div>
                    <div className="key key-c">C</div>
                    <div className="key key-v">V</div>
                    <div className="key key-b">B</div>
                    <div className="key key-n">N</div>
                    <div className="key key-m">M</div>
                    <div className="key key-comma"><span>&lt;<br/>,</span></div>
                    <div className="key key-fullstop"><span>&gt;<br/>.</span></div>
                    <div className="key key-question"><span>?<br/>/</span></div>
                    <div className="key double-size lowercase lower-right"><span className="lower-row-text">shift</span></div>
                </div>
                <div className="keyboard-row bottom-row">
                    <div className="key lower-left lowercase"><span className="lower-row-text">fn</span></div>
                    <div className="key lower-left lowercase"><span className="lower-row-text">control</span></div>
                    <div className="key lower-left lowercase"><span className="lower-row-text">option</span><span className="absolute-left">alt</span></div>
                    <div className="key lower-center lowercase extra-size-two"><span className="lower-row-text">command</span><span className="absolute-right icon">&#8984;</span></div>
                    <div className="key space-bar"></div>
                    <div className="key lower-center lowercase extra-size-two"><span className="lower-row-text">command</span><span className="absolute-left icon">&#8984;</span></div>
                    <div className="key lower-right lowercase"><span className="lower-row-text">option</span><span className="absolute-right">alt</span></div>
                    <div className="arrows">
                        <div className="key">&#9668;</div>
                        <div className="up-down">
                            <div className="key">&#9650;</div>
                            <div className="key">&#9660;</div>
                        </div>
                        <div className="key">&#9658;</div>
                    </div>
                </div>
            </div>
    )
}

export {Keyboard, KeyboardActiveKey, CapsLockOverlay };