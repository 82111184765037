import React, {useEffect, useState, useRef} from "react";
import { useParams, useLocation, Redirect, useNavigate, redirect } from "react-router-dom";
import parse from 'html-react-parser'
import {Keyboard, KeyboardActiveKey, CapsLockOverlay} from "../components/keyboard";
import {basicLessons, beginnerLessons, intermediateLessons, advanceLessons} from '../data/Lessons/Lessons-data'
import {StatsSideBar, StatsMainBar} from "../components/Stats-sidebar";
import { sessionSetItem, sessionGetItem } from "../components/common";
import { useAuth } from "../components/Auth";

const TypingLesson2 = () => {
    const navigate = useNavigate();
    const auth = useAuth()
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    let object = '';
    const params = useParams();
    console.log(params.lesson);
    if(params.lesson == 'basic'){
        object = basicLessons;
    }
    if(params.lesson == 'beginner'){
        object = beginnerLessons;
    }
    if(params.lesson == 'intermediate'){
        object = intermediateLessons;
    }
    if(params.lesson == 'advance'){
        object = advanceLessons;
    }
    let check = [5, 9, 10, 13]
    
    let setLesson = 0;
    if(params.id){
        setLesson = params.id - 1;
        console.log('paramsfdf11', params.id, setLesson)
    }  
    if(params.id > object.length){
        setLesson = 0;
        console.log('paramsfdf222', setLesson)
    }
    let boxletter = 'letter';
    let boxDiv = 'lesson-text';
    //if(check.includes(params.id)){
    /*if(params.id == 5 || params.id == 9 || params.id == 10 || params.id == 13 || 
        params.id == 18 || params.id == 19 || params.id == 20 || params.id == 21 ||
        params.id == 22 || params.id == 23){*/
    if(params.id == 5 || params.id == 9 || params.id == 10 || params.id == 13 ||
        params.id == 18 || params.id == 19 || params.id == 20 ||
        params.lesson == 'beginner'){
        boxletter = 'verse';
        boxDiv = 'text';
    }


    console.log('params', params.lesson, setLesson, object.length)
    
    const basic = {}
    const handleClickQuote = (e) => {
        e.preventDefault();
        document.querySelector(".quote-result-card").classList.remove('quote-result-card-show');
        document.querySelector(".quote-result-card").classList.add('quote-result-card-hide');
    }

    let [firstVerse, setFirstVerse] = useState(setLesson);
    const totalVerses = object.length - 1;
    let str = [...object[firstVerse]['text']];
    let stra = object[firstVerse]['text'];
    

    let strLength = str.length;
    console.log(strLength);

    let caps = 0
    let seconds = 0
    let accuracy = 0
    let errorKey = []
    let verse = str.map((v, index) => {
        return '<div id="chr_'+index+'" className="'+boxletter+'">'+v+'</div>';
    })
    let [firstVerseText, setFirstVerseText] = useState(verse);
    let [mainTitle, setMainTitle] = useState(object[firstVerse]['title']);
    let startTime, endTime;
    const start = () => {
        startTime = new Date().getTime();
    }
    const end = () => {
        endTime = new Date().getTime();
        let timeDiff = parseInt(endTime) - parseInt(startTime);
        console.log("diff", startTime, endTime)
        seconds = Math.round(timeDiff/1000);
    }
  

    let key = 0;

    useEffect(() => {
       
    }, [])
    const avgStats = () => {
        if(sessionStorage.getItem('avgAccuracy')){
            const avgAccuracy = Math.round((parseInt(sessionStorage.getItem('avgAccuracy')) + accuracy)/2)
            sessionStorage.setItem('avgAccuracy', avgAccuracy)
        } else {
            sessionStorage.setItem('avgAccuracy', accuracy)
        }
        /*if(sessionStorage.getItem('avgSeconds')){
            const avgSeconds = Math.round((parseInt(sessionStorage.getItem('avgSeconds')) + seconds)/2)
            sessionStorage.setItem('avgSeconds', avgSeconds)
        } else {
            sessionStorage.setItem('avgSeconds', seconds)
        }*/
        if(sessionStorage.getItem('totSeconds')){
            const totSeconds = Math.round((parseInt(sessionStorage.getItem('totSeconds')) + seconds))
            sessionStorage.setItem('totSeconds', totSeconds)
        } else {
            sessionStorage.setItem('totSeconds', seconds)
        }
        const avgWords = (strLength / 5)
        const WPM = Math.round(avgWords * (60 / seconds))
        if(sessionStorage.getItem('avgWPM')){
            const avgWPM = Math.round((parseInt(sessionStorage.getItem('avgWPM')) + WPM)/2)
            sessionStorage.setItem('avgWPM', avgWPM)
        } else {
            sessionStorage.setItem('avgWPM', WPM)
        }
        //strLength, errorKeyl,et wpm = parseInt(key / 5);
    }

    const handleKeyUpMain = (e) => { 
        e.preventDefault();

    }
    

    const handleKeydownMain = (e) => {  
        e.preventDefault();
      }
    
    return (
        <div className="lessons-main">
            <h4 className="lessons-main-title bg-main-title">{mainTitle}</h4>
            <div className="lessons-main-content">
                { parse('<div id="lesson_text" className="'+boxDiv+'"><span class="lessons-tooltiptext tooltiptext">Start Typing!</span>'+firstVerseText.join("")+'</div>') }
                <div className="f-help">
                    <br></br><hr></hr>
                    {<div
                        dangerouslySetInnerHTML={{__html: object[firstVerse]['help']}}
                    />}
                   {/* <img className="f-img" src={"/keys/f-key.png"} width={"500px"} style={!object[firstVerse]['help'] ? { visibility: "hidden"} : { }} />
                    <img className="f-img" src={"/hand.png"} width={"500px"} /> */}
                    
                </div>
  
            </div>

         
            
            {/*keyboard start */}
            <div style={{margin: "50px 0"}}>
                    <img className="f-img1" src={"/left-hand.png"} width={"250px"} />
                    <img className="f-img1" src={"/right-hand.png"} width={"250px"} />
                <Keyboard KeyboardFrom="main" ></Keyboard>
            </div>
                
            {/*keyboard end*/}
            
            {/* Stats SideBar start 
            <div className="sidebar">
                <StatsSideBar></StatsSideBar>
            </div>*/}
                
            {/* Stats SideBar end */}

    
            
            
            
         </div>
    )
}

export default TypingLesson2;