import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {blogData} from "./blogData.js"
import TypingBenefits from "./typingBenefits.js"
import WhyLearnTyping from "./whyLearnTyping.js"
import TypeMemVerse from "./typeMemVerse.js";
import WhyTypeBible from "./whyTypeBible.js";
import TypingVsReading from "./typingVsReading.js";
//import BlogList from "./BlogList";

const Blog = () => {
  const [blogs, setBlogs] = useState(blogData)
  const param = useParams()
  let link = param.blog ? param.blog : 1
    console.log("blog link",link)
  return (
    <div className="blog">
      {/*<BlogList blogs={blogs} title="So, Why Typing? or Typlogos!!" />*/}
        { link === "why-typing-bible" ? <WhyTypeBible /> : "" }
        { link === "typing-benefits" ? <TypingBenefits /> : "" }
        { link === "why-learn-typing" ? <WhyLearnTyping /> : "" }
        { link === "type-memorize-bible-verse" ? <TypeMemVerse /> : "" }
        { link === "bible-typing-vs-bible-reading" ? <TypingVsReading /> : "" }
    </div>
  );
}

const BlogList = ({ blogs, title}) => {
    // const blogs = props.blogs;
    // const title = props.title;
    // console.log(blogs);
  
    return (
      <div className="page">
        <h2 className="page-title">{ title }</h2>
        {blogs.map(blog => (
          <div className="page-text" key={blog.id} >
            <h2><a style={{color:"#466399", fontSize: "1rem"}} href={ "/blog/" + blog.title }>{ blog.title }</a></h2>
            <p>{ blog.summary }</p>
            <p>By: { blog.author }</p>
          </div>
        ))}
      </div>
    );
  }
 
export default Blog;