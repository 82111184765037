import React, {useEffect, useState, useRef} from "react";
import { useParams, useLocation } from "react-router";
import parse from 'html-react-parser'
import {Keyboard, KeyboardActiveKey, CapsLockOverlay} from "../components/keyboard";
import { StatsMainBarV} from "../components/Stats-sidebar";
/*import {quotes} from '../data/Quotes/quotes'
import bibleLove from "../data/Quotes/Bible-love";
import bibleFaith from "../data/Quotes/Bible-faith";
import bibleWisdom from "../data/Quotes/Bible-wisdom";
import bibleRandom from "../data/Quotes/Bible-random";
import { tenCommands } from "../data/Quotes/10-command";
*/

const TypingQuote = () => {
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    const params = useParams();
    const param = params.type ? params.type:'';

    let object = '';
    //if(param == 'random'){ object = quotes; }
    //if(param == 'bible-love'){ object = bibleLove; }
    //if(param == 'bible-faith'){ object = bibleFaith; }
    //if(param == 'bible-wisdom'){ object = bibleWisdom; }
    //if(param == 'bible-random'){ object = bibleRandom; }
    //if(param == '10-commandments'){ object = tenCommands; }
    //const object = data;
    let type = 'bible'; 
    let qId = 0;
    const getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }
    
    
    const getQuote = async () => { 
        //const user = {email: emailSignup,password: passwordSignup}
        if(param == 'random'){ type = 'quote'; qId = getRndInteger(1, 1662); }
        if(param == 'bible-random'){ type = 'bible'; qId = getRndInteger(1, 31103);}
        if(param == 'bible-random-ot'){ type = 'bible'; qId = getRndInteger(1, 23145); }
        if(param == 'bible-random-nt'){ type = 'bible'; qId = getRndInteger(23145, 31103);}
        const response = await fetch('/api/users/quote/'+qId, {
            method: 'GET',
            //body: 'quote',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Type': type
            }
        })
        const json = await response.json();
        if(!response.ok){
            //setFirstVerseText(object[0].text)
            //setError(json.error)
        }
        if(response.ok){
            //setError(null)
            object = json
            try {
                str = [...object[firstVerse]['text']];
                stra = object[firstVerse]['text'];
                strLength = str.length;
                verse = str.map((v, index) => {
                    return '<div id="chr_'+index+'" className="verse">'+v+'</div>';
                })
                author = object[firstVerse] ? object[firstVerse]['author'] : 'll';
                setFirstVerseText(verse.join(""));
                setMainTitle(author)
            } catch (err){
                console.log('catch errpr', err.message);
            }
            console.log(json)
        }

    }


    console.log(object);
    const handleClickQuote = (e) => {
        e.preventDefault();
        document.querySelector(".quote-result-card").classList.remove('quote-result-card-show');
        document.querySelector(".quote-result-card").classList.add('quote-result-card-hide');
    }

    let [firstVerse, setFirstVerse] = useState(0);
    const totalVerses = object.length - 1;
    let str, stra, strLength, verse;
    let caps = 0;
    let seconds = 0;
    let accuracy = 0;
    let author;
    /*try {
        str = [...object[firstVerse]['text']];
        stra = object[firstVerse]['text'];
        strLength = str.length;
        verse = str.map((v, index) => {
            return '<div id="chr_'+index+'" className="verse">'+v+'</div>';
        })
        author = object[firstVerse] ? object[firstVerse]['author'] : 'll';
    } catch (err){
        console.log('catch errpr', err.message);
    }*/
    
    
    
    let [firstVerseText, setFirstVerseText] = useState("Quote/text will be here soon!!");
    let [mainTitle, setMainTitle] = useState(author);


    let key = 0;
    //console.log("firstVerseText", firstVerseText)
    useEffect(() => {
        //console.log("useeffect", object)
        getQuote();
        //setFirstVerseText(verse);
        document.addEventListener('keydown', handleKeydownMain)
        document.addEventListener("keyup", handleKeyUpMain);
        //document.querySelector("#chr_0").classList.add('cursor');
        document.querySelector(".text").classList.add('tooltip');
        //document.querySelector(".key-"+stra[key].toLowerCase()).classList.add('is-active');
        return () => {
            key = 0;
            document.removeEventListener('keydown', handleKeydownMain)
            document.removeEventListener('keyup', handleKeyUpMain)
        };
        
    }, [param])


    const handleKeyUpMain = (e) => {

        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps = 1;
            } else {
                caps = 0;
            }
        }
        console.log('key up', object.length, firstVerse)
        if(key === strLength){
            document.querySelectorAll('.verse').forEach(el => el.classList.remove('red'))
            document.querySelectorAll('.verse').forEach(el => el.classList.remove('green'))
            document.querySelector("#chr_0").classList.add('cursor');
            //setFirstVerse(firstVerse++);
            getQuote();
            /*if(firstVerse ==object.length){ // pop up for last quotes in the list
                document.querySelector(".btn-b11").remove();
                document.querySelector(".quote-result-card").classList.remove('quote-result-card-hide');
                document.querySelector(".quote-result-card").classList.add('quote-result-card-show');
                e.preventDefault();
                e.stopPropagation();
                return false;
            }*/

            if(!object[firstVerse]){
                console.log('frist ver NOT exit')
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            /*str = [...object[firstVerse]['text']];
            stra = object[firstVerse]['text'];
            strLength = str.length;
            verse = str.map((v, index) => {
                return '<div id="chr_'+index+'" className="verse">'+v+'</div>';
            })
            setFirstVerseText(verse);
            setMainTitle(object[firstVerse]['author'])
            */
            key = 0;
            /*if(firstVerse % 2 == 0){ // pop up for every 10 quotes
                document.querySelector(".quote-result-card").classList.remove('quote-result-card-hide');
                document.querySelector(".quote-result-card").classList.add('quote-result-card-show');
            }*/
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }
    

    const handleKeydownMain = (e) => { 

        console.log("scroll text", key)

        

        if(e.keyCode === 16){ return; } //Shift Key  
        if(e.keyCode === 20){ //CapsLock
            caps = 1;
            console.log('key 20')

        } 
        
        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps =  1;
            } else {
                caps = 0;
            }
        }
        CapsLockOverlay(e, caps);if(caps){
            console.log('caps on');
            document.querySelector(".overlay").classList.remove('overlay-hide');
            document.querySelector(".overlay").classList.add('overlay-show');
            e.preventDefault();
            e.stopPropagation();
            return false;
        } else {
            document.querySelector(".overlay").classList.add('overlay-hide');
        }

        
           
        if(e.keyCode === 27){ return; } //Escape Key s
        if(e.keyCode === undefined){ return; }

        const element = document.querySelector(".quotes-main-text");


        if(key == 136){
            element.scrollBy({
                top: 125,
                left: 0,
                behavior: "smooth",
              });
        } else if (key == 272){
            element.scrollBy({
                top: 200,
                left: 0,
                behavior: "smooth",
              });
        } else if (key == 400){
            element.scrollBy({
                top: 300,
                left: 0,
                behavior: "smooth",
              });
        }

        if(e.keyCode === 32){ 
            console.log('space bar'); //return;
            e.preventDefault();
         }
  
        KeyboardActiveKey(stra[key+1]);

        

        document.querySelector("#chr_0").classList.remove('cursor');
        document.querySelector(".text").classList.remove('tooltip');
        document.querySelector(".text").classList.add('tooltip-hide');
        //stats
        //let red = 0;
        let red = document.querySelectorAll(".red").length;
        
        accuracy = (100 - Math.round((red/key)*100));
        accuracy = isNaN(accuracy) ? 0 : accuracy;

        document.querySelector("#errorKey").innerText = red;
        document.querySelector("#accuracy").innerText = accuracy+'%';
        document.querySelector("#totalTime").innerText = seconds;

        // backspace typing
        if(e.keyCode == 8){ 
            if(key != 0){
                document.querySelector("#chr_"+key).classList.remove('cursor');
                --key; 
            }

            document.querySelector("#chr_"+key).classList.remove('red');
            document.querySelector("#chr_"+key).classList.remove('green');
            document.querySelector("#chr_"+key).classList.add('cursor');
            
            
            if(key == 0){
                return;
            }
            return;
        }
        
        if(e.key == stra[key]){
            console.log('Here2111',key,strLength ); 
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#keyAudio").play() }
            document.querySelector("#chr_"+key).classList.add('green');
            document.querySelector("#chr_"+key).classList.remove('cursor');
            console.log(e.key, stra[key])
            key++;
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }
            if(e.key != 'Enter'){
                //redFlag++; 
            }
            return;
        } 
        if(e.key != stra[key]){
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#errorKeyAudio").play() }
            if(key == strLength){
                console.log('Here - key - lenth', key,strLength )
                return false;
            }
            document.querySelector("#chr_"+key).classList.add('red');
            document.querySelector("#chr_"+key).classList.remove('cursor');
            console.log('Here', e.key)
            key++;
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }

            return;
        }
        
        
        
    }
    
    return (
        <div className="quotes-main">
            <h4 className="quotes-main-title bg-main-title">{mainTitle}</h4>
            <div className="quotes-main-text">
                { parse('<div className="text"><span class="quotes-tooltiptext tooltiptext">Start Typing!</span><span className="material-symbols-outlined quote-left">format_quote</span>'+firstVerseText+'<span className="material-symbols-outlined quote-right">format_quote</span></div>') }
                {/*<div className="q-by">-'+mainTitle+'-</div></div> */}
                
                
  
            </div>
            <div className="lesson-main-stats-section">
                <StatsMainBarV />
            </div>
            
            {/*keyboard start*/}
                <Keyboard KeyboardFrom="main"></Keyboard>
            {/*keyboard end*/}
            
  

                <div className="quote-result-card">
                    <div className="result-card-box">
                        <h3>Awesome Typing Quotes!!</h3>
                        <p className="bg-star-qoute">
                        <span className="material-symbols-outlined quote-star-left">star</span>
                        <span className="material-symbols-outlined quote-star-middle">star</span>
                        <span className="material-symbols-outlined quote-star-right">star</span>
                        </p>
                        
                        { /*<p className="result-card-para">Your Speed was <span className="wpm-card"> </span> 
                        <br />with <span className="accuracy-card"> </span> </p> */ }
    
                        <p className="p-txt-sm111">
                        <a href="#" onClick={handleClickQuote} className="button btn-b11">Continue Typing Quotes</a> <br />  
                        <a href="/quotes/bible-random" className="button quotes-b21"> Random Bible Verses</a>
                        <a href="/quotes/bible-love" className="button quotes-b22"> Bible Verses - Love</a>
                        <a href="/quotes/bible-faith" className="button quotes-b23"> Bible Verses - Faith</a> 
                        </p>                   
                    </div>
                </div>

            
            
         </div>
    )
}

export default TypingQuote;