export const bookNames = [
	{
		"id" : 1,
		"book" : "Genesis",
		"type" : "OT",
		"category" : "Law",
		"chapter" : 50,
		"verse" : 1533,
		"chars" : 195262,
		"words" : 39052,
		"testament" : "Old Testament"
	},
	{
		"id" : 2,
		"book" : "Exodus",
		"type" : "OT",
		"category" : "Law",
		"chapter" : 40,
		"verse" : 1213,
		"chars" : 168113,
		"words" : 33623,
		"testament" : "Old Testament"
	},
	{
		"id" : 3,
		"book" : "Leviticus",
		"type" : "OT",
		"category" : "Law",
		"chapter" : 27,
		"verse" : 859,
		"chars" : 125992,
		"words" : 25198,
		"testament" : "Old Testament"
	},
	{
		"id" : 4,
		"book" : "Numbers",
		"type" : "OT",
		"category" : "Law",
		"chapter" : 36,
		"verse" : 1288,
		"chars" : 174297,
		"words" : 34859,
		"testament" : "Old Testament"
	},
	{
		"id" : 5,
		"book" : "Deuteronomy",
		"type" : "OT",
		"category" : "Law",
		"chapter" : 34,
		"verse" : 959,
		"chars" : 145443,
		"words" : 29089,
		"testament" : "Old Testament"
	},
	{
		"id" : 6,
		"book" : "Joshua",
		"type" : "OT",
		"category" : "History",
		"chapter" : 24,
		"verse" : 658,
		"chars" : 99464,
		"words" : 19893,
		"testament" : "Old Testament"
	},
	{
		"id" : 7,
		"book" : "Judges",
		"type" : "OT",
		"category" : "History",
		"chapter" : 21,
		"verse" : 618,
		"chars" : 98052,
		"words" : 19610,
		"testament" : "Old Testament"
	},
	{
		"id" : 8,
		"book" : "Ruth",
		"type" : "OT",
		"category" : "History",
		"chapter" : 4,
		"verse" : 85,
		"chars" : 12915,
		"words" : 2583,
		"testament" : "Old Testament"
	},
	{
		"id" : 9,
		"book" : "1 Samuel",
		"type" : "OT",
		"category" : "History",
		"chapter" : 31,
		"verse" : 810,
		"chars" : 128422,
		"words" : 25684,
		"testament" : "Old Testament"
	},
	{
		"id" : 10,
		"book" : "2 Samuel",
		"type" : "OT",
		"category" : "History",
		"chapter" : 24,
		"verse" : 695,
		"chars" : 105662,
		"words" : 21132,
		"testament" : "Old Testament"
	},
	{
		"id" : 11,
		"book" : "1 Kings",
		"type" : "OT",
		"category" : "History",
		"chapter" : 22,
		"verse" : 816,
		"chars" : 126140,
		"words" : 25228,
		"testament" : "Old Testament"
	},
	{
		"id" : 12,
		"book" : "2 Kings",
		"type" : "OT",
		"category" : "History",
		"chapter" : 25,
		"verse" : 719,
		"chars" : 120008,
		"words" : 24002,
		"testament" : "Old Testament"
	},
	{
		"id" : 13,
		"book" : "1 Chronicles",
		"type" : "OT",
		"category" : "History",
		"chapter" : 29,
		"verse" : 942,
		"chars" : 109983,
		"words" : 21997,
		"testament" : "Old Testament"
	},
	{
		"id" : 14,
		"book" : "2 Chronicles",
		"type" : "OT",
		"category" : "History",
		"chapter" : 36,
		"verse" : 822,
		"chars" : 138199,
		"words" : 27640,
		"testament" : "Old Testament"
	},
	{
		"id" : 15,
		"book" : "Ezra",
		"type" : "OT",
		"category" : "History",
		"chapter" : 10,
		"verse" : 280,
		"chars" : 40106,
		"words" : 8021,
		"testament" : "Old Testament"
	},
	{
		"id" : 16,
		"book" : "Nehemiah",
		"type" : "OT",
		"category" : "History",
		"chapter" : 13,
		"verse" : 406,
		"chars" : 56710,
		"words" : 11342,
		"testament" : "Old Testament"
	},
	{
		"id" : 17,
		"book" : "Esther",
		"type" : "OT",
		"category" : "History",
		"chapter" : 10,
		"verse" : 167,
		"chars" : 30050,
		"words" : 6010,
		"testament" : "Old Testament"
	},
	{
		"id" : 18,
		"book" : "Job",
		"type" : "OT",
		"category" : "Wisdom",
		"chapter" : 42,
		"verse" : 1070,
		"chars" : 93161,
		"words" : 18632,
		"testament" : "Old Testament"
	},
	{
		"id" : 19,
		"book" : "Psalms",
		"type" : "OT",
		"category" : "Wisdom",
		"chapter" : 150,
		"verse" : 2461,
		"chars" : 221244,
		"words" : 44249,
		"testament" : "Old Testament"
	},
	{
		"id" : 20,
		"book" : "Proverbs",
		"type" : "OT",
		"category" : "Wisdom",
		"chapter" : 31,
		"verse" : 915,
		"chars" : 79056,
		"words" : 15811,
		"testament" : "Old Testament"
	},
	{
		"id" : 21,
		"book" : "Ecclesiastes",
		"type" : "OT",
		"category" : "Wisdom",
		"chapter" : 12,
		"verse" : 222,
		"chars" : 28142,
		"words" : 5628,
		"testament" : "Old Testament"
	},
	{
		"id" : 22,
		"book" : "Song of Solomon",
		"type" : "OT",
		"category" : "Wisdom",
		"chapter" : 8,
		"verse" : 117,
		"chars" : 13569,
		"words" : 2714,
		"testament" : "Old Testament"
	},
	{
		"id" : 23,
		"book" : "Isaiah",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 66,
		"verse" : 1292,
		"chars" : 192694,
		"words" : 38539,
		"testament" : "Old Testament"
	},
	{
		"id" : 24,
		"book" : "Jeremiah",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 52,
		"verse" : 1364,
		"chars" : 222333,
		"words" : 44467,
		"testament" : "Old Testament"
	},
	{
		"id" : 25,
		"book" : "Lamentations",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 5,
		"verse" : 154,
		"chars" : 17930,
		"words" : 3586,
		"testament" : "Old Testament"
	},
	{
		"id" : 26,
		"book" : "Ezekiel",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 48,
		"verse" : 1273,
		"chars" : 203984,
		"words" : 40797,
		"testament" : "Old Testament"
	},
	{
		"id" : 27,
		"book" : "Daniel",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 12,
		"verse" : 357,
		"chars" : 61518,
		"words" : 12304,
		"testament" : "Old Testament"
	},
	{
		"id" : 28,
		"book" : "Hosea",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 14,
		"verse" : 197,
		"chars" : 26916,
		"words" : 5383,
		"testament" : "Old Testament"
	},
	{
		"id" : 29,
		"book" : "Joel",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 3,
		"verse" : 73,
		"chars" : 10642,
		"words" : 2128,
		"testament" : "Old Testament"
	},
	{
		"id" : 30,
		"book" : "Amos",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 9,
		"verse" : 146,
		"chars" : 21713,
		"words" : 4343,
		"testament" : "Old Testament"
	},
	{
		"id" : 31,
		"book" : "Obadiah",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 1,
		"verse" : 21,
		"chars" : 3557,
		"words" : 711,
		"testament" : "Old Testament"
	},
	{
		"id" : 32,
		"book" : "Jonah",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 4,
		"verse" : 48,
		"chars" : 6581,
		"words" : 1316,
		"testament" : "Old Testament"
	},
	{
		"id" : 33,
		"book" : "Micah",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 7,
		"verse" : 105,
		"chars" : 16224,
		"words" : 3245,
		"testament" : "Old Testament"
	},
	{
		"id" : 34,
		"book" : "Nahum",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 3,
		"verse" : 47,
		"chars" : 6865,
		"words" : 1373,
		"testament" : "Old Testament"
	},
	{
		"id" : 35,
		"book" : "Habakkuk",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 3,
		"verse" : 56,
		"chars" : 7866,
		"words" : 1573,
		"testament" : "Old Testament"
	},
	{
		"id" : 36,
		"book" : "Zephaniah",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 3,
		"verse" : 53,
		"chars" : 8447,
		"words" : 1689,
		"testament" : "Old Testament"
	},
	{
		"id" : 37,
		"book" : "Haggai",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 2,
		"verse" : 38,
		"chars" : 5696,
		"words" : 1139,
		"testament" : "Old Testament"
	},
	{
		"id" : 38,
		"book" : "Zechariah",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 14,
		"verse" : 211,
		"chars" : 32743,
		"words" : 6549,
		"testament" : "Old Testament"
	},
	{
		"id" : 39,
		"book" : "Malachi",
		"type" : "OT",
		"category" : "Prophets",
		"chapter" : 4,
		"verse" : 55,
		"chars" : 9138,
		"words" : 1828,
		"testament" : "Old Testament"
	},
	{
		"id" : 40,
		"book" : "Matthew",
		"type" : "NT",
		"category" : "Gospels",
		"chapter" : 28,
		"verse" : 1071,
		"chars" : 123344,
		"words" : 24669,
		"testament" : "New Testament"
	},
	{
		"id" : 41,
		"book" : "Mark",
		"type" : "NT",
		"category" : "Gospels",
		"chapter" : 16,
		"verse" : 678,
		"chars" : 78443,
		"words" : 15689,
		"testament" : "New Testament"
	},
	{
		"id" : 42,
		"book" : "Luke",
		"type" : "NT",
		"category" : "Gospels",
		"chapter" : 24,
		"verse" : 1151,
		"chars" : 133520,
		"words" : 26704,
		"testament" : "New Testament"
	},
	{
		"id" : 43,
		"book" : "John",
		"type" : "NT",
		"category" : "Gospels",
		"chapter" : 21,
		"verse" : 879,
		"chars" : 97152,
		"words" : 19430,
		"testament" : "New Testament"
	},
	{
		"id" : 44,
		"book" : "Acts",
		"type" : "NT",
		"category" : "Acts",
		"chapter" : 28,
		"verse" : 1007,
		"chars" : 128829,
		"words" : 25766,
		"testament" : "New Testament"
	},
	{
		"id" : 45,
		"book" : "Romans",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 16,
		"verse" : 433,
		"chars" : 49793,
		"words" : 9959,
		"testament" : "New Testament"
	},
	{
		"id" : 46,
		"book" : "1 Corinthians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 16,
		"verse" : 437,
		"chars" : 48492,
		"words" : 9698,
		"testament" : "New Testament"
	},
	{
		"id" : 47,
		"book" : "2 Corinthians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 13,
		"verse" : 257,
		"chars" : 31747,
		"words" : 6349,
		"testament" : "New Testament"
	},
	{
		"id" : 48,
		"book" : "Galatians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 6,
		"verse" : 149,
		"chars" : 16083,
		"words" : 3217,
		"testament" : "New Testament"
	},
	{
		"id" : 49,
		"book" : "Ephesians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 5,
		"verse" : 155,
		"chars" : 16127,
		"words" : 3225,
		"testament" : "New Testament"
	},
	{
		"id" : 50,
		"book" : "Philippians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 4,
		"verse" : 104,
		"chars" : 11454,
		"words" : 2291,
		"testament" : "New Testament"
	},
	{
		"id" : 51,
		"book" : "Colossians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 4,
		"verse" : 95,
		"chars" : 10625,
		"words" : 2125,
		"testament" : "New Testament"
	},
	{
		"id" : 52,
		"book" : "1 Thessalonians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 5,
		"verse" : 89,
		"chars" : 9574,
		"words" : 1915,
		"testament" : "New Testament"
	},
	{
		"id" : 53,
		"book" : "2 Thessalonians",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 3,
		"verse" : 47,
		"chars" : 5395,
		"words" : 1079,
		"testament" : "New Testament"
	},
	{
		"id" : 54,
		"book" : "1 Timothy",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 6,
		"verse" : 113,
		"chars" : 12584,
		"words" : 2517,
		"testament" : "New Testament"
	},
	{
		"id" : 55,
		"book" : "2 Timothy",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 4,
		"verse" : 83,
		"chars" : 9107,
		"words" : 1821,
		"testament" : "New Testament"
	},
	{
		"id" : 56,
		"book" : "Titus",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 3,
		"verse" : 46,
		"chars" : 5085,
		"words" : 1017,
		"testament" : "New Testament"
	},
	{
		"id" : 57,
		"book" : "Philemon",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 1,
		"verse" : 25,
		"chars" : 2295,
		"words" : 459,
		"testament" : "New Testament"
	},
	{
		"id" : 58,
		"book" : "Hebrews",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 13,
		"verse" : 303,
		"chars" : 36904,
		"words" : 7381,
		"testament" : "New Testament"
	},
	{
		"id" : 59,
		"book" : "James",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 5,
		"verse" : 108,
		"chars" : 12029,
		"words" : 2406,
		"testament" : "New Testament"
	},
	{
		"id" : 60,
		"book" : "1 Peter",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 5,
		"verse" : 105,
		"chars" : 13354,
		"words" : 2671,
		"testament" : "New Testament"
	},
	{
		"id" : 61,
		"book" : "2 Peter",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 3,
		"verse" : 61,
		"chars" : 8639,
		"words" : 1728,
		"testament" : "New Testament"
	},
	{
		"id" : 62,
		"book" : "1 John",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 5,
		"verse" : 105,
		"chars" : 12649,
		"words" : 2530,
		"testament" : "New Testament"
	},
	{
		"id" : 63,
		"book" : "2 John",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 1,
		"verse" : 13,
		"chars" : 1540,
		"words" : 308,
		"testament" : "New Testament"
	},
	{
		"id" : 64,
		"book" : "3 John",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 1,
		"verse" : 14,
		"chars" : 1579,
		"words" : 316,
		"testament" : "New Testament"
	},
	{
		"id" : 65,
		"book" : "Jude",
		"type" : "NT",
		"category" : "Epistles",
		"chapter" : 1,
		"verse" : 25,
		"chars" : 3498,
		"words" : 700,
		"testament" : "New Testament"
	},
	{
		"id" : 66,
		"book" : "Revelation",
		"type" : "NT",
		"category" : "Apocalyptic",
		"chapter" : 22,
		"verse" : 404,
		"chars" : 61668,
		"words" : 12334,
		"testament" : "New Testament"
	}
]

export const books = {
		"genesis" : {
				"id" : 1,
				"book" : "Genesis",
				"type" : "OT",
				"category" : "Law",
				"chapter" : 50,
				"verse" : 1533,
				"chars" : 195262,
				"words" : 39052,
				"testament" : "Old Testament"
		},
		"exodus" : {
			"id" : 2,
			"book" : "Exodus",
			"type" : "OT",
			"category" : "Law",
			"chapter" : 40,
			"verse" : 1213,
			"chars" : 168113,
			"words" : 33623,
			"testament" : "Old Testament"
		},
		"leviticus":{
			"id" : 3,
			"book" : "Leviticus",
			"type" : "OT",
			"category" : "Law",
			"chapter" : 27,
			"verse" : 859,
			"chars" : 125992,
			"words" : 25198,
			"testament" : "Old Testament"
		},
		"numbers":{
			"id" : 4,
			"book" : "Numbers",
			"type" : "OT",
			"category" : "Law",
			"chapter" : 36,
			"verse" : 1288,
			"chars" : 174297,
			"words" : 34859,
			"testament" : "Old Testament"
		},
		"deuteronomy": {
			"id" : 5,
			"book" : "Deuteronomy",
			"type" : "OT",
			"category" : "Law",
			"chapter" : 34,
			"verse" : 959,
			"chars" : 145443,
			"words" : 29089,
			"testament" : "Old Testament"
		},
		"joshua": {
			"id" : 6,
			"book" : "Joshua",
			"type" : "OT",
			"category" : "History",
			"chapter" : 24,
			"verse" : 658,
			"chars" : 99464,
			"words" : 19893,
			"testament" : "Old Testament"
		},
		"judges": {
			"id" : 7,
			"book" : "Judges",
			"type" : "OT",
			"category" : "History",
			"chapter" : 21,
			"verse" : 618,
			"chars" : 98052,
			"words" : 19610,
			"testament" : "Old Testament"
		},
		"ruth": {
			"id" : 8,
			"book" : "Ruth",
			"type" : "OT",
			"category" : "History",
			"chapter" : 4,
			"verse" : 85,
			"chars" : 12915,
			"words" : 2583,
			"testament" : "Old Testament"
		},
		"1-samuel": {
			"id" : 9,
			"book" : "1 Samuel",
			"type" : "OT",
			"category" : "History",
			"chapter" : 31,
			"verse" : 810,
			"chars" : 128422,
			"words" : 25684,
			"testament" : "Old Testament"
		},
		"2-samuel": {
			"id" : 10,
			"book" : "2 Samuel",
			"type" : "OT",
			"category" : "History",
			"chapter" : 24,
			"verse" : 695,
			"chars" : 105662,
			"words" : 21132,
			"testament" : "Old Testament"
		},
		"1-kings": {
			"id" : 11,
			"book" : "1 Kings",
			"type" : "OT",
			"category" : "History",
			"chapter" : 22,
			"verse" : 816,
			"chars" : 126140,
			"words" : 25228,
			"testament" : "Old Testament"
		},
		"2-kings": {
			"id" : 12,
			"book" : "2 Kings",
			"type" : "OT",
			"category" : "History",
			"chapter" : 25,
			"verse" : 719,
			"chars" : 120008,
			"words" : 24002,
			"testament" : "Old Testament"
		},
		"1-chronicles": {
			"id" : 13,
			"book" : "1 Chronicles",
			"type" : "OT",
			"category" : "History",
			"chapter" : 29,
			"verse" : 942,
			"chars" : 109983,
			"words" : 21997,
			"testament" : "Old Testament"
		},
		"2-chronicles": {
			"id" : 14,
			"book" : "2 Chronicles",
			"type" : "OT",
			"category" : "History",
			"chapter" : 36,
			"verse" : 822,
			"chars" : 138199,
			"words" : 27640,
			"testament" : "Old Testament"
		},
		"ezra": {
			"id" : 15,
			"book" : "Ezra",
			"type" : "OT",
			"category" : "History",
			"chapter" : 10,
			"verse" : 280,
			"chars" : 40106,
			"words" : 8021,
			"testament" : "Old Testament"
		},
		"nehemiah": {
			"id" : 16,
			"book" : "Nehemiah",
			"type" : "OT",
			"category" : "History",
			"chapter" : 13,
			"verse" : 406,
			"chars" : 56710,
			"words" : 11342,
			"testament" : "Old Testament"
		},
		"esther": {
			"id" : 17,
			"book" : "Esther",
			"type" : "OT",
			"category" : "History",
			"chapter" : 10,
			"verse" : 167,
			"chars" : 30050,
			"words" : 6010,
			"testament" : "Old Testament"
		},
		"job": {
			"id" : 18,
			"book" : "Job",
			"type" : "OT",
			"category" : "Wisdom",
			"chapter" : 42,
			"verse" : 1070,
			"chars" : 93161,
			"words" : 18632,
			"testament" : "Old Testament"
		},
		"psalms": {
			"id" : 19,
			"book" : "Psalms",
			"type" : "OT",
			"category" : "Wisdom",
			"chapter" : 150,
			"verse" : 2461,
			"chars" : 221244,
			"words" : 44249,
			"testament" : "Old Testament"
		},
		"proverbs":{
			"id" : 20,
			"book" : "Proverbs",
			"type" : "OT",
			"category" : "Wisdom",
			"chapter" : 31,
			"verse" : 915,
			"chars" : 79056,
			"words" : 15811,
			"testament" : "Old Testament"
		},
		"ecclesiastes":{
			"id" : 21,
			"book" : "Ecclesiastes",
			"type" : "OT",
			"category" : "Wisdom",
			"chapter" : 12,
			"verse" : 222,
			"chars" : 28142,
			"words" : 5628,
			"testament" : "Old Testament"
		},
		"song-of-solomon": {
			"id" : 22,
			"book" : "Song of Solomon",
			"type" : "OT",
			"category" : "Wisdom",
			"chapter" : 8,
			"verse" : 117,
			"chars" : 13569,
			"words" : 2714,
			"testament" : "Old Testament"
		},
		"isaiah": {
			"id" : 23,
			"book" : "Isaiah",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 66,
			"verse" : 1292,
			"chars" : 192694,
			"words" : 38539,
			"testament" : "Old Testament"
		},
		"jeremiah":{
			"id" : 24,
			"book" : "Jeremiah",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 52,
			"verse" : 1364,
			"chars" : 222333,
			"words" : 44467,
			"testament" : "Old Testament"
		},
		"lamentations":{
			"id" : 25,
			"book" : "Lamentations",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 5,
			"verse" : 154,
			"chars" : 17930,
			"words" : 3586,
			"testament" : "Old Testament"
		},
		"ezekiel": {
			"id" : 26,
			"book" : "Ezekiel",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 48,
			"verse" : 1273,
			"chars" : 203984,
			"words" : 40797,
			"testament" : "Old Testament"
		},
		"daniel": {
			"id" : 27,
			"book" : "Daniel",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 12,
			"verse" : 357,
			"chars" : 61518,
			"words" : 12304,
			"testament" : "Old Testament"
		},
		"hosea": {
			"id" : 28,
			"book" : "Hosea",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 14,
			"verse" : 197,
			"chars" : 26916,
			"words" : 5383,
			"testament" : "Old Testament"
		},
		"joel": {
			"id" : 29,
			"book" : "Joel",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 3,
			"verse" : 73,
			"chars" : 10642,
			"words" : 2128,
			"testament" : "Old Testament"
		},
		"amos": {
			"id" : 30,
			"book" : "Amos",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 9,
			"verse" : 146,
			"chars" : 21713,
			"words" : 4343,
			"testament" : "Old Testament"
		},
		"obadiah": {
			"id" : 31,
			"book" : "Obadiah",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 1,
			"verse" : 21,
			"chars" : 3557,
			"words" : 711,
			"testament" : "Old Testament"
		},
		"jonah": {
			"id" : 32,
			"book" : "Jonah",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 4,
			"verse" : 48,
			"chars" : 6581,
			"words" : 1316,
			"testament" : "Old Testament"
		},
		"micah": {
			"id" : 33,
			"book" : "Micah",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 7,
			"verse" : 105,
			"chars" : 16224,
			"words" : 3245,
			"testament" : "Old Testament"
		},
		"nahum": {
			"id" : 34,
			"book" : "Nahum",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 3,
			"verse" : 47,
			"chars" : 6865,
			"words" : 1373,
			"testament" : "Old Testament"
		},
		"habakkuk": {
			"id" : 35,
			"book" : "Habakkuk",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 3,
			"verse" : 56,
			"chars" : 7866,
			"words" : 1573,
			"testament" : "Old Testament"
		},
		"zephaniah": {
			"id" : 36,
			"book" : "Zephaniah",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 3,
			"verse" : 53,
			"chars" : 8447,
			"words" : 1689,
			"testament" : "Old Testament"
		},
		"haggai": {
			"id" : 37,
			"book" : "Haggai",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 2,
			"verse" : 38,
			"chars" : 5696,
			"words" : 1139,
			"testament" : "Old Testament"
		},
		"zechariah": {
			"id" : 38,
			"book" : "Zechariah",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 14,
			"verse" : 211,
			"chars" : 32743,
			"words" : 6549,
			"testament" : "Old Testament"
		},
		"malachi": {
			"id" : 39,
			"book" : "Malachi",
			"type" : "OT",
			"category" : "Prophets",
			"chapter" : 4,
			"verse" : 55,
			"chars" : 9138,
			"words" : 1828,
			"testament" : "Old Testament"
		},
		"matthew": {
			"id" : 40,
			"book" : "Matthew",
			"type" : "NT",
			"category" : "Gospels",
			"chapter" : 28,
			"verse" : 1071,
			"chars" : 123344,
			"words" : 24669,
			"testament" : "New Testament"
		},
		"mark": {
			"id" : 41,
			"book" : "Mark",
			"type" : "NT",
			"category" : "Gospels",
			"chapter" : 16,
			"verse" : 678,
			"chars" : 78443,
			"words" : 15689,
			"testament" : "New Testament"
		},
		"luke": {
			"id" : 42,
			"book" : "Luke",
			"type" : "NT",
			"category" : "Gospels",
			"chapter" : 24,
			"verse" : 1151,
			"chars" : 133520,
			"words" : 26704,
			"testament" : "New Testament"
		},
		"john": {
			"id" : 43,
			"book" : "John",
			"type" : "NT",
			"category" : "Gospels",
			"chapter" : 21,
			"verse" : 879,
			"chars" : 97152,
			"words" : 19430,
			"testament" : "New Testament"
		},
		"acts": {
			"id" : 44,
			"book" : "Acts",
			"type" : "NT",
			"category" : "Acts",
			"chapter" : 28,
			"verse" : 1007,
			"chars" : 128829,
			"words" : 25766,
			"testament" : "New Testament"
		},
		"romans": {
			"id" : 45,
			"book" : "Romans",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 16,
			"verse" : 433,
			"chars" : 49793,
			"words" : 9959,
			"testament" : "New Testament"
		},
		"1-corinthians": {
			"id" : 46,
			"book" : "1 Corinthians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 16,
			"verse" : 437,
			"chars" : 48492,
			"words" : 9698,
			"testament" : "New Testament"
		},
		"2-corinthians":{
			"id" : 47,
			"book" : "2 Corinthians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 13,
			"verse" : 257,
			"chars" : 31747,
			"words" : 6349,
			"testament" : "New Testament"
		},
		"galatians": {
			"id" : 48,
			"book" : "Galatians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 6,
			"verse" : 149,
			"chars" : 16083,
			"words" : 3217,
			"testament" : "New Testament"
		},
		"ephesians": {
			"id" : 49,
			"book" : "Ephesians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 5,
			"verse" : 155,
			"chars" : 16127,
			"words" : 3225,
			"testament" : "New Testament"
		},
		"philippians": {
			"id" : 50,
			"book" : "Philippians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 4,
			"verse" : 104,
			"chars" : 11454,
			"words" : 2291,
			"testament" : "New Testament"
		},
		"colossians": {
			"id" : 51,
			"book" : "Colossians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 4,
			"verse" : 95,
			"chars" : 10625,
			"words" : 2125,
			"testament" : "New Testament"
		},
		"1-thessalonians":{
			"id" : 52,
			"book" : "1 Thessalonians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 5,
			"verse" : 89,
			"chars" : 9574,
			"words" : 1915,
			"testament" : "New Testament"
		},
		"2-thessalonians":{
			"id" : 53,
			"book" : "2 Thessalonians",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 3,
			"verse" : 47,
			"chars" : 5395,
			"words" : 1079,
			"testament" : "New Testament"
		},
		"1-timothy": {
			"id" : 54,
			"book" : "1 Timothy",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 6,
			"verse" : 113,
			"chars" : 12584,
			"words" : 2517,
			"testament" : "New Testament"
		},
		"2-timothy": {
			"id" : 55,
			"book" : "2 Timothy",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 4,
			"verse" : 83,
			"chars" : 9107,
			"words" : 1821,
			"testament" : "New Testament"
		},
		"titus": {
			"id" : 56,
			"book" : "Titus",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 3,
			"verse" : 46,
			"chars" : 5085,
			"words" : 1017,
			"testament" : "New Testament"
		},
		"philemon": {
			"id" : 57,
			"book" : "Philemon",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 1,
			"verse" : 25,
			"chars" : 2295,
			"words" : 459,
			"testament" : "New Testament"
		},
		"hebrews": {
			"id" : 58,
			"book" : "Hebrews",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 13,
			"verse" : 303,
			"chars" : 36904,
			"words" : 7381,
			"testament" : "New Testament"
		},
		"james": {
			"id" : 59,
			"book" : "James",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 5,
			"verse" : 108,
			"chars" : 12029,
			"words" : 2406,
			"testament" : "New Testament"
		},
		"1-peter": {
			"id" : 60,
			"book" : "1 Peter",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 5,
			"verse" : 105,
			"chars" : 13354,
			"words" : 2671,
			"testament" : "New Testament"
		},
		"2-peter":{
			"id" : 61,
			"book" : "2 Peter",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 3,
			"verse" : 61,
			"chars" : 8639,
			"words" : 1728,
			"testament" : "New Testament"
		},
		"1-john": {
			"id" : 62,
			"book" : "1 John",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 5,
			"verse" : 105,
			"chars" : 12649,
			"words" : 2530,
			"testament" : "New Testament"
		},
		"2-john":{
			"id" : 63,
			"book" : "2 John",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 1,
			"verse" : 13,
			"chars" : 1540,
			"words" : 308,
			"testament" : "New Testament"
		},
		"3-john":{
			"id" : 64,
			"book" : "3 John",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 1,
			"verse" : 14,
			"chars" : 1579,
			"words" : 316,
			"testament" : "New Testament"
		},
		"jude": {
			"id" : 65,
			"book" : "Jude",
			"type" : "NT",
			"category" : "Epistles",
			"chapter" : 1,
			"verse" : 25,
			"chars" : 3498,
			"words" : 700,
			"testament" : "New Testament"
		},
		"revelation": {
			"id" : 66,
			"book" : "Revelation",
			"type" : "NT",
			"category" : "Apocalyptic",
			"chapter" : 22,
			"verse" : 404,
			"chars" : 61668,
			"words" : 12334,
			"testament" : "New Testament"
		}
	}
	