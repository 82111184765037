import React, {useEffect} from "react";
import './lessons.css'
//import { NavLink } from "react-router-dom";
import { basicLessons, beginnerLessons, intermediateLessons, advanceLessons } from '../data/Lessons/Lessons-data'
import {sessionGetItem} from '../components/common'
const Lessons = () => {
    //bad solution for clear counter if someone moves pages fron test page without completing
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    const handleOnChange = (e, tabId) => {
        let i, tabcontent, tablinks;
        
        tabcontent = document.querySelectorAll(".tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.querySelectorAll(".tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        console.log('in tab',tabId);
        document.querySelector("#"+tabId).style.display = "block";
        e.currentTarget.className += " active";
    }
    const handleTabClick = (e, tabId) => {
        let i, tabcontent, tablinks;
        
        tabcontent = document.querySelectorAll(".tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.querySelectorAll(".tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        console.log('in tab',tabId);
        document.querySelector("#"+tabId).style.display = "block";
        e.currentTarget.className += " active";
    }
    useEffect(() => {

        document.querySelector("#defaultOpen").click();
       /* return () => {
            document.removeEventListener('keyup', handleKeyUp)
        }; */
    }, [])
    //document.querySelector("#defaultOpen").click();
    return (
        <div className="lessons-container">
            <div className="lessons-select-div">
                <select className="lessons-select" onChange={(e)=>handleOnChange(e, e.target.value)}>
                    <option value="Basic">Basic Lessons</option>
                    <option value="Beginner">Beginner Lessons</option>
                    <option value="Intermediate">Intermediate Lessons</option>
                    <option value="Advance">Advance Lessons</option>
                </select>
            </div>
            <div className="tab">
                <ul>
                    <li className="tablinks" onClick={(e) => handleTabClick(e, 'Basic')} id="defaultOpen">
                        <span className="material-symbols-outlined">directions_walk</span> Basic</li>
                    <li className="tablinks" onClick={(e) => handleTabClick(e, 'Beginner')}>
                        <span className="material-symbols-outlined">transfer_within_a_station</span> Beginner</li>
                    <li className="tablinks" onClick={(e) => handleTabClick(e, 'Intermediate')}>
                        <span className="material-symbols-outlined">directions_run</span> Intermediate</li>
                    <li className="tablinks" onClick={(e) => handleTabClick(e, 'Advance')}>
                        <span className="material-symbols-outlined">sprint</span> Advance</li>
                </ul>
            </div>
            

            <div id="Basic" className="tabcontent">
                <ProgressBar type={"basic"} />
                {/*<h3>Lessons</h3>*/}
                { basicLessons.map((l) => (
                    <LessonsList lesson={l} key={l.id} type={"basic"} />
                ))}
            </div>

            <div id="Beginner" className="tabcontent">
                <ProgressBar type={"beginner"} />
                { beginnerLessons.map((l) => (
                    <LessonsList lesson={l} key={l.id} type={"beginner"} />
                ))}              
            </div>

            <div id="Intermediate" className="tabcontent">
                <ProgressBar type={"inter"} />
                { intermediateLessons.map((l) => (
                    <LessonsList lesson={l} key={l.id} type={"intermediate"} />
                ))}
            </div>

            <div id="Advance" className="tabcontent">
                <ProgressBar type={"advance"} />
                { advanceLessons.map((l) => (
                    <LessonsList lesson={l} key={l.id} type={"advance"} />
                ))}
            </div>
            
        </div>
    )
}
const LessonsList = (props) => {{
    let lessonCode = JSON.parse(sessionStorage.getItem(props.lesson.code));
    //console.log(props.lesson.code)
    let cls = lessonCode && lessonCode.completed == '1' ? "chip chip-lesson-done" : "chip"
    let accuracy = lessonCode && lessonCode.accuracy ? lessonCode.accuracy : null
    let start = lessonCode && lessonCode.completed == '1' ? "Restart" : "Start"
    let clsStart = lessonCode && lessonCode.completed == '1' ? "restart-bg" : "start-bg"
    let icon = lessonCode && lessonCode.completed == '1' ? "check_circle" : "arrow_circle_right"
    return (
        <div className={cls}  key={props.lesson.id}>
            <span className="material-symbols-outlined lesson-list-style">{icon}</span> 
            <span className="lesson-title-no ">Lesson {props.lesson.id}</span><span className="lesson-title-detail" >- {props.lesson.title} </span>&nbsp;
            {accuracy && <span className="" style={{border:"1px solid", borderRadius:"10px", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}}> Accuracy: {accuracy}%&nbsp; </span>}
            <div className={"start " + clsStart}><a href={"/lessons/"+ props.type +"/" + props.lesson.id}>{start} &raquo;</a></div>
        </div>
    )
}}
const ProgressBar = (props) => {
    let cls = '';
    if(props.type == 'basic'){ 
        cls = "basic";
        let item = [];
        for(let i=1; i<=50;i++){
            
            if(sessionStorage.getItem("ba-"+i)){
                item.push("ba-"+i)
            }
        }
        return (
            <div className="progress-bar">
                <div className={cls+"-progress-percent progress-bar-striped progress-bar-animated"}>{Math.round((item.length/60)*100)}% Completed</div>
            </div>
        )
    }
    if(props.type == 'beginner'){ 
        cls = "beginner";
        let item = [];
        for(let i=1; i<=20;i++){
            
            if(sessionStorage.getItem("be-"+i)){
                item.push("be-"+i)
            }
        }
        return (
            <div className="progress-bar">
                <div className={cls+"-progress-percent progress-bar-striped progress-bar-animated"}>{Math.round((item.length/60)*100)}% Completed</div>
            </div>
        ) 
    }
    if(props.type == 'inter'){ 
        cls = "inter";
        let item = [];
        for(let i=1; i<=20;i++){
            
            if(sessionStorage.getItem("in-"+i)){
                item.push("in-"+i)
            }
        }
        return (
            <div className="progress-bar">
                <div className={cls+"-progress-percent progress-bar-striped progress-bar-animated"}>{Math.round((item.length/60)*100)}% Completed</div>
            </div>
        )
    }
    if(props.type == 'advance'){ 
        cls = "advance";
        let item = [];
        for(let i=1; i<=20;i++){
            
            if(sessionStorage.getItem("ad-"+i)){
                item.push("ad-"+i)
            }
        }
        return (
            <div className="progress-bar">
                <div className={cls+"-progress-percent progress-bar-striped progress-bar-animated"}>{Math.round((item.length/45)*100)}% Completed</div>
            </div>
        )
    }
    
    
    
}
 
export default Lessons;