import './stats-sidebar.css';
const StatsSideBar = () => {
    return (
        <div className='side-stats-section'>
            <div className="card bg-outline-b11">
                <div className="card-content-side">
                <div className="side-stats-section-txt" id="accuracy">0% </div> 
                    <div>
                        {/*<span className="material-symbols-outlined">
                            motion_sensor_active
                        </span> */}
                        Accuracy
                    </div>
                </div>
            </div>
            <div className="card bg-outline-red">
                <div className="card-content-side">
                <div className="side-stats-section-txt" id="errorKey">0</div> 
                    <div>
                        {/*<span className="material-symbols-outlined">
                            error
                        </span> */}
                        Error Keys
                    </div>
                </div>
            </div>
            <div className="card bg-outline-blue">
                <div className="card-content-side">
                <div className="side-stats-section-txt" id="totalTime">0 </div> 
                    <div>
                        {/*<span className="material-symbols-outlined">
                            history
                        </span> */}
                        Time(sec)
                    </div>
                </div>
            </div>
        </div>
    )
}

const StatsMainBar = () => {
    return (
        <div className="main-stats-section">
            <div className="card bg-outline-b11">
                <div className="card-content-main">
                <div className="main-stats-section-txt" id="accuracy">0% </div> 
                    <div>
                        <span className="material-symbols-outlined">
                            motion_sensor_active
                        </span> 
                        Accuracy
                    </div>
                </div>
            </div>
            <div className="card bg-outline-red">
                <div className="card-content-main">
                <div className="main-stats-section-txt" id="errorKey">0</div> 
                    <div>
                        <span className="material-symbols-outlined">
                            error
                        </span> 
                        Error Keys
                    </div>
                </div>
            </div>
            <div className="card bg-outline-blue">
                <div className="card-content-main">
                <div className="main-stats-section-txt" id="totalTime">0 </div> 
                    <div>
                        <span className="material-symbols-outlined">
                            history
                        </span> 
                        Time(sec)
                    </div>
                </div>
            </div>
        </div>
    )
}

const StatsMainBarV = () => {
    return (
        <div className="sub-nav-test">
                    <ul>
                        <li>
                            <div className="nav-fc1">
                                <div className="material-symbols-outlined sub-nav-icon hide-mobile">point_scan</div>
                                <div style={{display: "inline-block"}}>
                                    <span className="txt-sm" style={{display:"block"}}>Accuracy</span>
                                    <span id="accuracy" className="txt-lg" style={{fontWeight: "bold"}}>0%</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="nav-fc2">
                                <div className="material-symbols-outlined sub-nav-icon hide-mobile">bubble</div>
                                <div style={{display: "inline-block"}}>
                                    <span className="txt-sm" style={{display:"block"}}>Gross WPM <span className="material-symbols-outlined txt-sm stooltip">help <span className="stooltiptext">Gross WPM is total number of gross words typed in a minute. <br /><a className="txt-blue" href="/page/faq">read more</a></span></span></span>
                                    
                                    <span id="gwpm" className="txt-lg" style={{fontWeight: "bold"}}>0</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="nav-fc8">
                                <div className="material-symbols-outlined sub-nav-icon hide-mobile">outbound</div>
                                <div style={{display: "inline-block"}}>
                                    <span className="txt-sm" style={{display:"block"}}>Net WPM <span className="material-symbols-outlined txt-sm stooltip">help <span className="stooltiptext">Net WPM is total number of net words typed in a minute. <br /><a className="txt-blue" href="/page/faq">read more</a></span></span></span>
                                    <span id="nwpm" className="txt-lg" style={{fontWeight: "bold"}}>0</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="nav-fc6">
                                <div className="material-symbols-outlined sub-nav-icon hide-mobile">error</div>
                                <div style={{display: "inline-block"}}>
                                    <span className="txt-sm" style={{display:"block"}}>Error Keys</span>
                                    <span id="errorKey" className="txt-lg" style={{fontWeight: "bold"}}>0</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="nav-fc5">
                                <div className="material-symbols-outlined sub-nav-icon hide-mobile">timer</div>
                                <div style={{display: "inline-block"}}>
                                    <span className="txt-sm" style={{display:"block"}}>Time</span>
                                    <span id="totalTime" className="txt-lg" style={{fontWeight: "bold"}}>0</span>
                                </div>
                            </div>
                        </li>
                        
                    </ul> 
                    
                    
                    
                </div>
    )
}

export { StatsSideBar, StatsMainBar, StatsMainBarV};