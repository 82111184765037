import React from "react";
import './games.css'
const Games = () => {
    return (
        <div className="games-row">
            <div className="games-column">
                <div className="games-card">
                <p><span className="material-symbols-outlined">auto_stories</span></p>
                <h3>11+</h3>
                <p>Partners</p>
                </div>
            </div>

            <div className="games-column">
                <div className="games-card">
                <p><span className="material-symbols-outlined">auto_stories</span></p>
                <h3>55+</h3>
                <p>Projects</p>
                </div>
            </div>

            <div className="games-column">
                <div className="games-card">
                <p><i className="fa fa-smile-o"></i></p>
                <h3>100+</h3>
                <p>Happy Clients</p>
                </div>
            </div>

            <div className="games-column">
                <div className="games-card">
                <p><i className="fa fa-coffee"></i></p>
                <h3>100+</h3>
                <p>Meetings</p>
                </div>
            </div>
            </div>
    )
}
 
export default Games;