import { NavLink } from "react-router-dom";
import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <ul className="footer-links">
                <li><NavLink to="page/about">About</NavLink></li> &bull;
                <li><NavLink to="page/terms">Terms</NavLink></li> &bull;
                <li><NavLink to="page/privacy">Privacy</NavLink></li> &bull;
                <li><NavLink to="page/help"> Help </NavLink></li> &bull;
                <li><NavLink to="page/faq"> FAQ </NavLink></li>
            </ul>

            <ul className="footer-links-b">
                <li>2023 © TYPLOGOS.com. All rights reserved.</li>
            </ul>
        </div>
    )
}

export default Footer;