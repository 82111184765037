const addClass = (element, cls) => {
    //return element.classList.add(cls);
    document.querySelector(element).classList.add(cls);
}

const removeClass = (element, cls) => {
    document.querySelector(element).classList.remove(cls);
    //return element.classList.remove(cls);
}

const sessionSetItem = (key, value) => {
    if (!key || !value) {return;}

    if (typeof value === "object") {
      value = JSON.stringify(value)
    }
    //localStorage.setItem(key, value)
    sessionStorage.setItem(key, value)
 }

const sessionGetItem = (key) => {
    //var value = localStorage.getItem(key);
    let value = sessionStorage.getItem(key);

    if (!value) {return;}

    // assume it is an object that has been stringified
    if (value[0] === "{") {
      value = JSON.parse(value);
    }

    return value;
  }
  const setDefaultSession = () => {
    if(!sessionStorage.getItem("settingsAccuracy")) { sessionStorage.setItem("settingsAccuracy", 90)}
    if(!sessionStorage.getItem("settingsSpeed")) { sessionStorage.setItem("settingsSpeed", 50)}
    if(!sessionStorage.getItem("settingsShowKeyboard")) { sessionStorage.setItem("settingsShowKeyboard", "On")}
    if(!sessionStorage.getItem("settingsKeyboardSound")) { sessionStorage.setItem("settingsKeyboardSound", "Off")}
    if(!sessionStorage.getItem("settingsBibleVersion")) { sessionStorage.setItem("settingsBibleVersion", "kjv_text")}
  }
  const textType = (txt = "Type",   speed, element) =>  {
    let i = 0
    //if(txt.length < 1) { txt = "Type"}
     if (i < txt.length) {
         document.querySelector(element).innerHTML += txt.charAt(i);
         i++;
         setTimeout(textType, speed);
     }
 }
export { addClass, removeClass, sessionSetItem, sessionGetItem, setDefaultSession, textType};