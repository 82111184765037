import React from "react";
import './tests.css'
import { NavLink } from "react-router-dom";
const Tests = () => {
    //bad solution for clear counter if someone moves pages fron test page without completing
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    
    //console.log('in tests.js',key)
    return (
        <div className="tests-row">
            <div className="tests-column">
                <div className="tests-card tests-b11">
                    <NavLink to="1-min-test-1">
                        <p>
                            <span className="material-symbols-outlined">east</span>&nbsp;  
                            <span className="material-symbols-outlined">edit_note</span>&nbsp; 
                            <span className="material-symbols-outlined">west</span>
                        </p>
                        <h3>Typing Test One</h3>
                        <p>Time - One Min</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">A quick brown fox jumps over the little lazy dog.</p>
                        
                    </NavLink>
                </div>
            </div>

            <div className="tests-column">
                <div className="tests-card tests-b12">
                    <NavLink to="1-min-test-2">
                        <p>
                            <span className="material-symbols-outlined">east</span>&nbsp;  
                            <span className="material-symbols-outlined">edit_note</span>&nbsp; 
                            <span className="material-symbols-outlined">west</span>
                        </p>
                        <h3>Typing Test Two</h3>
                        <p>Time - One Min</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">For God so loved the world that he gave His only Son so that whoever...</p>
                    </NavLink>
                </div>
            </div>

            <div className="tests-column">
                <div className="tests-card tests-b13">
                    <NavLink to="1-min-test-3">
                        <p>
                            <span className="material-symbols-outlined">east</span>&nbsp;  
                            <span className="material-symbols-outlined">edit_note</span>&nbsp; 
                            <span className="material-symbols-outlined">west</span>
                        </p>
                        <h3>Typing Test Three</h3>
                        <p>Time - One Min</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">The Lord is my shepherd,
                        I will not be in need. He lets me lie...</p>
                    </NavLink>
                </div>
            </div>

            <div className="tests-column">
                <div className="tests-card tests-b17">
                    <NavLink to="1-min-test-4">
                        <p>
                            <span className="material-symbols-outlined">east</span>&nbsp;  
                            <span className="material-symbols-outlined">edit_note</span>&nbsp; 
                            <span className="material-symbols-outlined">west</span>
                        </p>
                        <h3>Typing Test Four</h3>
                        <p>Time - One Min</p>
                        <span className="txt-yellow">Typing Text</span>
                        <p className="txt-sm">Blessed are those whose way is blameless,
                            Who walk in the ...
                        </p>
                    </NavLink>
                </div>
            </div>
            </div>
    )
}
 
export default Tests;