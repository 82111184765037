import React, {useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router";
//import { NavLink } from "react-router-dom";

import parse from 'html-react-parser'
import {testNames} from '../data/Tests/TypingTest'
import TestSettings from '../components/Test-settings-modal'

import {Keyboard, KeyboardActiveKey} from '../components/keyboard'
//import {StatsSideBar, StatsMainBar} from "../components/Stats-sidebar";

import {StatsMainBarV} from "../components/Stats-sidebar";

import {textType} from "../components/common"

export const KeyBoardContext = React.createContext();



const TypingTest = () => {
    const params = useParams();
    const param = params.time ? params.time:'';
    let typingText = '';
    let errorKeys = []
    const location = useLocation();
    

    if(param == '1'){
        typingText = testNames[0];
    }
    if(param == '2'){
        typingText = testNames[1];
    }
    if(param == '3'){
        typingText = testNames[2];
    }
    if(param == '4'){
        typingText = testNames[3];
    }
    if(param == '5'){
        typingText = testNames[4];
    }
    if(param == '6'){
        typingText = testNames[5];
    }


    let secs = 0, stop = 60, counterStarted = false, counter;
    let w = 0;
    const timer = (e) => {
        if(counterStarted === false){
        counterStarted = true;
        counter = setInterval(function(){
                if(secs <= stop){
                    w = ((w+1.66) > 98) ? 100 : (w+1.66)
                    //document.querySelector('.tests-main-title').innerHTML = secs + " seconds";
                    document.querySelector('#totalTime').innerHTML = secs;
                    document.querySelector(".test-bar").classList.add('test-bar-show');
                    document.querySelector(".test-bar").style.width = w +'%';
                    secs++;
                } else {
                    document.querySelector(".result-card").classList.add('result-card-show');
                    clearInterval(counter);
                    counterStarted = false;
                    secs = 60;
                }
            },1000)
          }
        }
    

    //console.log('typingText',typingText);
    let str = [...typingText['text']];
    let stra = typingText['text'];
    

    let strLength = str.length;

    let scroll_times = 0;
    let caps = 0;
    let seconds = 0;
    let accuracy = 0;
    let typingTextFinal = str.map((v, index) => {
        return '<div id="chr_'+index+'" className="verse">'+v+'</div>';
    })

    let mainTitle = typingText['time'];
    



    let key = 0;


    useEffect(() => {
        //textType("Typing Text 123", 80, "#main-title")
        document.addEventListener('keydown', handleKeydown)
        document.addEventListener("keyup", handleKeyUp);
        document.querySelector("#chr_0").classList.add('cursor');
        document.querySelector(".text").classList.add('tooltip');
        document.querySelector(".key-"+stra[key].toLowerCase()).classList.add('is-active');
        
        
        console.log('effect called', key)
        console.log('counter', counter)
        //return () => key 
        return () => {
            key = 0;
            document.removeEventListener('keydown', handleKeydown)
            document.removeEventListener('keyup', handleKeyUp)
        };
    }, [])
    const handleTestSettings = (e) => {
        e.preventDefault();
        document.querySelector(".main-settings").classList.remove('main-settings-hide');
        document.querySelector(".main-settings").classList.add('main-settings-show');
    }
    const handleKeyUp = (e) => {
    //function handleKeyUp(e) {
        //setLoc(location.key)
        console.log('counter ku', counter)
        console.log('key up ', key);
        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps = 1;
            } else {
                caps = 0;
            }
        }
        //console.log("key upsecond", secs)
        //if(key === strLength){
        if(secs === 60){
            document.querySelector(".result-card").classList.add('result-card-show');
            document.querySelector(".result-card-box").classList.add('confetti1');
            e.preventDefault();
            e.stopPropagation();
            return false;

        }
    }
    


    const handleKeydown = (e) => { 
        if(key == 1) { timer(e); }
        if(secs === 60){
            e.preventDefault();
            e.stopPropagation();
            return false;
        }


        if(e.keyCode === 16){ return; } //Shift Key  
        if(e.keyCode === 20){ //CapsLock
            caps = 1;
        } 
        
        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps =  1;
            } else {
                caps = 0;
            }
        }
        
        if(caps){
            document.querySelector(".overlay").classList.remove('overlay-hide');
            document.querySelector(".overlay").classList.add('overlay-show');
            e.preventDefault();
            e.stopPropagation();
            return false;
        } else {
            document.querySelector(".overlay").classList.add('overlay-hide');
        }
        
           
        if(e.keyCode === 27){ return; } //Escape Key s
        if(e.keyCode === undefined){ return; }
 
        const element = document.querySelector(".main-text");

        let scroll = 55;
        let h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

        
        if(key > 2 && key % 138 == 0){
            scroll_times++;
            if(scroll_times < 3){scroll = scroll * 2.15}
            else if(scroll_times < 6){scroll = scroll * 2.25}
            else if(scroll_times < 9){scroll = scroll * 2.35}
            else if(scroll_times < 12){scroll = scroll * 2.45}
            else if(scroll_times < 15){scroll = scroll * 2.55}
            else if(scroll_times < 18){scroll = scroll * 2.65}
            //console.log('scroll times', scroll_times)
            element.scrollBy({
                top: scroll,
                left: 0,
                behavior: "smooth",
              });
        } /*else if (key == 204){
            element.scrollBy({
                top: 150,
                left: 0,
                behavior: "smooth",
              });
        } else if (key == 306){
            element.scrollBy({
                top: 200,
                left: 0,
                behavior: "smooth",
              });
        } */

        if(e.keyCode === 32){ 
            e.preventDefault();
         }
        
        KeyboardActiveKey(stra[key+1]);
      

        document.querySelector("#chr_0").classList.remove('cursor');
        document.querySelector(".text").classList.remove('tooltip');
        document.querySelector(".text").classList.add('tooltip-hide');
 
        let red = document.querySelectorAll(".red").length;
        
        accuracy = (100 - Math.round((red/key)*100));
        accuracy = isNaN(accuracy) ? 0 : accuracy;
        //let wpm = parseInt(key / 5);
        const avgWords = (key / 5)
        let wpm = Math.round(avgWords * (60 / secs))
        wpm = (wpm == 'Infinity') ? 0 : wpm
        const avgNetWords = ((key-red) / 5)
        let nwpm = Math.round(avgNetWords * (60 / secs))
        nwpm = (nwpm == 'Infinity') ? 0 : nwpm
        document.querySelector("#errorKey").innerText = red;
        document.querySelector("#accuracy").innerText = accuracy+'%';
        document.querySelector("#gwpm").innerText = isNaN(wpm) ? 0 : wpm;
        document.querySelector("#nwpm").innerText = isNaN(nwpm) ? 0 : nwpm;
        document.querySelector(".tests-accuracy-card").innerText = accuracy+'% Accuracy';
        document.querySelector(".tests-wpm-card").innerText = wpm + ' WPM';
        //document.querySelector("#totalTime").innerText = seconds;
         console.log('stats', strLength, key, wpm, secs)
        // backspace typing
        if(e.keyCode == 8){ 
            if(key != 0){
                document.querySelector("#chr_"+key).classList.remove('cursor');
                --key; 
            }

            document.querySelector("#chr_"+key).classList.remove('red');
            document.querySelector("#chr_"+key).classList.remove('green');
            document.querySelector("#chr_"+key).classList.add('cursor');
            
            
            if(key == 0){
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        if(e.key == stra[key]){
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#keyAudio").play() }
            document.querySelector("#chr_"+key).classList.add('green');
            document.querySelector("#chr_"+key).classList.remove('cursor');
            console.log('Key==Str', e.key, stra[key])
            key++; 
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }
            if(e.key != 'Enter'){
                //redFlag++; 
            }
            e.preventDefault();
            e.stopPropagation();
            return false;
        } 
        if(e.key != stra[key]){
            errorKeys.push(stra[key])
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#errorKeyAudio").play() }
            console.log('Key!=Str', e.key, stra[key])
            document.querySelector("#chr_"+key).classList.add('red');
            document.querySelector("#chr_"+key).classList.remove('cursor');

            key++;
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }

            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        
        
        
    }


    return (

        <div className="main">
            <div className="hide-mobile">
                <span className="material-symbols-outlined title-anim1">cloud</span>
            </div>
            
            {/*<span className="material-symbols-outlined title-anim2">hotel_class</span>*/}
            <div className="main-title" id="main-title">
                <h4 className="bg-main-title">Typing Test - {mainTitle}</h4>
                <span className="material-symbols-outlined main-setting-icon " onClick={handleTestSettings}>add_circle</span>
                {/*<div className="nav-fc8">
                    <a className="material-symbols-outlined sub-nav-icon rule-settings" onClick={handleTestSettings}>add_circle</a>
                    <div style={{display: "inline-block"}}>
                        <span className="txt-sm" style={{display:"block"}}>Time</span>
                        <span id="totalTime" className="txt-lg" style={{fontWeight: "bold"}}>0</span>
                    </div>
                </div>*/}
                
            </div><span className="test-bar"></span>
            <div className="main-text" style={{height:"285px"}}>
                { parse('<div className="text"><span class="tooltiptext tests-tooltiptext">Start Typing!</span>'+typingTextFinal.join("")+'</div>') }
                {/*<StatsMainBar />*/}
                

            </div>
            <div className="lesson-main-stats-section">
                <StatsMainBarV />
            </div>
            {/*keyboard start*/}
            <Keyboard KeyboardFrom="type"></Keyboard>
            {/*keyboard end*/}

            {/* Stats SideBar start */}
            {/*<StatsSideBar></StatsSideBar>*/}
            {/* Stats SideBar end */}

                <div className="result-card">
                    <div className="result-card-box">
                        <h3>Awesome Typing!!</h3>
                        <p className="bg-star-img">
                            <img className="star-l" src="../star-congrats-1.svg" alt=""></img>
                            <img className="star-m" src="../star-congrats-1.svg" alt=""></img>
                            <img className="star-r" src="../star-congrats-1.svg" alt=""></img>
                        </p>
                        
                        <p className="tests-result-card-para">Your Speed was <span className="tests-wpm-card"> </span> 
                        <br />with <span className="tests-accuracy-card"> </span> </p>
                        <p className="p-txt-sm">

                            <a href="/tests/1" className="button btn-b11">Try Again</a> <br />  
                            <a href="/tests/2" className="button btn-b15"> Typing Test 2</a>
                            <a href="/tests/3" className="button btn-b15"> Typing Test 3</a>
                            <a href="/tests/4" className="button btn-b15"> Typing Test 4</a> 
                        </p>                   
                    </div>
                </div>
            
            
                <TestSettings />
         </div>

    )
}

export default TypingTest;