import Login from "../components/Login"
import { GoogleOAuthProvider } from '@react-oauth/google';
export default function PageLogin() {
    return (
      <div className="page">
        {/*<h2 className="page-title bg-main-title">Login/Register</h2>*/}
        <div className="page-text">
          <GoogleOAuthProvider clientId="881152342080-citqb7ls4f21rgvmpoup8a2kubnm6re7.apps.googleusercontent.com">
            <Login></Login>
          </GoogleOAuthProvider>  
        </div>
     </div>
    )
  }