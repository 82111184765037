import React from "react";
import {bookNames} from '../data/main/book-names'
import { NavLink } from "react-router-dom";
const Books = () => {
    //bad solution for clear counter if someone moves pages fron test page without completing
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    let v = 0;
    //console.log('in tests.js',key)
    return (
    <div className="main">
        <div className="main-title">
            <h4 className="bg-main-title">Bible Books</h4>
        </div>
        <div className="main-text" style={{padding: "15px"}}>
            <div className="books-row">
                    
                { bookNames.map((b) => //{
                    //v = sessionStorage.getItem("book-verse-"+b.id) ? sessionStorage.getItem("book-verse-"+b.id):0
                    ( b.id > 0 ?
                    <div className="main-column" key={b.id}>
                        <div className={"tests-card ddd-bk"}>
                            { 
                                //v = parseInt(sessionStorage.getItem("book-verse-"+b.id) ? sessionStorage.getItem("book-verse-"+b.id):0)
                            }
                            <NavLink to={"/"+b.book.replace(/\s+/g, '-').toLowerCase()}>
                                <h3 className="" style={{color:"#466399", fontSize:"0.85rem"}}><span className="material-symbols-outlined" style={{verticalAlign: "middle"}}>auto_stories</span> {b.book}</h3>
                                <p className="txt-sm txt-blue"> [{b.chapter} Chapter(s), {b.verse} Verses]</p>
                                <div className="main-progress-bar nav-bg1" >
                                    <div className={"main-progress-percent-Gospels css-"+ b.category}>{Math.round((sessionStorage.getItem("book-verse-"+b.id)/b.verse)*100)}% Complete</div>
                                </div>
                            </NavLink>
                        </div>
                    </div> : '')
                    //}
                )
                }
            </div>
        </div>
    </div>
    )
}
 
export default Books;