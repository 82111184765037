import React, {useEffect, useState, useRef} from "react";
import {testNames} from '../data/Tests/TypingTest'
import { NavLink, useNavigate } from "react-router-dom";
const TestSettings = () => {
    const navigate = useNavigate();
    //console.log(ChapterVerseCount[61]["chapters"]);
    useEffect(() => {
        //console.log(ChapterVerseCount[0]);
    }, []);
    const handleOnClick = (e) => {
        //document.querySelector(".main-settings").style.display = "none";
        document.querySelector(".main-settings").classList.add('main-settings-hide');
    }
    const handleOnChange = (e) => {
        e.preventDefault();
        sessionStorage.setItem("version", e.target.value);
        //setVersion(e.target.value)
        console.log(e.target.value);
        console.log(sessionStorage);
        document.querySelector(".main-settings").classList.add('main-settings-hide');
        window.location.reload();
    }
    const handleOnClickLink = (e) => {
        e.preventDefault();
        console.log(e.target.parentNode.id, e.target.parentNode.href)
        //navigate(e.target.parentNode.id)
        //return <Redirect to={e.target.parentNode.href} />
        document.querySelector(".main-settings").classList.add('main-settings-hide');
        return
    }
    const bookOnChange = (e) => {
        e.preventDefault();
        console.log(e.target.value);
        let chapterHTML = '';
        /*for(let i=1; i<=e.target.value; i++){
            chapterHTML += "<option>Chapter "+ i +"<option>";
        }ChapterVerseCount[e.target.value]["chapters"].map((b) =>{
            console.log(b.chapter)
        })*/
        //document.querySelector("#chapterOnChange").innerHTML = chapterHTML;
    }
    const chapterOnChange = (e) => {
        e.preventDefault();
    }
    const verseOnChange = (e) => {
        e.preventDefault();
    }

    return (
        <div className="main-settings">
            <div className="main-settings-box">
                <h3>Pick a Test</h3>
                <span className="close" onClick={(e) => handleOnClick(e)}><span className="material-symbols-outlined">cancel</span></span>
                
                
                <div className="tests-row" style={{margin: "15px"}}>
                        { testNames.map((b) => //{
                                //v = sessionStorage.getItem("book-verse-"+b.id) ? sessionStorage.getItem("book-verse-"+b.id):0
                                ( b.id > 0 ?
                                <div className="main-column" key={b.id}  style={{width:"33%"}}>
                                    <div className={"tests-card white-bk" }>
                                        { 
                                            //v = parseInt(sessionStorage.getItem("book-verse-"+b.id) ? sessionStorage.getItem("book-verse-"+b.id):0)
                                        }
                                        <NavLink to={b.id} id={b.id} onClick={() => window.location.href='/tests/'+b.id}>
                                            {<h3 className="" style={{fontSize:"1rem"}}><div className="material-symbols-outlined" style={{verticalAlign:"middle"}}>edit_note</div> {b.time}</h3>}
                                            <p className="txt-sm txt-blue" style={{lineHeight: "20px", padding: "10px"}}>{b.title}</p>
                                            {/*<div className="main-progress-bar nav-bg1">
                                                <div className={"main-progress-percent-"+ b.category}>{Math.round((sessionStorage.getItem("book-verse-"+b.id)/b.verse)*100)}% Completed</div>
                                            </div>*/}
                                        </NavLink>
                                    </div>
                                </div> : '')
                                //}
                            )
                        }
                </div>
                {/*<div>
                    <span className="main-settings-span">Book</span>
                    <select onChange={(e)=>bookOnChange(e)}>
                        <option value="61">1 John</option>
                        <option value="42">John</option>
                        <option value="39">Mat</option>
                    </select>
                    <select id="chapterOnChange" onChange={(e)=>chapterOnChange(e)}>
                    </select>
                    <select id="verseOnChange" onChange={(e)=>verseOnChange(e)}>
                    </select>
                </div>*/}
                
                                
            </div>
        </div>
    )
}

export default TestSettings