export default function Block() {
    return (
        <div className="page-block">
            <ul>
                <li><a href="/blog/bible-typing-vs-bible-reading">Bible Typing Vs Bible Reading</a></li>
                <li><a href="/blog/why-typing-bible">Why should I type Bible?</a></li>
                <li><a href="/blog/type-memorize-bible-verse">Can typing Bible verse help memorizing the Bible verse?</a></li>
                <li><a href="/blog/typing-benefits">What are the benefits of learning typing?</a></li>
                <li><a href="/blog/why-learn-typing">Why should I learn typing?</a></li>
                
       
            </ul>
        </div>
    )
  }