import React, {useEffect, useState} from "react";
import {bookNames} from '../data/main/book-names'
import { Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title,Tooltip, Legend } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';

import './profile.css'
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../components/Auth";

const Profile = () => {
    ChartJS.register(ArcElement, CategoryScale,
        LinearScale,
        BarElement,
        Title,Tooltip, Legend);
    //bad solution for clear counter if someone moves pages fron test page without completing
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    const auth = useAuth()
    const navigate = useNavigate();


    const logout = (e) => {
        auth.logout();
        navigate("/");
    }
    console.log("user -id", auth)
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState(null);
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }
    
    const handleSubmit = async(event) => {
    event.preventDefault();
    //console.log(inputs);
        const token = sessionStorage.getItem('token')
        const response = await fetch('/api/users/123', {
            method: 'PATCH',
            body: JSON.stringify(inputs),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization':'Bearer '+token
            }
        })
        const json = await response.json();
        console.log(json)
        if(!response.ok){
           // setError(json.error)
           console.log(json.error)
        }
        if(response.ok){
            //setError(null)
            console.log(json)
        }
    }
    const handlePassword = async(event) => {
        event.preventDefault();
        //console.log(inputs);
            const token = sessionStorage.getItem('token')
            const response = await fetch('/api/users/updatePassword', {
                method: 'POST',
                body: JSON.stringify(inputs),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization':'Bearer '+token
                }
            })
            const json = await response.json();
            console.log(json)
            if(!response.ok){
               // setError(json.error)
               //console.log(json.error)
               setError(json.error)
            }
            if(response.ok){
                setError(null)
                console.log(json)
            }
        }
    
    const handleTabClick = (e, tabId) => {
        let i, tabcontent, tablinks;
        
        tabcontent = document.querySelectorAll(".tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.querySelectorAll(".tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        console.log('in tab',tabId);
        document.querySelector("#"+tabId).style.display = "block";
        e.currentTarget.className += " active";
    }
    const vLessonBarOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Lesson\'s Progress Chart',
          },
        },
      };
      const vBibleBarOptions = {
        //responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Lesson\'s Progress Chart',
          },
        },
      };
    //const vBarLabels = ['Basic', 'Beginner', 'Iintermediate', 'Advance'];
    let d = {'Basic':0, 'Beginner':0, 'Intermediate':0, 'Advance':0}
    let d2 = {'Total':20, 'Total':20, 'Total':20, 'Total':20}
    for(let i=1; i<=20;i++){
        if(sessionStorage.getItem("ba-"+i)){ d.Basic = i } 
        if(sessionStorage.getItem("be-"+i)){ d.Beginner = i }
        if(sessionStorage.getItem("in-"+i)){ d.Intermediate = i }
        if(sessionStorage.getItem("ad-"+i)){ d.Advance = i }
        d2.Total = 20
    }
    const vBarData = {
        //vBarLabels,
        datasets: [
            {
            label: 'Lesson Typed',
            barPercentage: 1.5,
            barThickness: 10,
            maxBarThickness: 15,
            base: 0,
            data: d,
            backgroundColor: ["#466399"],
            },
            {
            label: 'Lesson Total',
            barPercentage: 1.5,
            barThickness: 10,
            maxBarThickness: 15,
            base: 0,
            data: d2,
            backgroundColor: ["#79a196"],
            }
        ],
    };
    let bdata = {}
    let bdata2 = {}
    bookNames.map((b) => {
        if(b.id > 39){
            bdata[b.book]= sessionStorage.getItem("book-verse-"+b.id) ? sessionStorage.getItem("book-verse-"+b.id) : 0
            bdata2[b.book]=b.verse
        } 
    })
    const vBibleData = {
        //vBarLabels,
        datasets: [
            {
            label: 'Typed Verses',
            barPercentage: 1.5,
            barThickness: 5,
            maxBarThickness: 10,
            base: 0,
            data: bdata,
            backgroundColor: ["#466399"],
            },
            {
                label: 'Total Verses',
                barPercentage: 1.5,
                barThickness: 5,
                maxBarThickness: 10,
                base: 0,
                data: bdata2,
                backgroundColor: ["#79a196"],
                }
        ],
    };
    console.log("vBibleData", vBibleData, bdata)
    let errKeys = '{}'
    if(sessionStorage.getItem("error_keys")){ 
        errKeys = sessionStorage.getItem("error_keys") ;
        console.log("here errKeys", Object.keys(JSON.parse(errKeys)), Object.values(JSON.parse(errKeys)));
    } 
    //console.log("here errKeys", Object.keys(JSON.parse(errKeys)), Object.values(JSON.parse(errKeys)));
    const doughnutData = {
        labels: Object.keys(JSON.parse(errKeys)),
        datasets: [
          {
            label: 'typed incorrect (times)',
            data: errKeys ? Object.values(JSON.parse(errKeys)): {},
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
    useEffect(() => {
        const user_id = sessionStorage.getItem('_id')
        const token = sessionStorage.getItem('token')
        document.querySelector("#defaultOpen").click();
        const profile = async() => await fetch('/api/users/'+user_id, {
            method: 'GET',
            //body: JSON.stringify(inputs),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization':'Bearer '+token
            }}) 
                        .then((response) => {
                            return response.json()
                        })
                        .then((data) => {
                            console.log(data)
                            setInputs(data)
                        })
        profile()
       /* return () => {
            document.removeEventListener('keyup', handleKeyUp)
        }; */
    }, [])
    //document.querySelector("#defaultOpen").click();
    return (
        <div className="lessons-container">
            <div className="tab">
                <ul>
                    <li className="tablinks" onClick={(e) => handleTabClick(e, 'ProfileTab')} id="defaultOpen">
                        <span className="material-symbols-outlined">person</span> Profile</li>
                    <li className="tablinks" onClick={(e) => handleTabClick(e, 'Beginner')}>
                        <span className="material-symbols-outlined">update</span> Change Password</li>
                    <li className="tablinks" onClick={(e) => handleTabClick(e, 'Intermediate')}>
                        <span className="material-symbols-outlined">monitoring</span> Progress</li>
                    <li className="tablinks" onClick={(e) => logout(e)}>
                        <span className="material-symbols-outlined">logout</span> Logout</li>
                </ul>
            </div>
            

            <div id="ProfileTab" className="tabcontent">
                <div className="profile-container">
                        {/*Email: {auth.user} <br />*/}
                        <form onSubmit={handleSubmit} className="bg-main-title"> 
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{width: "12%"}}>
                                        <label htmlFor="title">Title</label>
                                        <input type="text" name="title" placeholder="Mr./Mrs./Miss" value={inputs.title || ""}  onChange={handleChange} />
                                    </td>
                                    <td >
                                        <label htmlFor="first_name">First Name</label>
                                        <input type="text" name="first_name" placeholder="First Name" value={inputs.first_name || ""}  onChange={handleChange} />
                                    </td>
                                    <td >
                                        <label htmlFor="last_name">Last Name</label>
                                        <input type="text" name="last_name" placeholder="Last Name" value={inputs.last_name || ""}  onChange={handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td  colSpan="2">
                                        <label htmlFor="address">Address</label>
                                        <input type="text" name="address" placeholder="Address" value={inputs.address || ""}  onChange={handleChange} />
                                    </td>
                                    <td  >
                                        <label htmlFor="zip">Zip</label>
                                        <input type="text" name="zip" placeholder="Zip/Postcode" value={inputs.zip || ""}  onChange={handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td >
                                        <label htmlFor="city">City</label>
                                        <input type="text" name="city" placeholder="City" value={inputs.city || ""}  onChange={handleChange} />
                                    </td>
                                    <td >
                                        <label htmlFor="state">State</label>
                                        <input type="text"  name="state" placeholder="State Name" value={inputs.state || ""}  onChange={handleChange} />
                                    </td>
                                    <td >
                                        <label htmlFor="country">Country</label>
                                        <input type="text"  name="country" placeholder="Country" value={inputs.country || ""}  onChange={handleChange} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <input type="submit" />
                        </form>
                        

                </div>
            </div>

            <div id="Beginner" className="tabcontent">
            <div className="container">
                        {/*Email: {auth.user} <br />*/}
                        <form onSubmit={handlePassword} className="bg-main-title"> 
                        {error && <p className="red error">{error }</p>}
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{width: "12%"}}>
                                        <label htmlFor="oldPassword">Enter Old Password</label>
                                        <input type="text" name="oldPassword" placeholder="Enter Old Password" value={inputs.oldPassword || ""}  onChange={handleChange} />
                                    </td>

                                </tr>
                                <tr>

                                    <td style={{width: "44%"}}>
                                        <label htmlFor="newPassword">Enter New Password</label>
                                        <input type="text" name="newPassword" placeholder="Enter First Name" value={inputs.newPassword || ""}  onChange={handleChange} />
                                    </td>

                                </tr>
                                <tr>

                                    <td style={{width: "44%"}}>
                                        <label htmlFor="newPassword2">Re Enter New Password</label>
                                        <input type="text" name="newPassword2" placeholder="Re Enter Last Name" value={inputs.newPassword2 || ""}  onChange={handleChange}/>
                                    </td>
                                </tr>

                                
                            </tbody>
                        </table>
                        <input type="submit" />
                        </form>
                </div>
                        
            </div>

            <div id="Intermediate" className="tabcontent" style={{backgroundColor:"#eee"}}>

                <div className="profile-row">
                    <div className="profile-column" style={{width:"98%"}}>
                        <div className="profile-card" style={{}}>
                            <Bar options={vBibleBarOptions} data={vBibleData} />
                        </div>
                    </div>
                </div>

                <div className="profile-row">
                    <div className="profile-column">
                        <div className="profile-card" style={{height: "210px"}}>
                            <Bar options={vLessonBarOptions} data={vBarData} />
                        </div>
                    </div>
                    <div className="profile-column">
                        <div className="profile-card" style={{height: "210px"}}>
                            <Doughnut data={doughnutData} />
                        </div>
                    </div>
                    
                </div>
                
                {/*<div className="profile-row">
                    { bookNames.map((b) => 
                            ( b.id > 39 ?
                            <div className="profile-column" key={b.id} style={{}}>
                                <div className={"profile-card " + b.category}>
                                    <NavLink to={"/"+b.id}>
                                        <div className="main-progress-bar nav-bg8">
                                            <div style={{color:"#466399"}}>{b.book} : {Math.round((sessionStorage.getItem("book-verse-"+b.id)/b.verse)*100)}% Completed</div>
                                            <div className={"main-progress-percent-"+ b.category} style={{width:Math.round((sessionStorage.getItem("book-verse-"+b.id)/b.verse)*100)+"%"}}> </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div> : '')
                        )
                    }
                </div>*/}
            </div>

            <div id="Advance" className="tabcontent">
              
               
            </div>
            
        </div>
    )
}

 
export default Profile;