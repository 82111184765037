import { useState, createContext, useContext, useEffect } from "react";

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)

    const login = (user) => {
        setUser(user)
    }
    const logout = () => {
        setUser(null)
        sessionStorage.clear()
        localStorage.clear()
        //sessionStorage.removeItem('email')
        //sessionStorage.removeItem('token')
    }
    useEffect(() => {
        //const user = JSON.parse(sessionStorage.getItem('email'))
        const user = sessionStorage.getItem('email')
        console.log(user)
        if(user){
            setUser(user)
        }
    },[])
    return (
        <AuthContext.Provider value={ { user, login, logout} }>
            { children } 
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}