import React, {useEffect, useState, useRef} from "react";
import { useParams, useLocation, Redirect, useNavigate, redirect } from "react-router-dom";
import parse from 'html-react-parser'
import {Keyboard, KeyboardActiveKey, CapsLockOverlay} from "../components/keyboard";
import {basicLessons, beginnerLessons, intermediateLessons, advanceLessons} from '../data/Lessons/Lessons-data'
import {StatsMainBarV, StatsMainBar} from "../components/Stats-sidebar";
import { sessionSetItem, sessionGetItem } from "../components/common";
import { useAuth } from "../components/Auth";
//import {settingsAccuarcy} from "../components/Settings"
import { SettingsAccuarcy, SettingsSpeed } from "../components/Settings";


const TypingLesson = () => {
    const navigate = useNavigate();
    const auth = useAuth()
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    let object = '';
    const params = useParams();
    console.log(params.lesson);
    if(params.lesson == 'basic'){
        object = basicLessons;
    }
    if(params.lesson == 'beginner'){
        object = beginnerLessons;
    }
    if(params.lesson == 'intermediate'){
        object = intermediateLessons;
    }
    if(params.lesson == 'advance'){
        object = advanceLessons;
    }
    let check = [999, 111]
    
    let setLesson = 0;
    if(params.id){
        setLesson = params.id - 1;
        console.log('paramsfdf11', params.id, setLesson)
    }  
    if(params.id > object.length){
        setLesson = 0;
        console.log('paramsfdf222', setLesson)
    }
    let boxletter = 'letter';
    let boxDiv = 'lesson-text';
    //if(check.includes(params.id)){
    /*if(params.id == 5 || params.id == 9 || params.id == 10 || params.id == 13 || 
        params.id == 18 || params.id == 19 || params.id == 20 || params.id == 21 ||
        params.id == 22 || params.id == 23){*/
    if(params.id == 999 || params.lesson == 'beginner'){
        boxletter = 'verse';
        boxDiv = 'text';
    }


    console.log('params', params.lesson, setLesson, object.length)
    
    const basic = {}
    const handleClickQuote = (e) => {
        e.preventDefault();
        document.querySelector(".quote-result-card").classList.remove('quote-result-card-show');
        document.querySelector(".quote-result-card").classList.add('quote-result-card-hide');
    }

    let [firstVerse, setFirstVerse] = useState(setLesson);
    const totalVerses = object.length - 1;
    let str = [...object[firstVerse]['text']];
    let stra = object[firstVerse]['text'];
    

    let strLength = str.length;
    console.log(strLength);

    let caps = 0
    let seconds = 0
    let accuracy = 0
    let errorKey = []
    let verse = str.map((v, index) => {
        return '<div id="chr_'+index+'" className="'+boxletter+'">'+v+'</div>';
    })
    let [firstVerseText, setFirstVerseText] = useState(verse);
    let [mainTitle, setMainTitle] = useState(object[firstVerse]['title']);
    let [kbImg, setKBImg] = useState(object[firstVerse]['img'] ? object[firstVerse]['img'] : null);
    let [helpText, setHelpText] = useState(object[firstVerse]['help'] ? object[firstVerse]['help'] : null);
    let startTime, endTime;
    const settings_accuracy = sessionStorage.getItem("settings_accuracy") ? sessionStorage.getItem("settings_accuracy") : 90;
    const settings_speed = sessionStorage.getItem("settings_speed") ? sessionStorage.getItem("settings_speed") : 50;
    const start = () => {
        startTime = new Date().getTime();
    }
    const end = () => {
        endTime = new Date().getTime();
        let timeDiff = parseInt(endTime) - parseInt(startTime);
        console.log("diff", startTime, endTime)
        seconds = Math.round(timeDiff/1000);
    }
    const statsPop = () => {
        const basic = ['ba-1', 'ba-2', 'ba-3', 'ba-4', 'ba-5', 'ba-6', 'ba-7', 'ba-8', 'ba-9', 'ba-10', 'ba-11', 'ba-12', 'ba-13', 'ba-14', 'ba-15', 'ba-16', 'ba-17', 'ba-18', 'ba-19', 'ba-20']
        let accuracy = 0;
        //basic.map((ba) => {
        let cnt = 0;
        for(let i=0; i<basic.length;i++){
            if(sessionStorage.getItem(basic[i])){
                console.log('ba.accuracy',JSON.parse(sessionStorage.getItem(basic[i])).accuracy)
                accuracy += parseInt(JSON.parse(sessionStorage.getItem(basic[i])).accuracy);
                cnt++
            }
        }
        //})
        console.log('accuracyPop',parseInt(accuracy/cnt))
        return parseInt(accuracy/cnt)
    }

    let key = 0;

    useEffect(() => {
        statsPop()
        document.addEventListener('keydown', handleKeydownMain)
        document.addEventListener("keyup", handleKeyUpMain);
        document.querySelector("#chr_0").classList.add('cursor');
        document.querySelector("."+boxDiv).classList.add('tooltip');
        document.querySelector(".key-"+stra[key].toLowerCase()).classList.add('is-active');
        return () => {
            key = 0;
            document.removeEventListener('keydown', handleKeydownMain)
            document.removeEventListener('keyup', handleKeyUpMain)
        };
        
    }, [])
    const avgStats = () => {
        if(sessionStorage.getItem('avgAccuracy')){
            const avgAccuracy = Math.round((parseInt(sessionStorage.getItem('avgAccuracy')) + accuracy)/2)
            sessionStorage.setItem('avgAccuracy', avgAccuracy)
        } else {
            sessionStorage.setItem('avgAccuracy', accuracy)
        }
        /*if(sessionStorage.getItem('avgSeconds')){
            const avgSeconds = Math.round((parseInt(sessionStorage.getItem('avgSeconds')) + seconds)/2)
            sessionStorage.setItem('avgSeconds', avgSeconds)
        } else {
            sessionStorage.setItem('avgSeconds', seconds)
        }*/
        if(sessionStorage.getItem('totSeconds')){
            const totSeconds = Math.round((parseInt(sessionStorage.getItem('totSeconds')) + seconds))
            sessionStorage.setItem('totSeconds', totSeconds)
        } else {
            sessionStorage.setItem('totSeconds', seconds)
        }
        const avgWords = (strLength / 5)
        const WPM = Math.round(avgWords * (60 / seconds))
        if(sessionStorage.getItem('avgWPM')){
            const avgWPM = Math.round((parseInt(sessionStorage.getItem('avgWPM')) + WPM)/2)
            sessionStorage.setItem('avgWPM', avgWPM)
        } else {
            sessionStorage.setItem('avgWPM', WPM)
        }
        //strLength, errorKeyl,et wpm = parseInt(key / 5);
    }

    const handleKeyUpMain = (e) => { //e.preventDefault();

        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps = 1;
            } else {
                caps = 0;
            }
        }
        console.log('key up', object.length, firstVerse, sessionGetItem("ba-1"))
        const obj = sessionGetItem("lesson")
        //console.log(obj['ba-1'].wpm)
        document.querySelector(".lessons-main").classList.remove('confetti1');
        if(key === strLength){
            let error = document.querySelectorAll(".red").length;
            console.log('stats',accuracy, seconds, errorKey.toString())
            document.querySelectorAll('.'+boxletter).forEach(el => el.classList.remove('red'))
            document.querySelectorAll('.'+boxletter).forEach(el => el.classList.remove('green'))
            document.querySelector("#chr_0").classList.add('cursor');
            
            
            
            let lessons = {}; 
            const errorKeysFilter = errorKey.filter(word => word.trim().length > 0);
            lessons = { completed: 1, accuracy:accuracy, time:seconds, strLength:strLength, code:object[firstVerse]['code'], errorKeys:errorKeysFilter.toString(), errorKeyCnt:errorKey.length}
            //lessons.book
            sessionSetItem(object[firstVerse]['code'], lessons)
            const token = sessionStorage.getItem('token')
            const saveLesson = async() => {
                const response = await fetch('/api/users/123', {
                    method: 'PATCH',
                    body: JSON.stringify(lessons),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Lesson': params.lesson,
                        'Authorization':'Bearer '+token
                    }
                })
                const json = await response.json();
                console.log(json)
                if(!response.ok){ 
                    console.log(json.error) 
                    if(json.error === "jwt expired"){ //jwt malformed
                    //if(json.error === "jwt malformed"){  // not jwt token
                        auth.logout();
                        navigate("/page/login/se");
                        return;
                    }
                    
                    //navigate("/lessons/basic/"+lesson_number)
                }
                if(response.ok){ console.log(json) }
            }
            saveLesson()
            avgStats()
            //sessionSetItem(object[firstVerse]['code'], true);
            //essionSetItem(object[firstVerse]['code'], true)           
            //console.log('l complet', sessionStorage)
            
            //basic[object[firstVerse]['code']] = { completed: 1, accuracy:accuracy, time:seconds, strLength:strLength}
            let lesson_number = firstVerse + 1;
            let lesson_url = params.lesson+"/"+lesson_number
            //console.log("basic",basic)
            if(JSON.parse(sessionStorage.getItem(object[firstVerse]['code'])).accuracy < parseInt(settings_accuracy)){
                document.querySelector(".quote-result-card").classList.remove('quote-result-card-hide');
                document.querySelector(".quote-result-card").classList.add('quote-result-card-show');
                document.querySelector("#accuracy_card").innerText = "Your Accuracy: "+JSON.parse(sessionStorage.getItem(object[firstVerse]['code'])).accuracy+'%';
                document.querySelector("#try_again").classList.remove('overlay-hide');
                document.querySelector("#try_again").classList.add('overlay-show');
                document.querySelector("#try_again").innerHTML = '<a href="/lessons/'+lesson_url+'"  class="button nav-bg2">Try Again.!</a>'
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            if(JSON.parse(sessionStorage.getItem(object[firstVerse]['code'])).code == "ba-60" ||
                JSON.parse(sessionStorage.getItem(object[firstVerse]['code'])).code == "be-60" ||
                JSON.parse(sessionStorage.getItem(object[firstVerse]['code'])).code == "in-60" ||
                JSON.parse(sessionStorage.getItem(object[firstVerse]['code'])).code == "ad-45"
                ){
                document.querySelector(".quote-result-card").classList.remove('quote-result-card-hide');
                document.querySelector(".quote-result-card").classList.add('quote-result-card-show');
                document.querySelector("#accuracy_card").innerText = "Congratulations!!  Level Complate, Try Next one..!";
                document.querySelector("#try_again").classList.remove('overlay-hide');
                document.querySelector("#try_again").classList.add('overlay-show');
                document.querySelector("#try_again").innerHTML = '<a href="/lessons"  class="button nav-bg2">Lessons</a>'
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            //redirect("lessons/basic/2");
            //navigate("/lessons/basic/2"); // return;

            setFirstVerse(firstVerse++);
            document.querySelector(".lessons-main").classList.add('confetti1');
            navigate("/lessons/basic/"+lesson_number)
            
            console.log(firstVerse,"kokokok")
            
            if(check.includes(firstVerse + 1)){
                boxletter = 'verse';
                boxDiv = 'text';
                document.querySelector("#lesson_text").classList.remove('text');
                document.querySelector("#lesson_text").classList.remove('lesson-text');
                document.querySelector("#lesson_text").classList.add('text');
            } else {
                boxletter = 'letter';
                boxDiv = 'lesson-text';
                document.querySelector("#lesson_text").classList.remove('lesson-text');
                document.querySelector("#lesson_text").classList.remove('text');
                document.querySelector("#lesson_text").classList.add('lesson-text');
            }
            str = [...object[firstVerse]['text']];
            stra = object[firstVerse]['text'];
            strLength = str.length;
   
            verse = str.map((v, index) => {
                return '<div id="chr_'+index+'" className="'+boxletter+'">'+v+'</div>';
            })
            setFirstVerseText(verse);
            setMainTitle(object[firstVerse]['title'])
            setKBImg(object[firstVerse]['img'])
            setHelpText(object[firstVerse]['help'])
  

            key = 0;
            console.log(key, strLength, "1")
            //if(firstVerse % 2 == 0){
            
            
        }
    }
    

    const handleKeydownMain = (e) => {  //e.preventDefault();
        if(key == 1) { start(); }
        console.log("scroll text", key)

        

        if(e.keyCode === 16){ return; } //Shift Key  
        if(e.keyCode === 20){ //CapsLock
            caps = 1;
            console.log('key 20')

        } 
        
        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps =  1;
            } else {
                caps = 0;
            }
        }
        CapsLockOverlay(e, caps);if(caps){
            console.log('caps on');
            document.querySelector(".overlay").classList.remove('overlay-hide');
            document.querySelector(".overlay").classList.add('overlay-show');
            e.preventDefault();
            e.stopPropagation();
            return false;
        } else {
            document.querySelector(".overlay").classList.add('overlay-hide');
        }

        
           
        if(e.keyCode === 27){ return; } //Escape Key s
        if(e.keyCode === undefined){ return; }

        console.log('ecroll', key)
        const element = document.querySelector(".lessons-content");
       


        if(e.keyCode === 32){ 
            console.log('space bar'); //return;
            e.preventDefault();
         }
  
        KeyboardActiveKey(stra[key+1]);

        

        document.querySelector("#chr_0").classList.remove('cursor');
        document.querySelector("."+boxDiv).classList.remove('tooltip');
        document.querySelector("."+boxDiv).classList.add('tooltip-hide');
        //stats
        //let red = 0;
        let red = document.querySelectorAll(".red").length;
        
        accuracy = (100 - Math.round((red/key)*100));
        accuracy = isNaN(accuracy) ? 0 : accuracy;
        const avgWords = (key / 5)
        let wpm = Math.round(avgWords * (60 / seconds))
        wpm = (wpm == 'Infinity') ? 0 : wpm
        const avgNetWords = ((key-red) / 5)
        let nwpm = Math.round(avgNetWords * (60 / seconds))
        nwpm = (nwpm == 'Infinity') ? 0 : nwpm
        console.log("red", red, document.querySelector("#errorKey"))
        document.querySelector("#errorKey").innerText = red;
        document.querySelector("#accuracy").innerText = accuracy+'%';
        document.querySelector("#totalTime").innerText = seconds;
        document.querySelector("#gwpm").innerText = isNaN(wpm) ? 0 : wpm;
        document.querySelector("#nwpm").innerText = isNaN(nwpm) ? 0 : nwpm;

        

        // backspace typing
        if(e.keyCode == 8){ 
            if(key != 0){
                document.querySelector("#chr_"+key).classList.remove('cursor');
                --key; 
            }

            document.querySelector("#chr_"+key).classList.remove('red');
            document.querySelector("#chr_"+key).classList.remove('green');
            document.querySelector("#chr_"+key).classList.add('cursor');
            
            
            if(key == 0){
                return;
            }
            return;
        }
        console.log('Here2',e.key,stra[key]); 
        if(e.key == stra[key]){
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#keyAudio").play() }
            end();
            document.querySelector("#chr_"+key).classList.add('green');
            document.querySelector("#chr_"+key).classList.remove('cursor');
            console.log(e.key, stra[key])
            key++;
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }
            if(e.key != 'Enter'){
                //redFlag++; 
            }
            return;
        } 
        if(e.key != stra[key]){
            end();
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#errorKeyAudio").play() }
            const el1 = document.querySelector("#chr_"+key);
            //errorKey.push(e.key)
            if(stra[key] !== undefined){
                errorKey.push(stra[key])
            }
            console.log(el1); 
            if (el1 == null) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            el1.classList.add('red');
            el1.classList.remove('cursor');
            console.log('Here', e.key)
            key++;
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }

            return;
        }
        
        
        
    }
    
    const handleSpeed = (e) => {
        e.preventDefault();
        sessionStorage.setItem("settings_speed", e.target.value);
    }
    
    
    return (
        <div className="lessons-main">
            <h4 className="lessons-main-title bg-main-title">{mainTitle}</h4>
            <div className="lessons-main-content">
                { parse('<div id="lesson_text" className="'+boxDiv+'"><span class="lessons-tooltiptext tooltiptext">Start Typing!</span>'+firstVerseText.join("")+'</div>') }
                <div className="f-help" style={!helpText ? { display: "none"} : { }}>
                    <br></br><hr></hr>
                    {<div style={{padding: "35px 0"}}
                        dangerouslySetInnerHTML={{__html: helpText}}
                    />}
                    <img className="f-img" src={kbImg}   />
                    {/*<img className="f-img" src='/keys/right-shift-key.png'   />*/}
                </div>

                
            </div>


            { <div className="lesson-main-stats-section" style={helpText ? { visibility: "hidden"} : { }}>
                    <StatsMainBarV />
                </div>}
            {/*keyboard start */}
            <div style={helpText ? { visibility: "hidden"} : { }}>
                {<Keyboard KeyboardFrom="main" ></Keyboard>}
            </div>

            <div className="quote-result-card">
                
                <div className="result-card-box .nav-bg6">
                    <div style={{border:"1px solid #b44441", padding:"0 0 5px", borderRadius: "5px" }}>
                        <h3 style={{background:"#CD5C5C", lineHeight:"30px"}}>Hmmm!! <span id="accuracy_card" className="accuracy-card">  </span></h3>
                 
                        <span className="material-symbols-outlined" style={{color:"#CD5C5C",fontSize:"3rem", float:"left", padding: "10px"}}>mood_bad &nbsp; </span>
                     
                        <span className="material-symbols-outlined" style={{color:"#CD5C5C",fontSize:"3rem", float:"right", padding: "10px"}}> &nbsp; mood_bad</span>

                        <p id="try_again" className="overlay-hide">
                            <a href="/lessons"  className="button nav-bg1">Repeat</a> 
                        </p>
                        <p style={{borderBottom: "1px dashed"}}>90% accuracy is recommaded. </p>
                        <div className="settings">
                            {<SettingsAccuarcy />}
                            {<SettingsSpeed />}
                        </div>
                        <br></br>
                    
                        <p id="try_again_no" className="overlay-hide">
                            { sessionStorage.getItem("ba-20") && sessionStorage.getItem("be-20") && sessionStorage.getItem("in-20") &&
                                <a href="/lessons/advance/1"  className="button nav-bg1"> Try Again (Advance)</a>  
                            }
                            { sessionStorage.getItem("ba-20") && sessionStorage.getItem("be-20") &&
                                <a href="/lessons/intermediate/1"  className="button nav-bg1"> Try Again (Intermediate)</a>  
                            }
                            { sessionStorage.getItem("ba-20") && 
                                <a href="/lessons/beginner/1"  className="button nav-bg1"> Try Again (Beginner)</a>  
                            }
                            <a href="/lessons" className="button nav-bg3"> Lesson's Home</a>

                        </p>    
                    </div>                
                </div>
            </div>
                
            
            
         </div>
    )
}

export default TypingLesson;