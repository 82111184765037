import React from "react";
import './quotes.css'
import { NavLink } from "react-router-dom";
const Quotes = () => {
    //bad solution for clear counter if someone moves pages fron test page without completing
    for(let i=0; i<100; i++) { window.clearInterval(i); }
    
    //console.log('in tests.js',key)
    return (
        <div className="quotes-row">
            <div className="quotes-column">
                <div className="quotes-card quotes-b11">
                    <NavLink to="random">
                        <p>
                            <span className="material-symbols-outlined quote-left q-white">format_quote</span>
                                ...
                            <span className="material-symbols-outlined quote-right q-white">format_quote</span>
                        </p>
                        <h3>Click Here To Start Typing Random Quotes</h3>
                        <p>1500k+ quotes / 100+ Authors</p>
                        
                    </NavLink>
                </div>
            </div>

            <div className="quotes-column">
                <div className="quotes-card quotes-b21">
                    <NavLink to="bible-random">
                        <p>
                            <span className="material-symbols-outlined quote-left q-white">format_quote</span>
                                ...
                            <span className="material-symbols-outlined quote-right q-white">format_quote</span>
                        </p>
                        <h3>Click Here To Start Typing Random Bible Verses</h3>
                        <p>31102 verses / 40 Authors</p>
                        
                    </NavLink>
                </div>
            </div>

            <div className="quotes-column">
                <div className="quotes-card quotes-b22">
                    <NavLink to="bible-love">
                        <p><span className="material-symbols-outlined quote-left q-white">format_quote</span>&nbsp;
                        <span className="material-symbols-outlined">auto_stories</span>&nbsp;
                        <span className="material-symbols-outlined quote-right q-white">format_quote</span></p>
                        <h3>Click Here To Start Typing Bible Verses</h3>
                        <p>on Love</p>
                    </NavLink>
                </div>
            </div>

            <div className="quotes-column">
                <div className="quotes-card quotes-b23">
                    <NavLink to="bible-faith">
                    <p><span className="material-symbols-outlined quote-left q-white">format_quote</span>&nbsp;
                        <span className="material-symbols-outlined">auto_stories</span>&nbsp;
                        <span className="material-symbols-outlined quote-right q-white">format_quote</span></p>
                        <h3>Click Here To Start Typing Bible Verses</h3>
                        <p>on Faith</p>
                    </NavLink>
                </div>
            </div>

            <div className="quotes-column">
                <div className="quotes-card quotes-b24">
                    <NavLink to="bible-wisdom">
                    <p><span className="material-symbols-outlined quote-left q-white">format_quote</span>&nbsp;
                        <span className="material-symbols-outlined">auto_stories</span>&nbsp;
                        <span className="material-symbols-outlined quote-right q-white">format_quote</span></p>
                        <h3>Click Here To Start Typing Bible Verses</h3>
                        <p>on Wisdom</p>
                    </NavLink>
                </div>
            </div>
            </div>
    )
}
 
export default Quotes;