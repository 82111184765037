export const testNames = [
	{
        "id" : 1,
		"time": "Pangrams (1 Min Test)",
		"text" : "A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog. A quick brown fox jumps over the little lazy dog.",
		"title" : "Pangrams - A quick brown fox jumps over the little lazy dog."
    },
	{
        "id" : 2,
		"time": "John 3:16 (1 Min Test)",
		"text" : "For God so loved the world, that he gave his only begotten Son, that whosoever believeth on him should not perish, but have eternal life. For God sent not the Son into the world to judge the world; but that the world should be saved through him. 18 He that believeth on him is not judged: he that believeth not hath been judged already, because he hath not believed on the name of the only begotten Son of God. 19 And this is the judgment, that the light is come into the world, and men loved the darkness rather than the light; for their works were evil. For every one that doeth evil hateth the light, and cometh not to the light, lest his works should be reproved. But he that doeth the truth cometh to the light, that his works may be made manifest, that they have been wrought in God.",
		"title" : "John 3:16 - For God so loved the world, that he gave his only begotten Son"
    },
	{
        "id" : 3,
		"time": "Psalm 23 (1 Min Test)",
		"text" : "The Lord is my shepherd; I shall not want. He maketh me to lie down in green pastures: he leadeth me beside the still waters. He restoreth my soul: he leadeth me in the paths of righteousness for his name's sake. Yea, though I walk through the valley of the shadow of death, I will fear no evil: for thou art with me; thy rod and thy staff they comfort me. Thou preparest a table before me in the presence of mine enemies: thou anointest my head with oil; my cup runneth over. Surely goodness and mercy shall follow me all the days of my life: and I will dwell in the house of the Lord for ever. The Lord is my shepherd; I shall not want. He maketh me to lie down in green pastures: he leadeth me beside the still waters. He restoreth my soul: he leadeth me in the paths of righteousness for his name's sake. Yea, though I walk through the valley of the shadow of death, I will fear no evil: for thou art with me; thy rod and thy staff they comfort me. Thou preparest a table before me in the presence of mine enemies: thou anointest my head with oil; my cup runneth over. Surely goodness and mercy shall follow me all the days of my life: and I will dwell in the house of the Lord for ever.",
		"title" : "Psalm 23 - The Lord is my shepherd; I shall not want. He maketh me to lie down in green pastures:"
    },
	{
        "id" : 4,
		"time": "Genesis 1 (1 Min Test)",
		"text" : "In the beginning God created the heaven and the earth. And the earth was without form, and void; and darkness was upon the face of the deep. And the Spirit of God moved upon the face of the waters. And God said, Let there be light: and there was light. And God saw the light, that it was good: and God divided the light from the darkness. And God called the light Day, and the darkness he called Night. And the evening and the morning were the first day. And God said, Let there be a firmament in the midst of the waters, and let it divide the waters from the waters. And God made the firmament, and divided the waters which were under the firmament from the waters which were above the firmament: and it was so. And God called the firmament Heaven. And the evening and the morning were the second day. And God said, Let the waters under the heaven be gathered together unto one place, and let the dry land appear: and it was so. And God called the dry land Earth; and the gathering together of the waters called he Seas: and God saw that it was good. And God said, Let the earth bring forth grass, the herb yielding seed, and the fruit tree yielding fruit after his kind, whose seed is in itself, upon the earth: and it was so.",
		"title" : "Genesis 1 - In the beginning God created the heaven and the earth."
    },
	{
        "id" : 5,
		"time": "Ten commandments (1 Min Test)",
		"text" : "And God spake all these words, saying, I am the Lord thy God, which have brought thee out of the land of Egypt, out of the house of bondage. Thou shalt have no other gods before me. Thou shalt not make unto thee any graven image, or any likeness of any thing that is in heaven above, or that is in the earth beneath, or that is in the water under the earth. Thou shalt not bow down thyself to them, nor serve them: for I the Lord thy God am a jealous God, visiting the iniquity of the fathers upon the children unto the third and fourth generation of them that hate me; And shewing mercy unto thousands of them that love me, and keep my commandments. Thou shalt not take the name of the Lord thy God in vain; for the Lord will not hold him guiltless that taketh his name in vain. Remember the sabbath day, to keep it holy. Six days shalt thou labour, and do all thy work: But the seventh day is the sabbath of the Lord thy God: in it thou shalt not do any work, thou, nor thy son, nor thy daughter, thy manservant, nor thy maidservant, nor thy cattle, nor thy stranger that is within thy gates: For in six days the Lord made heaven and earth, the sea, and all that in them is, and rested the seventh day: wherefore the Lord blessed the sabbath day, and hallowed it. Honour thy father and thy mother: that thy days may be long upon the land which the Lord thy God giveth thee. Thou shalt not kill. Thou shalt not commit adultery. Thou shalt not steal. Thou shalt not bear false witness against thy neighbour. Thou shalt not covet thy neighbour's house, thou shalt not covet thy neighbour's wife, nor his manservant, nor his maidservant, nor his ox, nor his ass, nor any thing that is thy neighbour's.",
		"title" : "Exodus 20 - Ten commandments - And God spake all these words, saying, I am the Lord thy God"
    },
	{
        "id" : 6,
		"time": "Bible Stats (1 Min Test)",
		"text" : "The Bible was written by around 40 different authors from various walks of life, including kings, shepherds, and fishermen. The Bible was written over a period of approximately 1,500 years. The Gutenberg Bible, printed in the 1450s, was the first major book printed using mass-produced movable metal type in Europe. The word 'Bible' comes from the Latin word 'biblia,' which means \"books.\", The shortest verse in the Bible is John 11:35, which states, \"Jesus wept.\", The longest verse in the Bible is Esther 8:9. Psalm 119 is the longest chapter in the Bible with 176 verses. The phrase “do not be afraid” appears 365 times in the Bible. The Bible was written over the course of more than 1,000 years - between 1200 BC and 100 AD. The Bible has been translated into 700 languages completely, with at least one book of the Bible being translated into over 3,500 languages. There are 31,102 verses in the King James Bible.",
		"title" : "Bible Stats - And God spake all these words, saying, I am the Lord thy God"
    }
]