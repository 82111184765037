import React, {useState, useEffect} from "react";
//import { useAuth } from "./Auth";
import { useAuth } from "../components/Auth";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

const Login = () => {
    const [emailLogin, setEmailLogin] = useState("");
    const [passwordLogin, setPasswordLogin] = useState("");
    const [error, setError] = useState(null);
    const auth = useAuth();
    const location = useLocation()
    const crumbs = location.pathname.split('/')
    console.log("bread", crumbs[2], crumbs[3])

    const navigate = useNavigate();
    //if(auth.user)
    
    const loginSignupTab =(evt, tab) => {
        console.log("tab",tab);
        let i, tabcontent, tablinks;
        tabcontent = document.querySelectorAll(".login-tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.querySelectorAll(".nav-tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.querySelector("#"+tab).style.display = "block";
        evt.currentTarget.className += " active";
    }
    const handleLogin = async (e) => { 
        e.preventDefault();
        
        const user = {email: emailLogin,password: passwordLogin}
        //console.log(user)
        const response = await fetch('/api/users/login', {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        //console.log(user)
        //console.log(response)
        
        const json = await response.json();
        console.log(json)
        if(!response.ok){
            setError(json.error)
        }
        if(response.ok){
            setError(null)
            sessionStorage.setItem('token', json.token);
            sessionStorage.setItem('email', json.email);
            sessionStorage.setItem('_id', json._id);
            const bible_stats = json.bible_stats;
            const lesson_basic = json.lesson_basic;
            const lesson_beginner = json.lesson_beginner;
            const lesson_intermediate = json.lesson_intermediate;
            const lesson_advance = json.lesson_advance;
            const error_keys = json.error_keys;
            console.log("stats", bible_stats, lesson_basic)
            if(bible_stats){
                for (let x in bible_stats) {
                    //console.log("log",bible_stats[x].book)
                    if(bible_stats[x].bookId){
                        sessionStorage.setItem("book-verse-"+bible_stats[x].bookId, bible_stats[x].verse);
                    }
                    
                }
            }
            if(error_keys){
                let result = [...error_keys.toString()].reduce((a, e) => { a[e] = a[e] ? a[e] + 1 : 1; return a }, {}); 
                sessionStorage.setItem("error_keys", JSON.stringify(result))
            }
            if(lesson_basic){
                for (let x in lesson_basic) {
                    if(lesson_basic[x].code){
                        sessionStorage.setItem(lesson_basic[x].code, JSON.stringify(lesson_basic[x]));
                    }
                    
                }
            }
            if(lesson_beginner){
                for (let x in lesson_beginner) {
                    if(lesson_beginner[x].code){
                        sessionStorage.setItem(lesson_beginner[x].code, JSON.stringify(lesson_beginner[x]));
                    }
                    
                }
            }
            if(lesson_intermediate){
                for (let x in lesson_intermediate) {
                    if(lesson_intermediate[x].code){
                        sessionStorage.setItem(lesson_intermediate[x].code, JSON.stringify(lesson_intermediate[x]));
                    }
                    
                }
            }
            if(lesson_advance){
                for (let x in lesson_advance) {
                    if(lesson_advance[x].code){
                        sessionStorage.setItem(lesson_advance[x].code, JSON.stringify(lesson_advance[x]));
                    }
                    
                }
            }
            
            auth.login(json.email)
            navigate("/profile")
            console.log("auth", auth, "uswr", user);
            //setIsLoggedIn(true)
        }

    }
    
    const [emailSignup, setEmailSignup] = useState("");
    const [passwordSignup, setPasswordSignup] = useState("");
    const [rePasswordSignup, setRePasswordSignup] = useState("");
    //const [error, setError] = useState(null);

    const handleSignUp = async (e) => { 
        e.preventDefault();
        if(passwordSignup !== rePasswordSignup){
            setError('password don\'t match')
            return;
        } else {
            setError(null)
        }
        const user = {email: emailSignup,password: passwordSignup}
        const response = await fetch('/api/users/signup', {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        const json = await response.json();
        if(!response.ok){
            setError(json.error)
        }
        if(response.ok){
            setError(null)
            sessionStorage.setItem('token', json.token);
            sessionStorage.setItem('email', json.email);
            auth.login(json.email)
            navigate("/profile")
        }

    }

    const handleFB = async() => {
        console.log('FB login')
        
    }

    useEffect(() => {

        if(crumbs[2] == 'login'){
            document.querySelector("#LoginOpen").click();
        }
        if(crumbs[2] == 'signup'){
            document.querySelector("#SignupOpen").click();
        }
        if(crumbs[3] == 'se'){
            setError("Session Expired, Please login!")
        }
        
        
    },[crumbs[2]])

    //document.querySelector
    

    return (


        <div id="loginSignup">
            


        

                        <div className="nav-tab nav-tab-login">
                            <button className="nav-tablinks" onClick={(e)=>loginSignupTab(e, 'Login')} id="LoginOpen">
                                <span className="material-symbols-outlined nav-li">login</span> Login
                            </button>
                            <button className="nav-tablinks" onClick={(e)=>loginSignupTab(e, 'Signup')} id="SignupOpen">
                                <span className="material-symbols-outlined">app_registration</span> Sign Up
                            </button>
                        </div>
     
                        <div id="Login" className="login-tabcontent">
                            
                            <form  method="post" onSubmit={handleLogin}>
                                <div className="container">
                                <GoogleLogin
                                    onSuccess={credentialResponse => {
                                        console.log(credentialResponse);
                                        const decoded = jwt_decode(credentialResponse.credential);
                                        console.log("G jwt decoded", decoded, decoded.email, decoded.name, decoded.picture, decoded.family_name, decoded.given_name);
                                        //decoded.email, decoded.name, decoded.picture, decoded.family_name, decoded.given_name
                                        const saveGUser = async() => {
                                            const user = {email: decoded.email,first_name:decoded.given_name, last_name:decoded.family_name, picture:decoded.picture, type:"google"}
                                            const response = await fetch('/api/users/glogin', {
                                                method: 'POST',
                                                body: JSON.stringify(user),
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Accept': 'application/json'
                                                }
                                            })
                                            console.log(console.log(user))
                                            console.log(response)
                                            
                                            const json = await response.json();
                                            console.log(json)
                                            if(!response.ok){
                                                setError(json.error)
                                            }
                                            if(response.ok){
                                                setError(null)
                                                sessionStorage.setItem('token', json.token);
                                                sessionStorage.setItem('email', json.email);
                                                sessionStorage.setItem('_id', json._id);
                                                auth.login(json.email)
                                                navigate("/profile")
                                                return
                                            }
                                        }
                                        saveGUser()
                                        
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                    />
                                    {/*<br></br>
                                    <div id="status"></div>
                                    <button type="button" className="btn nav-bg1" onClick={handleFB}>Login with Facebook</button>
                                    <fb:login-button scope="public_profile,email" onlogin="checkLoginState();">
                                    </fb:login-button>*/}
                                    {/*<div className="fb-login-button" data-width="300" data-size="" data-button-type="" data-layout="" data-auto-logout-link="true" data-use-continue-as="true"></div>*/}
                                    <br></br>
                                    
                                {error && <p className="red error">{error }</p>}
                                        <label htmlFor="uname">Email</label>
                                        <input type="email" placeholder="Enter Email"  value={emailLogin} required 
                                        onChange={(e) => setEmailLogin(e.target.value)} />

                                        <label htmlFor="psw">Password</label>
                                        <input type="password" placeholder="Enter Password" value={passwordLogin} required 
                                        onChange={(e) => setPasswordLogin(e.target.value)} />



                                    <label>
                                    <input type="checkbox" checked="checked" name="remember" readOnly /> Remember me
                                    </label>
                                </div>

                                <div className="container">
                                    <button type="submit" className="btn nav-bg5">Login</button>
                                    <span className="psw">Forgot <a href="#">password?</a></span>
                                </div>
                            </form>
                        </div>

                        <div id="Signup" className="login-tabcontent">
                            <form  name="Signup" onSubmit={handleSignUp} method="post">
                                    <div className="container">
                                        <p>Please fill in this form to create an account.</p>
                                        {error && <p className="red error">{error }</p>}
                                        <label htmlFor="uname">Email</label>
                                        <input type="email" placeholder="Enter Email"  value={emailSignup} required 
                                        onChange={(e) => setEmailSignup(e.target.value)} />

                                        <label htmlFor="psw">Password</label>
                                        <input type="password" placeholder="Enter Password" value={passwordSignup} required 
                                        onChange={(e) => setPasswordSignup(e.target.value)} />

                                        <label htmlFor="psw">Repeat Password</label>
                                        <input type="password" placeholder="Repeat Password" value={rePasswordSignup} required 
                                        onChange={(e) => setRePasswordSignup(e.target.value)}  />
                                        
                                        <label>
                                        <input type="checkbox" checked="checked" name="remember" readOnly /> Remember me
                                        </label>
                                    </div>

                                    <div className="container">
                                        <button type="submit" className="btn nav-bg5">Sign Up</button>
                                    </div>
                                </form>
                            </div>


                    

                    

                    

                </div>



    )
  }

  export default Login