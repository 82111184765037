import Contact from "../components/Contact"
export default function NotFound() {
    return (
      <div className="page">
        <h2 className="page-title">Page Not Found!</h2>
        <div className="page-text">
          <table>
            <tbody>
              <tr>
                <td>
                  <img className="img-404" src="../../404.webp" alt=""></img>
                </td>
                <td>
                  <h3 className="red">Opps! We could not find the page!!</h3>
                  <div className="nav-tests-card nav-bg5"><a className="" href="/"><h2>Home</h2><div>Typing is meaningful with TYPLOGOS.com</div></a></div>
                  <div className="nav-tests-card nav-bg2"><a className="" href="/tests/3"><h2>Click Here to One Minute Test</h2><div>Test Your Speed</div></a></div>
                  <div className="nav-tests-card nav-bg3"><a className="" href="/quotes/random"><h2>Random Quotes</h2><div>15000+ Quotes / 100+ Author</div></a></div>
                </td>
              </tr>
              
            </tbody>
          </table>
          <Contact></Contact>
 
        </div>
     </div>
    )
  }