import React, {useState, useEffect} from 'react';
import {
  createBrowserRouter, 
  createRoutesFromElements,
  Route, 
  RouterProvider, Navigate
} from 'react-router-dom'
import './App.css';
import './mobile.css';

import Main from './main/main';
import Books from './main/books';
//import Footer from './layouts/footer'
import Lessons from './lessons/lessons';
import TypingLesson from './lessons/typingLesson';
import TypingLesson2 from './lessons/typingLesson2'; // create css keyboard. remove after

import Tests from './tests/tests';
import TypingTest from './tests/typingTest';

import Profile from './profile/profile';
import Login from './pages/login';
import Users from './adm/Users'


import Games from './games/games'

import Quotes from './quotes/quotes';
import TypingQuote from './quotes/typingQuotes'
// pages
import Terms from './pages/terms'
import About from './pages/about'
import Privacy from './pages/privacy'
import Faq from './pages/faq'
import NotFound from './pages/notfound'

import Blog from './blog/Blog';


// layouts
import HomeLayout from './layouts/HomeLayout'
import { AuthProvider } from './components/Auth';

//import PageLayout from './layouts/PageLayout'

import { useAuth } from './components/Auth';





function App() {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.classList.add('loader-hide')
        document.querySelector("#loader_txt").classList.add('loader-hide')
        //setLoading(!isLoading);
      }
  }, [])
  //let key = 0;
  const auth = useAuth()
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route  path="/" element={<HomeLayout />}>
          <Route index element={<Main />} />
          <Route path="/:book" element={<Main />} />
          <Route path="/books" element={<Books />} />
          <Route path="/tests" element={<Tests />} />
          <Route path="/tests/:time" element={<TypingTest />} />
          <Route path="/quotes" element={<Quotes />} />
          <Route path="/quotes/:type" element={<TypingQuote />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/lessons/:lesson" element={<TypingLesson />} />
          <Route path="/lessons/:lesson/:id" element={<TypingLesson />} />
          <Route path="/clessons/:lesson/:id" element={<TypingLesson2 />} />
          <Route path="/profile" element={ auth.user ?  <Profile /> : <Navigate to={"/page/login"} /> } />
          <Route path="/page/login" element={ auth.user ?  <Profile /> : <Login /> }  />
          <Route path="/page/login/:error" element={ auth.user ?  <Profile /> : <Login /> }  />
          <Route path="/page/signup" element={ auth.user ?  <Profile /> : <Navigate to={"/page/login"} /> }  />
          <Route path="/games" element={<Games />} />
          <Route path="/users" element={<Users />} />
          <Route path="/page/terms" element={<Terms />} />
          <Route path="/page/about" element={<About />} />
          <Route path="/page/privacy" element={<Privacy />} />
          <Route path="/page/faq" element={<Faq />} />
          <Route path="/blog/:blog" element={<Blog />} />
  
          <Route path="*" element={<NotFound />} />
      </Route>
  
      
    )
  )
  return (
      <>
        <RouterProvider router={router} />
      </>
  );
}
/*
function App() {
  return (
    <div className="">
      <header className="">
        <Nav></Nav>
        <div className='blue'></div>
      </header>
      <main>
        <Main></Main>
      </main>
      <footer>
        <Footer></Footer>
      </footer>
    </div>
  );
}
*/
export default App;
