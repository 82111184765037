const basicLessons = [
	{
		"id" : 1,
		"text" : "aaaaaaaaaa",
		"title" : "Key a - left hand tiny finger",
		"code" : "ba-1",
		"help" : "<div>Use your left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">a</span></h4>",
		"img": "/keys/a-key.png"
	},{
		"id" : 2,
		"text" : "ssssssssss",
		"title" : "Key s - left hand ring finger",
		"code" : "ba-2",
		"help" : "<div>Use your left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">s</span></h4>",
		"img": "/keys/s-key.png"
	},{
		"id" : 3,
		"text" : "dddddddddd",
		"title" : "Key d - left hand middle finger",
		"code" : "ba-3",
		"help" : "<div>Use your left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">d</span></h4>",
		"img": "/keys/d-key.png"
	},{
		"id" : 4,
		"text" : "ffffffffff",
		"title" : "Key f - left hand index finger",
		"code" : "ba-4",
		"help" : "<div>Use your left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">f</span></h4>",
		"img": "/keys/f-key.png"
	},{
		"id" : 5,
		"text" : "a s d f a s d f",
		"title" : "Key Space Bar - left or right thumb",
		"code" : "ba-5",
		"help" : "<div>Use your left or right thumb to</div> <h4>type the key <span class=\"material-symbols-outlined h-key letter is-active\">space_bar</span></h4>",
		"img": "/keys/space-key.png"
	},{
		"id" : 6,
		"text" : "aa as ad af aa as ad af sa da fa sa da fa",
		"title" : "Practice Keys - a, s, d & f",
		"code" : "ba-6"
	},{
		"id" : 7,
		"text" : "ass add aff sad dad fad ass add aff sad dad fad added add sad",
		"title" : "Practice Keys - a, s, d & f",
		"code" : "ba-7"
	},{
		"id" : 8,
		"text" : "eeeeeeeeee",
		"title" : "Key e - left hand middle finger",
		"code" : "ba-8",
		"help" : "<div>Use your left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">e</span></h4>",
		"img": "/keys/e-key.png"
	},{
		"id" : 9,
		"text" : "rrrrrrrrrr",
		"title" : "Key r - left hand index finger",
		"code" : "ba-9",
		"help" : "<div>Use your left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">r</span></h4>",
		"img": "/keys/r-key.png"
	},{
		"id" : 10,
		"text" : "see dee fee ree err add era ear eat red fed feed ree def ref red",
		"title" : "Practice Keys - e & r",
		"code" : "ba-10"
	},{
		"id" : 11,
		"text" : "ease dead fade feed reed dear fear sear read fard sard dead rase sare sear",
		"title" : "Practice Keys - e & r",
		"code" : "ba-11"
	},{
		"id" : 12,
		"text" : "jjjjjjjjjj",
		"title" : "Key j - right hand index finger",
		"code" : "ba-12",
		"help" : "<div>Use your right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">j</span></h4>",
		"img": "/keys/j-key.png"
	},{
		"id" : 13,
		"text" : "kkkkkkkkkk",
		"title" : "Key k - right hand middle finger",
		"code" : "ba-13",
		"help" : "<div>Use your right hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">k</span></h4>",
		"img": "/keys/k-key.png"
	},{
		"id" : 14,
		"text" : "llllllllll",
		"title" : "Key l - right hand ring finger",
		"code" : "ba-13",
		"help" : "<div>Use your right hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">l</span></h4>",
		"img": "/keys/l-key.png"
	},{
		"id" : 15,
		"text" : "ja ka la je ke le jj kk ll ja ka la je ke la jj kk ll",
		"title" : "Practice Keys - j, k & l",
		"code" : "ba-15"
	},{
		"id" : 16,
		"text" : "jake lake fake rake lead jeal feal keel leek kale fead sake sale raja jarr karl",
		"title" : "Practice Keys - j, k & l",
		"code" : "ba-16"
	},{
		"id" : 17,
		"text" : "iiiiiiiiii",
		"title" : "Key i - right hand middle finger",
		"code" : "ba-17",
		"help" : "<div>Use your right hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">i</span></h4>",
		"img": "/keys/i-key.png"
	},{
		"id" : 18,
		"text" : "uuuuuuuuuu",
		"title" : "Key u - right hand index finger",
		"code" : "ba-18",
		"help" : "<div>Use your right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">u</span></h4>",
		"img": "/keys/u-key.png"
	},{
		"id" : 19,
		"text" : "ai si di fi hi ji ki li ri ui au su du fu hu ju ku lu",
		"title" : "Practice Key u & i",
		"code" : "ba-19"
	},{
		"id" : 20,
		"text" : "lull dull jill kill fill full diff riff ruff rudd surd furl sire lire jure lure furr sure aura",
		"title" : "Practice Key u & i",
		"code" : "ba-20"
	},{
		"id" : 21,
		"text" : "oooooooooo",
		"title" : "Key o - right hand ring finger",
		"code" : "ba-21",
		"help" : "<div>Use your right hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">o</span></h4>",
		"img": "/keys/o-key.png"
	},{
		"id" : 22,
		"text" : "pppppppppp",
		"title" : "Key p - right hand tiny finger",
		"code" : "ba-22",
		"help" : "<div>Use your right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">p</span></h4>",
		"img": "/keys/p-key.png"
	},{
		"id" : 23,
		"text" : "oo so do fo jo ko lo po ro pa pe pi pu op po oi ou ao oe",
		"title" : "Practice Keys - o & p",
		"code" : "ba-23"
	},{
		"id" : 24,
		"text" : "pop sap lap lip kip kap jap rap par per pid pat pas for sor lod lof lop rop rod ros",
		"title" : "Practice Keys - o & p",
		"code" : "ba-24"
	},{
		"id" : 25,
		"text" : "qqqqqqqqqq",
		"title" : "Key q - left hand tiny finger",
		"code" : "ba-25",
		"help" : "<div>Use your left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">q</span></h4>",
		"img": "/keys/q-key.png"
	},{
		"id" : 26,
		"text" : "wwwwwwwwww",
		"title" : "Key w - left hand ring finger",
		"code" : "ba-26",
		"help" : "<div>Use your left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">w</span></h4>",
		"img": "/keys/w-key.png"
	},{
		"id" : 27,
		"text" : "qa qe qi qo qu wa we wi wo wu eq aq aq eq ew aw iw ow wa wq",
		"title" : "Practice Keys - q & w",
		"code" : "ba-27"
	},{
		"id" : 28,
		"text" : "qua queer quid quad quel awe owl wipe wear was saw raw dew few law pew drew flaw jaw pow",
		"title" : "Practice Keys - q & w",
		"code" : "ba-28"
	},{
		"id" : 29,
		"text" : "gggggggggg",
		"title" : "Key g - left hand index finger",
		"code" : "ba-29",
		"help" : "<div>Use your left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">g</span></h4>",
		"img": "/keys/g-key.png"
	},{
		"id" : 30,
		"text" : "tttttttttt",
		"title" : "Key t - left hand index finger",
		"code" : "ba-30",
		"help" : "<div>Use your left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">t</span></h4>",
		"img": "/keys/t-key.png"
	},{
		"id" : 31,
		"text" : "ta ti to tu te ga gi go gu ge gg tt gt ta ti to tu te ga gi go gu ge gg tt gt",
		"title" : "Practice Keys - t & g",
		"code" : "ba-31"
	},{
		"id" : 32,
		"text" : "tar tarp tare toad till trap toil tear turf grap grapes surge gear gate gay guy ghi group",
		"title" : "Practice Keys - t & g",
		"code" : "ba-32"
	},{
		"id" : 33,
		"text" : "hhhhhhhhhh",
		"title" : "Key h - right hand index finger",
		"code" : "ba-33",
		"help" : "<div>Use your right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">h</span></h4>",
		"img": "/keys/h-key.png"
	},{
		"id" : 34,
		"text" : "yyyyyyyyyy",
		"title" : "Key y - right hand index finger",
		"code" : "ba-34",
		"help" : "<div>Use your right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">y</span></h4>",
		"img": "/keys/y-key.png"
	},{
		"id" : 35,
		"text" : "ha hi he ho hu ya ye yo yu yi hy yh ha hi he ho hu ya ye yo yu yi hy yh",
		"title" : "Practice Keys - h & y",
		"code" : "ba-35"
	},{
		"id" : 36,
		"text" : "what where how whey yeah share shear shut shout kyak dry fry try hay hey jay kay",
		"title" : "Practice Keys - h & y",
		"code" : "ba-36"
	},{
		"id" : 37,
		"text" : "nnnnnnnnnn",
		"title" : "Key n - right hand index finger",
		"code" : "ba-37",
		"help" : "<div>Use your right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">n</span></h4>",
		"img": "/keys/n-key.png"
	},{
		"id" : 38,
		"text" : "mmmmmmmmmm",
		"title" : "Key m - right hand index finger",
		"code" : "ba-38",
		"help" : "<div>Use your right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">m</span></h4>",
		"img": "/keys/m-key.png"
	},{
		"id" : 39,
		"text" : "na ne ni no nu nn nm mm mn na ne ni no nu nn nm mm mn",
		"title" : "Practice Keys - n & m",
		"code" : "ba-39"
	},{
		"id" : 40,
		"text" : "name nail mail maps snap small smoke snake snail ramp null mull mill nay may make",
		"title" : "Practice Keys - n & m",
		"code" : "ba-40"
	},{
		"id" : 41,
		"text" : "vvvvvvvvvv",
		"title" : "Key v - left hand index finger",
		"code" : "ba-41",
		"help" : "<div>Use your left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">v</span></h4>",
		"img": "/keys/v-key.png"
	},{
		"id" : 42,
		"text" : "bbbbbbbbbb",
		"title" : "Key b - left hand index finger",
		"code" : "ba-42",
		"help" : "<div>Use your left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">b</span></h4>",
		"img": "/keys/b-key.png"
	},{
		"id" : 43,
		"text" : "va ve vi vo vu vb bv ba be bi bu bo by va ve vi vo vu vb bv ba be bi bu bo vy",
		"title" : "Practice Keys - v & b",
		"code" : "ba-43"
	},{
		"id" : 44,
		"text" : "wave weaver river rave view vent vamp brake burp bake bend beaver brand vows bower buying",
		"title" : "Practice Keys - v & b",
		"code" : "ba-44"
	},{
		"id" : 45,
		"text" : "cccccccccc",
		"title" : "Key c - left hand middle finger",
		"code" : "ba-45",
		"help" : "<div>Use your left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">c</span></h4>",
		"img": "/keys/c-key.png"
	},{
		"id" : 46,
		"text" : "xxxxxxxxxx",
		"title" : "Key x - left hand ring finger",
		"code" : "ba-46",
		"help" : "<div>Use your left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">x</span></h4>",
		"img": "/keys/x-key.png"
	},{
		"id" : 47,
		"text" : "ca co chi cha che xu xi ex exo pax dex hex wax crux lux chair chap",
		"title" : "Practice Keys - c & x",
		"code" : "ba-47"
	},{
		"id" : 48,
		"text" : "express expo axel exile corex hexa deca cake crack crux crate chars xerxes max lax maximum",
		"title" : "Practice Keys - c & x",
		"code" : "ba-48"
	},{
		"id" : 49,
		"text" : "zzzzzzzzzz",
		"title" : "Key z - left hand tiny finger",
		"code" : "ba-49",
		"help" : "<div>Use your left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">z</span></h4>",
		"img": "/keys/z-key.png"
	},{
		"id" : 50,
		"text" : "za zoo jazz zack wizz lizard wizard fizz razor lazor zig zig zap zoo zinc jazz zing",
		"title" : "Practice Keys - z",
		"code" : "ba-50"
	},{
		"id" : 51,
		"text" : "th. ed. ly. ism. a.i.d u.s.a ment. one, two and three. here, there and everywhere.",
		"title" : "Practice Keys , & .",
		"code" : "ba-51"
	},{
		"id" : 52,
		"text" : "one. two, three. four, five. six, seven. eight, nine. ten.",
		"title" : "Practice Keys , & .",
		"code" : "ba-53"
	},{
		"id" : 53,
		"text" : "colors of rainbow, red, orange, yellow, green, blue, indigo and violet.",
		"title" : "Practice Keys , & .",
		"code" : "ba-53"
	},{
		"id" : 54,
		"text" : "sunday, monday, tuesday, wednesday, thursday, friday and saturday.",
		"title" : "Practice Keys , & .",
		"code" : "ba-54"
	},{
		"id" : 55,
		"text" : "january, february, march, april, may, june, july, august, september, october, november, december.",
		"title" : "Practice Keys , & .",
		"code" : "ba-55"
	},{
		"id" : 56,
		"text" : "banana, apple, kiwi, strawberry, avocado, pineapple, watermelon, mango, orange and berry",
		"title" : "Practice Keys , & .",
		"code" : "ba-56"
	},{
		"id" : 57,
		"text" : "question ??? ?? ? who? where? how? what? when?",
		"title" : "Key ? - right hand tiny finger",
		"code" : "ba-57",
		"help" : "<div>Use your right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">?</span></h4>",
		"img": "/keys/cquestion-key.png"
	},{
		"id" : 58,
		"text" : "semi colon ;;; ;; ; love suffereth long; and is kind; envieth not; vaunteth not itself, not puffed up;",
		"title" : "Key ; - right hand tiny finger",
		"code" : "ba-58",
		"help" : "<div>Use your right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">;</span></h4>",
		"img": "/keys/semi-colon-key.png"
	},{
		"id" : 59,
		"text" : "colon ::: :: :: they: them: he: she: we the people: the gospel says:",
		"title" : "Key : - right hand tiny finger",
		"code" : "ba-59",
		"help" : "<div>Use your right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">:</span></h4>",
		"img": "/keys/ccolon-key.png"
	},{
		"id" : 60,
		"text" : "love? never faileth: but prophecies, shall fail; tongues, shall cease; knowledge, shall vanish away.",
		"title" : "Practice Keys ;, ; & ?",
		"code" : "ba-60"
	}
]
const beginnerLessons = [
	{
		"id" : 1,
		"text" : "a quick brown fox jumps over the little lazy dog, a quick brown fox jumps over the little lazy dog, a quick brown fox jumps over the little lazy dog, a quick brown fox jumps over the little lazy dog, a quick brown fox jumps over the little lazy dog.",
		"title" : "All letters (Pangram)",
		"code" : "be-1"
	},{
		"id" : 2,
		"text" : "is am are he his him she her was were has have had can could will would shall should they their them we us you your all each and every no one must may might do did done and does sure ok there here and everywhere",
		"title" : "Most common words - 1",
		"code" : "be-2"
	},{
		"id" : 3,
		"text" : "do did done and does, he has had them to have it, she will have it to be done, for it is in you, so you can do, but it all was not in them, they could do it",
		"title" : "Most common words - 2",
		"code" : "be-3"
	},{
		"id" : 4,
		"text" : "who is this who is she where was he from how much do you have when can they go what may come should not be there every one should be here soon before all who are here see this",
		"title" : "Most common words - 3",
		"code" : "be-4"
	},{
		"id" : 5,
		"text" : "tasked braked caked dared leased flaxed fired juked linked packaged jived maned feed joked kited clicked maced motioned tasked braked caked dared leased flaxed fired juked linked packaged jived maned feed joked kited clicked maced motioned",
		"title" : "Practice ...ed",
		"code" : "be-5"
	},{
		"id" : 6,
		"text" : "dearly amply apply aptly artly lavishly awkly badly baily belly bigly billy aly smugly bully coily curly drilly clearly dearly amply apply aptly artly lavishly awkly badly baily belly bigly billy aly smugly bully coily curly drilly clearly",
		"title" : "Practice ...ly",
		"code" : "be-6"
	},{
		"id" : 7,
		"text" : "ailing airing aiding tailing railing pinning zipping giggling ripping piping landing barking darking popping knocking asking pulping rapping piling tiling riding darling curling burning thanking sharping shelling wailing wanting porking parking banking choking ringing yanking yolking darling churing mourning whopping eating looting",
		"title" : "Practice ...ing",
		"code" : "be-7"
	},{
		"id" : 8,
		"text" : "animism imperialism arabism atheism baptism bahaism deism prism zionism prism marxism conservatism fascisms animism animism arabism atheism baptism bahaism deism prism zionism prism prism communism marxism conservatism fascism hinduism buddism jainism magnetism fanaticism feminism skepticism egoism paganism altruism",
		"title" : "Practice ...ism",
		"code" : "be-8"
	},{
		"id" : 9,
		"text" : "aration bastion caption caution delegation diction duction affection fiction unmention limitation assumption emotion ovation station taction aration bastion caption caution diction duction faction fiction lection mention inition emotion ovation station taction production ammunition starvation absorption projection prevention unlibelisation.",
		"title" : "Practice ...tion",
		"code" : "be-9"
	},{
		"id" : 10,
		"text" : "aceness actless actress address ageless aidless aimless aptness armless artness axeless carless dewless confess dogness aceness actless actress address ageless aidless aimless aptness armless artness axeless carless dewless confess dogness merciless dullness motionless tactless careless gress press pressless address aimless caress.",
		"title" : "Practice ...ess",
		"code" : "be-10"
	},{
		"id" : 11,
		"text" : "abasement allotment amusments arguments decrement condiment elopement goverment freshment excrement exilement detriment abasement allotment amusments arguments decrement condiment elopement goverment freshment excrement exilement detriment abasement allotment amusments arguments decrement condiment elopement allotment amusments arguments decrement.",
		"title" : "Practice ...ment",
		"code" : "be-11"
	},{
		"id" : 12,
		"text" : "trace track tracy trader trailed trains trait tramatrams trans traps trashtrave trawls trays trangtranqs traiters trads trace track tracy trader trailed trains trait tramatrams trans traps trashtrave trawls trays trangtranqs traiters trads.",
		"title" : "Practice tra...",
		"code" : "be-12"
	},{
		"id" : 13,
		"text" : "chars chaat chosen chaser chacker chads chain chago chair chart chalked changer chank chapt chant champions chaos charm chars chaat chosen chaser chacker chads chain chago chair chart chalked changer chank chapt chant champions chaos charm.",
		"title" : "Practice cha...",
		"code" : "be-13"
	},{
		"id" : 14,
		"text" : "reach reacted reads ready reaf speakers realm reals bereaved rears rebox rebits rebel rebut reck redex redux real refer reach reacted reads ready reaf speakers realm reals bereaved rears rebox rebits rebel rebut reck redex redux real refer.",
		"title" : "Practice cha...",
		"code" : "be-14"
	},{
		"id" : 15,
		"text" : "reach reacted reads ready reaf speakers realm reals bereaved rears rebox rebits rebel rebut reck redex redux real refer reach reacted reads ready reaf speakers realm reals bereaved rears rebox rebits rebel rebut reck redex redux real refer",
		"title" : "Practice re...",
		"code" : "be-15"
	},{
		"id" : 16,
		"text" : "disable discern disbars disbark discard discoed disease disgest diserve discord discous dishers disform disowns dislike disable discern disbars disbark discard discoed disease disgest diserve discord discous dishers disform disowns dislike.",
		"title" : "Practice dis...",
		"code" : "be-16"
	},{
		"id" : 17,
		"text" : "a blue jars prevented the mixtures from the freez too quickly, do they know how vexingly quick daft zebras jump around, a blue jars prevented the mixtures from the freez too quickly, do they know how vexingly quick daft zebras jump around.",
		"title" : "All letters (Pangram)",
		"code" : "be-17"
	},{
		"id" : 18,
		"text" : "a jackdaws love my big sphinx of quartz we soon judged antique ivory buckle for next prize that was presented to winner a jackdaws love my big sphinx of quartz we soon judged antique ivory buckle for next prize that was presented to winner.",
		"title" : "All letters (Pangram)",
		"code" : "be-18"
	},{
		"id" : 19,
		"text" : "actable actable actable addable addable addable affable affable affable amiable amiable amiable bakable bakable bakable capable capable capable buyable buyable buyable codable codable codable curable curable curable disable disable disable.",
		"title" : "Practice ...able",
		"code" : "be-19"
	},{
		"id" : 20,
		"text" : "cozy cozy cozy dozer dozer dozers crazy crazy doozy doozy doozy zig jazzy jazzy breezer breezy glitzy queazy cheezy zag cozy cozy cozy dozer dozer dozers crazy crazy doozy doozy doozy zig jazzy jazzy breezer breezy glitzy queazy cheezy zag.",
		"title" : "Practice z",
		"code" : "be-20"
	},{
		"id" : 21,
		"text" : "the lazy zebra is not exactly known for being quieter animals, and a lazy zebra is not exactly known for being quickers animals for zookeepers and again saying that seven strip zebras are not exactly known for being quick animals for zoos.",
		"title" : "All letters (Pangram)",
		"code" : "be-21"
	},{
		"id" : 22,
		"text" : "accept accuse acquire adapt add admire admin admit adopt advise aim allow afford announce agree appear anticipate apply approve argue arise arrange assume ask attach astonish attempt attend avoid awake attract assure arrive approach appreciate appologize adore adjust acknowledge achieve accuse",
		"title" : "Most Common Verbs - A",
		"code" : "be-22"
	},{
		"id" : 23,
		"text" : "bake bathe be bear beat become beg begin believe behave bend belong bet bind bite blow boil bounce blow break bow breed bring build broadcast burn burst buy",
		"title" : "Most Common Verbs - B",
		"code" : "be-23"
	},{
		"id" : 24,
		"text" : "can could care carry calculate catch celebrate change chose chap chop cling commit communicate compare complain complete concern confirm consent consist consult convince cost crawl create count cry cut crticize creep",
		"title" : "Most Common Verbs - C",
		"code" : "be-24"
	},{
		"id" : 25,
		"text" : "dance dare deal decide defer delay deliver deny depend deserve desire describe destroy demand disagree discover discuss dislike distribute dive disagree do doubt drag dream drill drive drop dry",
		"title" : "Most Common Verbs - D",
		"code" : "be-25"
	},{
		"id" : 26,
		"text" : "eat earn enable engage emphasize encourage enhance enjoy entail exist examine enter ensure expand explain explore experiment expect extend",
		"title" : "Most Common Verbs - E",
		"code" : "be-26"
	},{
		"id" : 27,
		"text" : "fail fall feed fight feel find finish follow fold fly fit forbid forget forgive freeze fry fend flee flown flow flop flip fake fan family fax",
		"title" : "Most Common Verbs - F",
		"code" : "be-27"
	},{
		"id" : 28,
		"text" : "gather give grow grasp gate grape greet guide guess go generate glitter groan grab ghi game gale gas glad glam glean grace grave gay gray guy grey gap gland",
		"title" : "Most Common Verbs - G",
		"code" : "be-28"
	},{
		"id" : 29,
		"text" : "hand hand happen hate have hall heal hear hide hesitate hit held hold hop hip hope hug hurt hurry hippy happy handle hag had has half hard heard how hail house hack hang hover hummm hay haze hammack",
		"title" : "Most Common Verbs - H",
		"code" : "be-29"
	},{
		"id" : 30,
		"text" : "ink ire identiy ignore imagine ill illustrate impress include inkpot indicate improve incorporate inform install intend invest iron investigate involve immune illusion ions irate irritate",
		"title" : "Most Common Verbs - I",
		"code" : "be-30"
	},{
		"id" : 31,
		"text" : "jog jag juggle join jump judge justify jake jack jamm joker jail james jaw june jazzy jeal jot jar jerk jolt jay joy joining jag joke",
		"title" : "Most Common Verbs - J",
		"code" : "be-31"
	},{
		"id" : 32,
		"text" : "keep kiss kale kick knit know kay kayak knowledge knap knife kundli",
		"title" : "Most Common Verbs - K",
		"code" : "be-32"
	},{
		"id" : 33,
		"text" : "lack lick lock lay lead leed lip lean learn leave left lend lie lying lamplift light lanky lane like listen look low law lose lost love lame lab lack lazor legal lentil lumber link live life",
		"title" : "Most Common Verbs - L",
		"code" : "be-33"
	},{
		"id" : 34,
		"text" : "make mike mic mane mail matter may might manage measure meet meat map melt mend mention mind mock mull mould miss mow must musk mode model mopping meld move maximum maze meadow mocker",
		"title" : "Most Common Verbs - M",
		"code" : "be-34"
	},{
		"id" : 35,
		"text" : "nag nab naked name nimble neglect navigate nudge nourishnurture notify narrate necessary nagate nill null navel now nay need needle net netizen nail nose nike night nozel",
		"title" : "Most Common Verbs - N",
		"code" : "be-35"
	},{
		"id" : 36,
		"text" : "oops observe obtain okey occur okay open oil own owl operate order organize ought over owner overcome overtake off often old older oyster out outside outer obey ox offer",
		"title" : "Most Common Verbs - O",
		"code" : "be-36"
	},{
		"id" : 37,
		"text" : "pan pant paint pay participate peel pale perform persuade pinch plan plank posses play postpone pout point pour prefer practice prepare pretend prevent promise propose prove prank pull pond puff protect proceed punch pursue push put platter poll picker pocker paddle phase pile pail pub plead plea ponder pow power pack pine pock pluck piggy",
		"title" : "Most Common Verbs - P",
		"code" : "be-37"
	},{
		"id" : 38,
		"text" : "quick quack quid quad quell quake quality quit quite quiet qualify qualification quiter quiz qi quip",
		"title" : "Most Common Verbs - Q",
		"code" : "be-38"
	},{
		"id" : 39,
		"text" : "rake random read realize recall receive reduce recommend refer recollect reflect refuse regret relate relax relieve relay ramp rake rent reave regal rely remain remember remind replace resent represent repair retain retire rif rip ring rise rose risk roast run ray ripping rattle rape rapper rate rout router row raw raid red rage reason region race rave razor rock rambo rack review relocate rum room reel real",
		"title" : "Most Common Verbs - R",
		"code" : "be-39"
	},{
		"id" : 40,
		"text" : "sad satisft sanction scrub see sea seem send sail same sell sale sew saw shall shell show shop shrink shut sing sit slap slay ski slice slide smell speal specify spell spread squat spit stack stand start stir sting stick stop steal stretch struggle study strike suffer submit suffer suggest succeed supply survive swear suppose swim swing swamp swoon safe shout shroud should sake shake saw sower shepherd size sax sage snap selfy",
		"title" : "Most Common Verbs - S",
		"code" : "be-40"
	},{
		"id" : 41,
		"text" : "take tap talk tase taste tasty teal tear tip top tank tinker teach tell tend treat tear think threaten throw tiptoe translate tolerate try tingle trap toggle tax torn toy trolly troll",
		"title" : "Most Common Verbs - T",
		"code" : "be-41"
	},{
		"id" : 42,
		"text" : "unfold upset undertake understand under use urge unit unity unite upgrade update upload useless usefull upper urine usual usually user",
		"title" : "Most Common Verbs - U",
		"code" : "be-42"
	},{
		"id" : 43,
		"text" : "van vex validate vax vanish visual visualize volunteer verify visit view vapor viper visitor vital vocalize vitalize vent vulture vick vaw vamp vampire",
		"title" : "Most Common Verbs - V",
		"code" : "be-43"
	},{
		"id" : 44,
		"text" : "wait war want wand walk wake wash warn watch wear wonder weep whip weigh will win won would white write writ was way woke while wizz wizard wound",
		"title" : "Most Common Verbs - W",
		"code" : "be-44"
	},{
		"id" : 45,
		"text" : "xray xerox ye yielf yeah yearn zip zap zoom zig zag yell zigzag zero zoon zoot zest zone yonder yet you yup",
		"title" : "Most Common Verbs - X, Y, Z",
		"code" : "be-45"
	},{
		"id" : 46,
		"text" : "the talk tell tail tale tarry tag twinkle tan torch tingle term take tray tram till tab tax tardy tow two top tip tuck these those tango them tough tug ton tank than then thank through though thy",
		"title" : "Most Common Verbs - T",
		"code" : "be-46"
	},{
		"id" : 47,
		"text" : "For God so loved the world, that he gave his only begotten Son, that whosoever believeth in him should not perish, but have everlasting life. For God sent not his Son into the world to condemn the world; but that the world through him might be saved. He that believeth on him is not condemned: but he that believeth not is condemned already, because he hath not believed in the name of the only begotten Son of God",
		"title" : "John 3:16-18",
		"code" : "be-47"
	},{
		"id" : 48,
		"text" : "Moreover, brethren, I declare unto you the gospel which I preached unto you, which also ye have received, and wherein ye stand; By which also ye are saved, if ye keep in memory what I preached unto you, unless ye have believed in vain.  For I delivered unto you first of all that which I also received, how that Christ died for our sins according to the scriptures; And that he was buried, and that he rose again the third day according to the scriptures:",
		"title" : "1 Corinthians 15: 1-4",
		"code" : "be-48"
	},{
		"id" : 49,
		"text" : "And that he was seen of Cephas, then of the twelve: After that, he was seen of above five hundred brethren at once; of whom the greater part remain unto this present, but some are fallen asleep. After that, he was seen of James; then of all the apostles. And last of all he was seen of me also, as of one born out of due time.",
		"title" : "1 Corinthians 15: 4-8",
		"code" : "be-49",
	},{
		"id" : 50,
		"text" : "But the fruit of the Spirit is love, joy, peace, longsuffering, gentleness, goodness, faith, Meekness, temperance: against such there is no law. And they that are Christ's have crucified the flesh with the affections and lusts. If we live in the Spirit, let us also walk in the Spirit. Let us not be desirous of vain glory, provoking one another, envying one another.",
		"title" : "Fruit of the Spirit (Gal 5:22-26)",
		"code" : "be-50",
	},{
		"id" : 51,
		"text" : "But if ye be led of the Spirit, ye are not under the law. Now the works of the flesh are manifest, which are these; Adultery, fornication, uncleanness, lasciviousness, Idolatry, witchcraft, hatred, variance, emulations, wrath, strife, seditions, heresies, Envyings, murders, drunkenness, revellings, and such like: of the which I tell you before, as I have also told you in time past, that they which do such things shall not inherit the kingdom of God.",
		"title" : "Who shall not inherit the kingdom of God (Gal 5:18-21)",
		"code" : "be-51",
	},{
		"id" : 52,
		"text" : "After this manner therefore pray ye: Our Father which art in heaven, Hallowed be thy name. Thy kingdom come, Thy will be done in earth, as it is in heaven. Give us this day our daily bread. And forgive us our debts, as we forgive our debtors. And lead us not into temptation, but deliver us from evil: For thine is the kingdom, and the power, and the glory, for ever. Amen.",
		"title" : "The Lord's Prayer: Matthew 6:9-13",
		"code" : "be-52",
	},{
		"id" : 53,
		"text" : "Blessed are the poor in spirit: for theirs is the kingdom of heaven. Blessed are they that mourn: for they shall be comforted. Blessed are the meek: for they shall inherit the earth. Blessed are they which do hunger and thirst after righteousness: for they shall be filled. Blessed are the merciful: for they shall obtain mercy.",
		"title" : "Matthew 5:3-7 - Blessed",
		"code" : "be-53",
	},{
		"id" : 54,
		"text" : "Blessed are the pure in heart: for they shall see God. Blessed are the peacemakers: for they shall be called the children of God. Blessed are they which are persecuted for righteousness' sake: for theirs is the kingdom of heaven. Blessed are ye, when men shall revile you, and persecute you, and shall say all manner of evil against you falsely, for my sake.",
		"title" : "Matthew 5:8-11 - Blessed",
		"code" : "be-54",
	},{
		"id" : 55,
		"text" : "Ye are the salt of the earth: but if the salt have lost his savour, wherewith shall it be salted? it is thenceforth good for nothing, but to be cast out, and to be trodden under foot of men. Ye are the light of the world. A city that is set on an hill cannot be hid. Neither do men light a candle, and put it under a bushel, but on a candlestick; and it giveth light unto all that are in the house.",
		"title" : "Matthew 5:13-15 - Salt and Light ",
		"code" : "be-55",
	},{
		"id" : 56,
		"text" : "Judge not, that ye be not judged. For with what judgment ye judge, ye shall be judged: and with what measure ye mete, it shall be measured to you again. And why beholdest thou the mote that is in thy brother's eye, but considerest not the beam that is in thine own eye? Or how wilt thou say to thy brother, Let me pull out the mote out of thine eye; and, behold, a beam is in thine own eye? Thou hypocrite, first cast out the beam out of thine own eye; and then shalt thou see clearly to cast out the mote out of thy brother's eye.",
		"title" : "Matthew 7:1-5 - Judge not",
		"code" : "be-56",
	},{
		"id" : 57,
		"text" : "Ask, and it shall be given you; seek, and ye shall find; knock, and it shall be opened unto you: For every one that asketh receiveth; and he that seeketh findeth; and to him that knocketh it shall be opened. Or what man is there of you, whom if his son ask bread, will he give him a stone?  Or if he ask a fish, will he give him a serpent?  If ye then, being evil, know how to give good gifts unto your children, how much more shall your Father which is in heaven give good things to them that ask him?",
		"title" : "Matthew 7:7-11 - Seek, Ask & Knock",
		"code" : "be-57",
	},{
		"id" : 58,
		"text" : "Now faith is the substance of things hoped for, the evidence of things not seen. For by it the elders obtained a good report. Through faith we understand that the worlds were framed by the word of God, so that things which are seen were not made of things which do appear. By faith Abel offered unto God a more excellent sacrifice than Cain, by which he obtained witness that he was righteous, God testifying of his gifts: and by it he being dead yet speaketh.",
		"title" : "Hebrews 11 - Faith",
		"code" : "be-58",
	},{
		"id" : 59,
		"text" : "Though I speak with the tongues of men and of angels, and have not charity, I am become as sounding brass, or a tinkling cymbal. And though I have the gift of prophecy, and understand all mysteries, and all knowledge; and though I have all faith, so that I could remove mountains, and have not charity, I am nothing. And though I bestow all my goods to feed the poor, and though I give my body to be burned, and have not charity, it profiteth me nothing.",
		"title" : "1 Corinthians 13 - Love(Charity)",
		"code" : "be-59",
	},{
		"id" : 60,
		"text" : "Charity suffereth long, and is kind; charity envieth not; charity vaunteth not itself, is not puffed up, Doth not behave itself unseemly, seeketh not her own, is not easily provoked, thinketh no evil; Rejoiceth not in iniquity, but rejoiceth in the truth; Beareth all things, believeth all things, hopeth all things, endureth all things. Charity never faileth: but whether there be prophecies, they shall fail; whether there be tongues, they shall cease; whether there be knowledge, it shall vanish away.",
		"title" : "1 Corinthians 13 - Love(Charity)",
		"code" : "be-60",
	}
]
const intermediateLessons = [
	{
		"id" : 1,
		"text" : "AaAaAaAaAa",
		"title" : "Key A - left hand tiny finger",
		"code" : "in-1",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">A</span></h4>",
		"img": "/keys/ca-key.png"
	},{
		"id" : 2,
		"text" : "SsSsSsSsSs",
		"title" : "Key S - left hand ring finger",
		"code" : "in-2",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">S</span></h4>",
		"img": "/keys/cs-key.png"
	},{
		"id" : 3,
		"text" : "DdDdDdDdDd",
		"title" : "Key D - left hand middle finger",
		"code" : "in-3",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">D</span></h4>",
		"img": "/keys/cd-key.png"
	},{
		"id" : 4,
		"text" : "FfFfFfFfFf",
		"title" : "Key F - left hand index finger",
		"code" : "in-4",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">F</span></h4>",
		"img": "/keys/cf-key.png"
	},{
		"id" : 5,
		"text" : "Aa As Ad Af Aa As Ad Af Sa Da Fa Sa Da Fa",
		"title" : "Practice Keys - A, S, D & F",
		"code" : "in-5"
	},{
		"id" : 6,
		"text" : "Ass Add Aff Sad Dad Fad Ass Add Aff Sad Dad Fad Added Add Sad",
		"title" : "Practice Keys - A, S, D & F",
		"code" : "in-6"
	},{
		"id" : 7,
		"text" : "EeEeEeEeEe",
		"title" : "Key E - left hand middle finger",
		"code" : "in-7",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">E</span></h4>",
		"img": "/keys/ce-key.png"
	},{
		"id" : 8,
		"text" : "RrRrReReRe",
		"title" : "Key R - left hand index finger",
		"code" : "in-8",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">R</span></h4>",
		"img": "/keys/cr-key.png"
	},{
		"id" : 9,
		"text" : "See Dee Fee Ree Err Add Era Ear Eat Red Fed Feed Ree Def Ref Red",
		"title" : "Practice Keys - E & R",
		"code" : "in-9"
	},{
		"id" : 10,
		"text" : "Ease Dead Fade Feed Reed Dear Fear Sear Read Fard Sard Dead Rase Sare Sear",
		"title" : "Practice Keys - E & R",
		"code" : "in-10"
	},{
		"id" : 11,
		"text" : "JjJjJjJjJj",
		"title" : "Key J - right hand index finger",
		"code" : "in-11",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">J</span></h4>",
		"img": "/keys/cj-key.png"
	},{
		"id" : 12,
		"text" : "KkKkKkKkKk",
		"title" : "Key K - right hand middle finger",
		"code" : "in-12",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">K</span></h4>",
		"img": "/keys/ck-key.png"
	},{
		"id" : 13,
		"text" : "LlLlLlLlLl",
		"title" : "Key L - right hand ring finger",
		"code" : "in-13",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">L</span></h4>",
		"img": "/keys/cl-key.png"
	},{
		"id" : 14,
		"text" : "Ja Ka La Je Ke Le Jj Kk Ll Ja Ka La Je Ke La Kj Kk Ll",
		"title" : "Practice Keys - J, K & L",
		"code" : "in-14"
	},{
		"id" : 15,
		"text" : "Jake Lake Fake Rake Lead Jeal Feal Keel Leek Kale Fead Sake Sale Raja Jarr Karl",
		"title" : "Practice Keys - J, K & L",
		"code" : "in-15"
	},{
		"id" : 16,
		"text" : "IiIiIiIiIi",
		"title" : "Key I - right hand middle finger",
		"code" : "in-16",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">I</span></h4>",
		"img": "/keys/ci-key.png"
	},{
		"id" : 17,
		"text" : "UuUuUuUuUu",
		"title" : "Key U - right hand index finger",
		"code" : "in-17",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">U</span></h4>",
		"img": "/keys/cu-key.png"
	},{
		"id" : 18,
		"text" : "Ai Si Di Fi Hi Ji Ki Li Ri Ui Au Su Du Fu Hu Ju Ku Lu",
		"title" : "Practice Key U & I",
		"code" : "in-18"
	},{
		"id" : 19,
		"text" : "Lull Dull Jill Kill Fill Full Diff Riff Ruff Rudd Surd Furl Sire Lire Jure Lure Furr Sure Aura",
		"title" : "Practice Key U & I",
		"code" : "in-19"
	},{
		"id" : 20,
		"text" : "OoOoOoOoOo",
		"title" : "Key O - right hand ring finger",
		"code" : "in-20",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">O</span></h4>",
		"img": "/keys/co-key.png"
	},{
		"id" : 21,
		"text" : "PpPpPpPpPp",
		"title" : "Key p - right hand tiny finger",
		"code" : "in-21",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">P</span></h4>",
		"img": "/keys/cp-key.png"
	},{
		"id" : 22,
		"text" : "Ou Oi Out Ok Ole Lo Opps Opt Oat Out Opt Ops Opt Oil Oint Okay",
		"title" : "Practice Keys - O & P",
		"code" : "in-22"
	},{
		"id" : 23,
		"text" : "Pop Sap Pap Papa Pick Pale Pail Par Per Pid Pat Pass Phase Pour Pond Onto Obey Poll Pack Ohh",
		"title" : "Practice Keys - O & P",
		"code" : "in-23"
	},{
		"id" : 24,
		"text" : "QqQqQqQqQq",
		"title" : "Key Q - left hand tiny finger",
		"code" : "in-24",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">Q</span></h4>",
		"img": "/keys/cq-key.png"
	},{
		"id" : 25,
		"text" : "WwWwWwWwWw",
		"title" : "Key W - left hand ring finger",
		"code" : "in-25",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">W</span></h4>",
		"img": "/keys/cw-key.png"
	},{
		"id" : 26,
		"text" : "Qa Qua Qi Quad Quit Quiet Quite We Why Wow Eq Who Where Wat What",
		"title" : "Practice Keys - Q & W",
		"code" : "in-26"
	},{
		"id" : 27,
		"text" : "Qua Queer Quid Quad Quel Awe Owl Wipe Wear Was Saw Raw Wed Woof Wall Wipe Write Quartz Wape Wonder",
		"title" : "Practice Keys - Q & W",
		"code" : "in-27"
	},{
		"id" : 28,
		"text" : "GgGgGgGgGg",
		"title" : "Key G - left hand index finger",
		"code" : "in-28",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">G</span></h4>",
		"img": "/keys/cg-key.png"
	},{
		"id" : 29,
		"text" : "TtTtTtTtTt",
		"title" : "Key t - left hand index finger",
		"code" : "in-29",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">T</span></h4>",
		"img": "/keys/ct-key.png"
	},{
		"id" : 30,
		"text" : "Ta Thy To Tu Te Ga Gi Go Gu Ge Gg Tt Gt Ta Ti To Tu Te Ga Gi Go Gu Ge Gg Tt Gt",
		"title" : "Practice Keys - T & G",
		"code" : "in-30"
	},{
		"id" : 31,
		"text" : "Tar Tarp Tare Toad Till Trap Toil Tear Turf Grap Grapes Goes Gate Gear Gay Guy Ghi Group Take",
		"title" : "Practice Keys - T & G",
		"code" : "in-31"
	},{
		"id" : 32,
		"text" : "HhHhHhHhHh",
		"title" : "Key H - right hand index finger",
		"code" : "in-32",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">H</span></h4>",
		"img": "/keys/ch-key.png"
	},{
		"id" : 33,
		"text" : "YyYyYyYyYy",
		"title" : "Key Y - right hand index finger",
		"code" : "in-33",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">Y</span></h4>",
		"img": "/keys/cy-key.png"
	},{
		"id" : 34,
		"text" : "Ha Hi He Ho Hu Ya Ye Yo Yu Yi Hy Yh Ha Hi He Ho Hu Ya Ye Yo Yu Yi Hy Yh",
		"title" : "Practice Keys - H & Y",
		"code" : "in-34"
	},{
		"id" : 35,
		"text" : "What Where How Whey Yeah Hare Hear Hut House Yak Yarn Yeah Hire Hay Hey Hope Yelp",
		"title" : "Practice Keys - H & Y",
		"code" : "in-35"
	},{
		"id" : 36,
		"text" : "NnNnNnNnNn",
		"title" : "Key N - right hand index finger",
		"code" : "in-36",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">N</span></h4>",
		"img": "/keys/cn-key.png"
	},{
		"id" : 37,
		"text" : "MmMmMmMmMm",
		"title" : "Key M - right hand index finger",
		"code" : "in-37",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">M</span></h4>",
		"img": "/keys/cm-key.png"
	},{
		"id" : 38,
		"text" : "Na Ne Ni No Nu Nn Nm Mm Mn Ma Ne Ni No Nu Nn Mn Mm Mn",
		"title" : "Practice Keys - N & M",
		"code" : "in-38"
	},{
		"id" : 39,
		"text" : "Name Mail Mail Maps Naps Mall Make Naked Nail Mound Null Mull Mill Nay May Make",
		"title" : "Practice Keys - N & M",
		"code" : "in-39"
	},{
		"id" : 40,
		"text" : "VvVvVvVvVv",
		"title" : "Key V - left hand index finger",
		"code" : "in-40",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">V</span></h4>",
		"img": "/keys/cv-key.png"
	},{
		"id" : 41,
		"text" : "BbBbBbBbBb",
		"title" : "Key B - left hand index finger",
		"code" : "in-41",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">B</span></h4>",
		"img": "/keys/cb-key.png"
	},{
		"id" : 42,
		"text" : "Va Ve Vi Vo Vu Vb Bv Ba Be Bi Bu Bo By Va Ve Vi Vo Vu Vb Bv Ba Be Bi Bu Bo Vy",
		"title" : "Practice Keys - V & B",
		"code" : "in-42"
	},{
		"id" : 43,
		"text" : "View Vibe Vent Vax View Vamp Brake Burp Bake Bend Beaver Brand Vows Bower Buying",
		"title" : "Practice Keys - V & B",
		"code" : "in-43"
	},{
		"id" : 44,
		"text" : "CcCcCcCcCc",
		"title" : "Key C - left hand middle finger",
		"code" : "in-44",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">C</span></h4>",
		"img": "/keys/cc-key.png"
	},{
		"id" : 45,
		"text" : "XxXxXxXxXx",
		"title" : "Key X - left hand ring finger",
		"code" : "in-45",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">X</span></h4>",
		"img": "/keys/cx-key.png"
	},{
		"id" : 46,
		"text" : "Ca C Chi Cha Che Xu Xi Ex Exo Pax Xander Xray Xcel Crux Lux Chair Chap",
		"title" : "Practice Keys - C & X",
		"code" : "in-46"
	},{
		"id" : 47,
		"text" : "Express Expo Axel Exile Corex Hexa Cock Cake Crack Crux Crate Chars Xerxes Max Lax Maximum",
		"title" : "Practice Keys - C & X",
		"code" : "in-47"
	},{
		"id" : 48,
		"text" : "ZzZzZzZzZz",
		"title" : "Key Z - left hand tiny finger",
		"code" : "in-48",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">Z</span></h4>",
		"img": "/keys/z-key.png"
	},{
		"id" : 49,
		"text" : "Za Zoo Jazz Zack Wizz Lizard Wizard Fizz Razor Zig Zig Zap Zoo Zinc Zing Zoom Zazzal Zinger",
		"title" : "Practice Keys - Z",
		"code" : "in-49"
	},{
		"id" : 50,
		"text" : "See See Dee Dee Fee Fee Jee Jee Kee Kee Lee Lee Sea Sea Dea Dea Fea Fea Kea Kea Lea Red Red Sea Sad Fed Dad Ref Far Fad Sed Lal kaK laR arK alL eeL eaR erR eRa aRE aLE lAG leG jEf lAd Led Las Les Def Ref Jaj jal jar jak far fad fas faj fej fek del dal dej sej saj daj kaj laj lek lej dad",
		"title" : "Practice Words with Three Letters",
		"code" : "in-50"
	},{
		"id" : 51,
		"text" : "Ale Ale Ail Ail Air Air Ait Ait All All Are Are Ark Ark Awe Awe Ask Ask Eat Eat Eel Eel Err Err Era Era Ear Ear Oil Oil Owl Owl Ora Oat Oat Oak Oak Irk Irk Aid Aid Was Was War War Rat Rat Yes Yea Yea Jit Jot Jot Kit Kit Kat Kat Kid Kid For For Far Far Jar Lat Lat Let Let Lot Sit Set Sat",
		"title" : "Practice Words with Three Letters",
		"code" : "in-51"
	},{
		"id" : 52,
		"text" : "Wore Wore Ware Ware Wait Wait Tore Tore Tail Tail Tare Tare Fore Fore Four Four Fare Fare Feat Feat Fell Fell Fall Fall Fart Fart Fork Fork Furl Furl File File Fade Fade Fake Fake Lard Lard Kart Kart Jour Jour Dare Dare Dork Dork Dark Dark Damn Damn Dire Dire Deal Deal Dear Dear Rail Rail Rear Rear",
		"title" : "Practice Words with Four Letters",
		"code" : "in-52"
	},{
		"id" : 53,
		"text" : "Trap Trap Troll Troll Town Town Tour Tour Trader Trader Trailed Trailed Tailer Tailer Trait Trapper Traps Trash Trawls Trays Toys Toys Topped Topped Tips Tips Turd Turd Turnip Turnip Toil Toiled Toils Tone Toned Tones Tunes Tuned Tuner Tanned Tank Tanked Thank Thanking Thanks Tyre Type Tock Tick Tack Tow Toed Towed Tip Tap Tip Tip Thanks",
		"title" : "Practice Words",
		"code" : "in-54"
	},{
		"id" : 54,
		"text" : "Quip Quip Quip Quip Quit Quit Quit Quit Quay Quay Quay Queen Queen Queen Queens Quickly Quickly Quickly Quiet Quiet Qua Quip Quip Quip Quip Quit Quit Quit Quit Quay Quay Quay Queen Queen Queen Queens Quickly Quickly Quickly Quiet Quiet Qua",
		"title" : "Practice Keys - Q",
		"code" : "in-54"
	},{
		"id" : 55,
		"text" : "Okay Okay Okay Oops Oops Oops Oaks Oaks Oaks Oils Oils Oils Onto Onto Onto Ooze Ooze Ooze Oxen Oxen Oxen Onyx Onyx Onyx Okay Okay Okay Oops Oops Oops Oaks Oaks Oaks Oils Oils Oils Onto Onto Onto Ooze Ooze Ooze Oxen Oxen Oxen Onyx Onyx Onyx",
		"title" : "Practice Keys - O",
		"code" : "in-55"
	},{
		"id" : 56,
		"text" : "Four Zippers Were Picked From The Woven Jute Bag Quickly, My Boy Wove Six Dozen Plaid Jackets.",
		"title" : "All Letters - Pangram 1",
		"code" : "in-56"
	},{
		"id" : 57,
		"text" : "Mr. Jock, TV quiz PhD., bags few lynx. Mr. Jock, TV quiz PhD., bags few lynx.",
		"title" : "All Letters & . ,  - Pangram 2",
		"code" : "in-57"
	},{
		"id" : 58,
		"text" : "Jock nymphs waqf drug vex blitz. Waltz, nymph, for quick jigs vex Bud.",
		"title" : "All Letters - Pangram 3",
		"code" : "in-58"
	},{
		"id" : 59,
		"text" : "Glib jocks quiz nymph to vex dwarf. Jackdaws love my big sphinx of quartz.",
		"title" : "All Letters - Pangram 4",
		"code" : "in-59"
	},{
		"id" : 60,
		"text" : "The jay, pig, fox, zebra and my wolves quack. Sympathizing would fix Quaker objectives.",
		"title" : "All Letters - Pangram 5",
		"code" : "in-60"
	}
]
const advanceLessons = [
	{
		"id" : 1,
		"text" : "111 111 111 111 111",
		"title" : "Key 1 - left hand tiny finger",
		"code" : "ad-1",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">1</span></h4>",
		"img": "/keys/1-key.png"
	},{
		"id" : 2,
		"text" : "222 222 222 222 222",
		"title" : "Key 2 - left hand ring finger",
		"code" : "ad-2",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">2</span></h4>",
		"img": "/keys/2-key.png"
	},{
		"id" : 3,
		"text" : "333 333 333 333 333",
		"title" : "Key 3 - left hand ring finger",
		"code" : "ad-3",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">3</span></h4>",
		"img": "/keys/3-key.png"
	},{
		"id" : 4,
		"text" : "444 444 444 444 444",
		"title" : "Key 4 - left hand index finger",
		"code" : "ad-4",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">4</span></h4>",
		"img": "/keys/4-key.png"
	},{
		"id" : 5,
		"text" : "555 555 555 555 555",
		"title" : "Key 5 - left hand index finger",
		"code" : "ad-5",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">5</span></h4>",
		"img": "/keys/5-key.png"
	},{
		"id" : 6,
		"text" : "666 666 666 666 666",
		"title" : "Key 6 - right hand index finger",
		"code" : "ad-6",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">6</span></h4>",
		"img": "/keys/6-key.png"
	},{
		"id" : 7,
		"text" :  "777 777 777 777 777",
		"title" : "Key 7 - right hand index finger",
		"code" : "ad-7",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">7</span></h4>",
		"img": "/keys/7-key.png"
	},{
		"id" : 8,
		"text" :  "888 888 888 888 888",
		"title" : "Key 8 - right hand middle finger",
		"code" : "ad-8",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">8</span></h4>",
		"img": "/keys/8-key.png"
	},{
		"id" : 9,
		"text" :  "999 999 999 999 999",
		"title" : "Key 9 - right hand ring finger",
		"code" : "ad-9",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">9</span></h4>",
		"img": "/keys/9-key.png"
	},{
		"id" : 10,
		"text" :  "000 000 000 000 000",
		"title" : "Key 0 - right hand tiny finger",
		"code" : "ad-10",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">0</span></h4>",
		"img": "/keys/0-key.png"
	},{
		"id" : 11,
		"text": "111 222 333 444 555 123 234 345 451 512 Highway 344, 1st turn to the right and pass 53rd.",
		"title": "Numbers",
		"code": "ad-11"
	},{
		"id" : 12,
		"text": "666 777 888 999 678 789 890 123 234 345 11 22 33 44 55 66 77 88 99 10 20 30 40 50 60 70 80 90",
		"title": "Numbers",
		"code": "ad-12"
	},{
		"id" : 13,
		"text": "1st, 2nd, 3rd, 4th, 5th, 6th, 7th, 8th, 9th and 10th. John 3:16 1st, 2nd, 3rd, 4th,",
		"title": "Numbers",
		"code": "ad-13"
	},{
		"id" : 14,
		"text": "1,000mg 0.33 Oz 15g 90kg 145 pounds, 67 John 4:12 1 can 45ml 1.7x5.345.34",
		"title": "Numbers",
		"code": "ad-14"
	},{
		"id" : 15,
		"text": "There are 70 primary Interstate Highways in the 48 contiguous United States, NY 318 is an east west state highwaym The origins of NY 318 date back to the 19th century",
		"title": "Text & Number Words",
		"code": "ad-15"
	},{
		"id" : 16,
		"text": "1,000 years : between 1200 BC and 100 AD. 700 to 3,500 languages. 31,102 verses in KJB.",
		"title": "Drill Numbers",
		"code": "ad-16"
	},{
		"id" : 17,
		"text" : "1 !! 1 !! 1 !! 1 !!",
		"title" : "Key ! - left hand tiny finger",
		"code" : "ad-17",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">!</span></h4>",
		"img": "/keys/cexclaimation-key.png"
	},{
		"id" : 18,
		"text" : "2 @@ 2 @@ 2 @@ 2 @@",
		"title" : "Key @ - left hand ring finger",
		"code" : "ad-18",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">@</span></h4>",
		"img": "/keys/cat-key.png"
	},{
		"id" : 19,
		"text" : "3 ## 3 ## 3 ## 3 ##",
		"title" : "Key # - left hand ring finger",
		"code" : "ad-19",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">#</span></h4>",
		"img": "/keys/chash-key.png"
	},{
		"id" : 20,
		"text" : "4 $$ 4 $$ 4 $$ 4 $$",
		"title" : "Key $ - left hand middle finger",
		"code" : "ad-20",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">$</span></h4>",
		"img": "/keys/cdollar-key.png"
	},{
		"id" : 21,
		"text" : "5 %% 5 %% 5 %% 5 %%",
		"title" : "Key % - left hand index finger",
		"code" : "ad-21",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">%</span></h4>",
		"img": "/keys/cpercent-key.png"
	},{
		"id" : 22,
		"text" : "6 ^^ 6 ^^ 6 ^^ 6 ^^",
		"title" : "Key ^ - left hand index finger",
		"code" : "ad-22",
		"help" : "<div>Use your right hand tiny finger to press 'shift' key and left hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">^</span></h4>",
		"img": "/keys/ccaret-key.png"
	},{
		"id" : 23,
		"text" : "7 && 7 && 7 && 7 &&",
		"title" : "Key & - right hand index finger",
		"code" : "ad-23",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand index finger to</div> <h4>type the key <span class=\"h-key letter is-active\">&</span></h4>",
		"img": "/keys/camph-key.png"
	},{
		"id" : 24,
		"text" : "8 ** 8 ** 8 ** **",
		"title" : "Key * - right hand middle finger",
		"code" : "ad-24",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand middle finger to</div> <h4>type the key <span class=\"h-key letter is-active\">*</span></h4>",
		"img": "/keys/cstar-key.png"
	},{
		"id" : 25,
		"text" : "9 (( 9 (( 9 (( 9 ((",
		"title" : "Key 9 - right hand ring finger",
		"code" : "ad-25",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand ring finger to</div> <h4>type the key <span class=\"h-key letter is-active\">(</span></h4>",
		"img": "/keys/cleft-bracket-key.png"
	},{
		"id" : 26,
		"text" : "9 )) 9 )) 9 )) 9 ))",
		"title" : "Key 9 - right hand tiny finger",
		"code" : "ad-26",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">)</span></h4>",
		"img": "/keys/cright-bracket-key.png"
	},{
		"id" : 27,
		"text" : "9 -- 9 -- 9 -- 9 --",
		"title" : "Key - : right hand tiny finger",
		"code" : "ad-257",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">-</span></h4>",
		"img": "/keys/cdash-key.png"
	},{
		"id" : 28,
		"text" : "9 ++ 9 ++ 9 ++ 9 ++",
		"title" : "Key + - right hand tiny finger",
		"code" : "ad-28",
		"help" : "<div>Use your left hand tiny finger to press 'shift' key and right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">+</span></h4>",
		"img": "/keys/cplus-key.png"
	},{
		"id" : 29,
		"text" : "9 == 9 == 9 == 9 ==",
		"title" : "Key = - right hand tiny finger",
		"code" : "ad-29",
		"help" : "<div>Use your right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">=</span></h4>",
		"img": "/keys/equal-key.png"
	},{
		"id" : 30,
		"text" : "single' '' is's '' that's her's ''",
		"title" : "Key ' - right hand tiny finger",
		"code" : "ad-30",
		"help" : "<div>Use your right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">'</span></h4>",
		"img": "/keys/single-quote-key.png"
	},{
		"id" : 31,
		"text": "Apostrophe''' it's that's your's it's that's your's, it's that's your's it's",
		"title": "Puntuations",
		"code": "ad-31"
	},{
		"id" : 32,
		"text" : "double\" d\" \" \"double\" \"double quote\" \"\" '",
		"title" : "Key \" - right hand tiny finger",
		"code" : "ad-32",
		"help" : "<div>Use your right hand tiny finger to</div> <h4>type the key <span class=\"h-key letter is-active\">\"</span></h4>",
		"img": "/keys/cquote-key.png"
	},{
		"id" : 33,
		"text": "Quotation marks\" \" \" \" Hyphen--- Hyphen--- \"'\" An exclamation!!! :! :! in-law's sister-in-law ",
		"title": "Puntuations",
		"code": "ad-33"
	},{
		"id" : 34,
		"text": "In the books of John, chapter 14 verse 6, Jesus said, \"I am the way and the truth and the life\", no one comes to father except through me",
		"title": "Puntuation Words",
		"code": "ad-34"
	},{
		"id" : 35,
		"text": "Oh Wow!, says Mike. \"I like Purple too!\" My father-in-law said, He lived on the 3rd street, close to the Highway 169.",
		"title": "Puntuations Words",
		"code": "ad-35"
	},{
		"id" : 36,
		"text": "A question mark? ? ? ? it's not that!, said he? I like Purple too!",
		"title": "Puntuations",
		"code": "ad-36"
	},{
		"id" : 37,
		"text": "On average, there are 100 million bibles printed each year. It's projected that there are more than 6 billion Bibles currently in print - 140 percent more than the estimated 2.5 billion copies in print as of 1975. The number of bibles sold on average has more than doubled in the US since 1950 with: 20 million bibles sold each year, 1.66 million bibles sold each month, 384,615 bibles sold per week, 54,945 bibles sold every day 2,289 bibles sold per hour, 38 bibles sold per minute, 6.4 bibles sold every 10 seconds, In addition to the bibles sold, another 115,055 bibles are given away or distributed every day, The US accounts for a quarter of newly printed bible sales every year.",
		"title": "Drill: Bible Stats from wordsrated.com",
		"code": "ad-37"
	},{
		"id" : 38,
		"text": "1,000 years - between 1200 BC and 100 AD. 700 to 3,500 languages. 31,102 verses in KJB.",
		"title": "Drill: Mixed",
		"code": "ad-38"
	},{
		"id" : 39,
		"text": "Printed in the 1450s, mass-produced in Europe. Esther 8:9. Psalm 119, John 3:16 ",
		"title": "Drill: Mixed Words",
		"code": "ad-39"
	},{
		"id" : 40,
		"text": "Printed in the 1450s, mass-produced in Europe. Esther 8:9. Psalm 119, John 3:16 ",
		"title": "Drill: Mixed Words",
		"code": "ad-40"
	},{
		"id" : 41,
		"text": "The Bible was written by around 40 different authors from various walks of life, including kings, shepherds, and fishermen. The Bible was written over a period of approximately 1,500 years. The Gutenberg Bible, printed in the 1450s, was the first major book printed using mass-produced movable metal type in Europe.",
		"title": "Drill: Bible facts from blog.gitnux.com",
		"code": "ad-41"
	},{
		"id" : 42,
		"text": "The word 'Bible' comes from the Latin word 'biblia,' which means \"books.\", The shortest verse in the Bible is John 11:35, which states, \"Jesus wept.\", The longest verse in the Bible is Esther 8:9. Psalm 119 is the longest chapter in the Bible with 176 verses. The phrase “do not be afraid” appears 365 times in the Bible.",
		"title": "Drill: Bible facts from blog.gitnux.com",
		"code": "ad-42"
	},{
		"id" : 43,
		"text": "The word 'Bible' comes from the Latin word 'biblia,' which means \"books.\", The shortest verse in the Bible is John 11:35, which states, \"Jesus wept.\", The longest verse in the Bible is Esther 8:9. Psalm 119 is the longest chapter in the Bible with 176 verses. The phrase “do not be afraid” appears 365 times in the Bible.",
		"title": "Drill: Bible facts from blog.gitnux.com",
		"code": "ad-43"
	},{
		"id" : 44,
		"text": "Watch \"Jeopardy!\", Alex Trebek's fun TV quiz game. By Jove, my quick study of lexicography won a prize! Waxy and quivering, jocks fumble the pizza.",
		"title": "Drill: Pangram",
		"code": "ad-44"
	},{
		"id" : 45,
		"text": "This Pangram contains four a's, one b, two c's, one d, thirty e's, six f's, five g's, seven h's, eleven i's, one j, one k, two l's, two m's, eighteen n's, fifteen o's, two p's, one q, five r's, twenty-seven s's, eighteen t's, two u's, seven v's, eight w's, two x's, three y's, & one z. : Lee Sallows",
		"title": "Drill: Pangram",
		"code": "ad-45"
	}
]
export {basicLessons, beginnerLessons, intermediateLessons, advanceLessons }

