import React, {useEffect, useState} from "react";
import parse from 'html-react-parser'
import {Keyboard, KeyboardActiveKey, CapsLockOverlay} from "../components/keyboard";
/*import {matthew} from '../data/main/40.js'
import {mark} from '../data/main/41.js'
import {luke} from '../data/main/42.js'
import {john} from '../data/main/43.js'
import {acts} from '../data/main/44.js'
import {romans} from '../data/main/45.js'
import {corinthians1} from '../data/main/46.js'
import {corinthians2} from '../data/main/47.js'
import {galatians} from '../data/main/48.js'
import {ephesians} from '../data/main/49.js'
import {philippians} from '../data/main/50.js'
import {colossians} from '../data/main/51.js'
import {thessalonians1} from '../data/main/52.js'
import {thessalonians2} from '../data/main/53.js'
import {timothy1} from '../data/main/54.js'
import {timothy2} from '../data/main/55.js'
import {titus} from '../data/main/56.js'
import {philemon} from '../data/main/57.js'
import {hebrews} from '../data/main/58.js'
import {james} from '../data/main/59.js'
import {peter1} from '../data/main/60.js'
import {peter2} from '../data/main/61.js'
import {john2} from '../data/main/63.js'
import {john3} from '../data/main/64.js'
import {revelation} from '../data/main/66.js'*/
import { bookNames } from "../data/main/book-names";
import { books } from "../data/main/book-names";
import {jude} from '../data/main/65.js'
import {john1} from '../data/main/62.js'
import {StatsMainBarV} from "../components/Stats-sidebar";
import MainSettings from '../components/Main-settings-modal'
//import {InlineShareButtons} from 'sharethis-reactjs';

//import Keyboard  from './keyboard'

import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../components/Auth";

import './main.css';

const Main = () => {
    const navigate = useNavigate();
    const param = useParams()
    const auth = useAuth()
    const handleClickSettings = (e) => {
        e.preventDefault();
        document.querySelector(".main-settings").classList.remove('main-settings-hide');
        document.querySelector(".main-settings").classList.add('main-settings-show');
    }

    
    console.log("total verse", param.book, books)
    let bibleBookName = param.book ? param.book : 'genesis'
    bibleBookName = (param.book == 1) ? 'genesis' : bibleBookName
    //const totalBookVerse = bookNames[bookId - 1].verse
    //let bookFullName = bookNames[bookId - 1].book
    const totalBookVerse = books[bibleBookName].verse
    let bookFullName = books[bibleBookName].book
    let bookId = books[bibleBookName].id ? books[bibleBookName].id : 1

    let version = sessionStorage.getItem("settingsBibleVersion");
    let lastBibleVerseId = sessionStorage.getItem("settingsLastBibleVerseId"); // id from 1 to 31102
    let lastBookVerseId = sessionStorage.getItem("settingsLastBookVerseId"); // vid, each verse id from 1 to xxx
    let lastBook = sessionStorage.getItem("settingsLastBook");
    let lastChapter = sessionStorage.getItem("settingsLastChapter");
    let v = 0; //sessionStorage.getItem("v");

    if(!version){
        sessionStorage.setItem("settingsBibleVersion", "kjv_text");
        version = "kjv_text";
    } 
    if(!lastBibleVerseId){ // user lands first time to the site
        sessionStorage.setItem("settingsLastBibleVerseId", 1);
        lastBibleVerseId = 1;
    }

    
    
    


    let data = null;
    const bookArray = ["40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66"]
    /*if(param.book && param.book == "40") { data = matthew;  }
    if(param.book && param.book == "41") { data = mark;  }
    if(param.book && param.book == "42") { data = luke;  }
    if(param.book && param.book == "43") { data = john;  }
    if(param.book && param.book == "44") { data = acts;  }
    if(param.book && param.book == "45") { data = romans;  }
    if(param.book && param.book == "46") { data = corinthians1;  }
    if(param.book && param.book == "47") { data = corinthians2;  }
    if(param.book && param.book == "48") { data = galatians;  }
    if(param.book && param.book == "49") { data = ephesians;  }
    if(param.book && param.book == "50") { data = philippians;  }
    if(param.book && param.book == "51") { data = colossians;  }
    if(param.book && param.book == "52") { data = thessalonians1;  }
    if(param.book && param.book == "53") { data = thessalonians2;  }
    if(param.book && param.book == "54") { data = timothy1;  }
    if(param.book && param.book == "55") { data = timothy2;  }
    if(param.book && param.book == "56") { data = titus;  }
    if(param.book && param.book == "57") { data = philemon;  }
    if(param.book && param.book == "58") { data = hebrews;  }
    if(param.book && param.book == "59") { data = james;  }
    if(param.book && param.book == "60") { data = peter1;  }
    if(param.book && param.book == "61") { data = peter2;  }
    if(param.book && param.book == "62") { data = john1;  }
    if(param.book && param.book == "63") { data = john2;  }
    if(param.book && param.book == "64") { data = john3;  }
    if(param.book && param.book == "65") { data = jude;  }
    if(param.book && param.book == "66") { data = revelation;  }*/
    v = sessionStorage.getItem("book-verse-"+bookId) ? sessionStorage.getItem("book-verse-"+bookId):1
    //if(!param.book){ data = john1; v = sessionStorage.getItem("book-verse-62") }
    //if(param.book && !bookArray.includes(param.book)){ data = jude }

   
    const getBible = async () => { 
        //const user = {email: emailSignup,password: passwordSignup}
        lastBibleVerseId = sessionStorage.getItem("settingsLastBibleVerseId");
        let id = (lastBibleVerseId == 1) ? 1: lastBibleVerseId++
        const response = await fetch('/api/users/bible/'+id, {
            method: 'GET',
            //body: 'quote',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'book' : bookId,
                'verse' : v
            }
        })
        const json = await response.json();
        if(!response.ok){
            //setFirstVerseText(object[0].text)
            //setError(json.error)
        }
        if(response.ok){
            //setError(null)
            object = json
            try {
                str = [...object[firstVerse][version]];
                stra = object[firstVerse][version];
                strLength = str.length;
                verse = str.map((v, index) => {
                    return '<div id="chr_'+index+'" className="verse">'+v+'</div>';
                })
                title = object[firstVerse] ? object[firstVerse]['book_name'] : 'll';
                setFirstVerseText(verse.join(""));
                setMainTitle(title)
                document.querySelector(".key-"+stra[key].toLowerCase()).classList.add('is-active');
                console.log("res.ok", stra[key])
                if(stra[key] === 'A'){
                    //document.querySelector(".double-size").classList.add('is-active-bg');
                }
            } catch (err){
                console.log('catch errpr', err.message);
            }
            console.log(json)
        }

    }
   
   
   
   
    let object = '' // data; // get the verse/data from the book
    
    
    console.log("object.length, version",object.length, version);
    //let firstVerse = 0;
    let [firstVerse, setFirstVerse] = useState(0);
    let str, stra, strLength, verse, title;
    //let [version, setVersion] = useState(bible);
    const totalVerses = object.length - 1;
    str = [...("Loading...!")];
    stra = "Loading...!";
    
    //console.log(totalVerses);
    //let str = [...stra];
    //let strLength = str.length;
    //console.log(strLength);

    let caps = 0
    let seconds = 0
    let accuracy = 0
    let errorKeys = []
    verse = str.map((v, index) => {
        return '<div id="chr_'+index+'" className="verse">'+v+'</div>';
    })
    let [firstVerseText, setFirstVerseText] = useState(verse);
    let [mainTitle, setMainTitle] = useState("");
    let startTime, endTime;
    const start = () => {
        startTime = new Date().getTime();
    }
    const end = () => {
        endTime = new Date().getTime();
        let timeDiff = parseInt(endTime) - parseInt(startTime);
        console.log("diff", startTime, endTime)
        seconds = Math.round(timeDiff/1000);
    }
    
    let key = 0;
    //let nextk = "k";
    //const [nextk, setNextk]= useState('');
    //let [nextk, setNextk] = useState(stra[key]);
    console.log("stra[key]", stra[key], key, stra)
    useEffect(() => {
        getBible()
        //document.addEventListener('keydown', handleKeydown, true)
        document.addEventListener('keydown', handleKeydownMain)
        document.addEventListener("keyup", handleKeyUpMain);
        document.querySelector("#chr_0").classList.add('cursor');
        document.querySelector(".text").classList.add('tooltip');
        if(stra[key] === '(' || stra[key] === ')' || stra[key] === '[' || stra[key] === ']'){
            document.querySelector(".key-bracket").classList.add('is-active');
        } else {
            //document.querySelector(".key-"+stra[key].toLowerCase()).classList.add('is-active');
        }
        
        document.querySelector(".main-progress-percent-book").style.width = Math.round((firstVerse/object.length)*100)+"%";
        
        return () => {
            key = 0;
            document.removeEventListener('keydown', handleKeydownMain)
            document.removeEventListener('keyup', handleKeyUpMain)
        };
        
    },[param.book])

    //nextk = stra[key+1];
    //function testCapsLock(e) {
    const handleKeyUpMain = (e) => {
        //setNextk(stra[key+1]);
        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps = 1;
            } else {
                caps = 0;
            }
        }
        if(caps){
            console.log('caps on');
            //document.querySelector(".overlay").classList.remove('overlay-hide');
            document.querySelector(".overlay").classList.add('overlay-show');
            //e.preventDefault();
            //e.stopPropagation();
            //return false;
        } else {
            document.querySelector(".overlay").classList.add('overlay-hide');
        }
        console.log('key up', key, strLength)
        if(key === strLength){
            
            console.log("total verse", object.length - 1, firstVerse)
            
            document.querySelectorAll('.verse').forEach(el => el.classList.remove('red'))
            document.querySelectorAll('.verse').forEach(el => el.classList.remove('green'))
            document.querySelector("#chr_0").classList.add('cursor');
            console.log('error Key',e.key,stra[key], "keys:",errorKeys); 
            let lessons = {}; 
            const bookName = object[firstVerse]['book_full_name'].split(" ").join("").toLowerCase()
            const ifCompleted = object[firstVerse]['completed'] && object[firstVerse]['completed'] == '1' ? 1 : 0
            const errorKeysFilter = errorKeys.filter(word => word.trim().length > 0);
            // sessionStorage.setItem("book-verse-completed-percent-"+object[firstVerse]["book"], Math.round((firstVerse/object.length)*100));
            lessons = { bookName: bookName, bookId: object[firstVerse]['book'], errorKeys:errorKeysFilter.toString(), vid: object[firstVerse]['vid'], completed: ifCompleted, chapter:object[firstVerse]['chapter'], verse:object[firstVerse]['verse'], totalVerses:object.length}
            sessionStorage.setItem("errorKeysLocal", errorKeysFilter);
            sessionStorage.setItem("settingsLastBibleVerseId", object[firstVerse]['id']);
            //lessons.book
            //sessionSetItem(object[firstVerse]['code'], lessons)
            const token = sessionStorage.getItem('token')
            const saveLesson = async() => {
                const response = await fetch('/api/users/updateStats', {
                    method: 'POST',
                    body: JSON.stringify(lessons),
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        //'Lesson': params.lesson,
                        'Authorization':'Bearer '+token
                    }
                })
                const json = await response.json();
                console.log(json)
                if(!response.ok){ 
                    console.log(json.error) 
                    if(json.error === "jwt expired"){ //jwt malformed
                    //if(json.error === "jwt malformed"){  // not jwt token
                        auth.logout();
                        navigate("/page/login/se");
                        return;
                    }
                    
                    //navigate("/lessons/basic/"+lesson_number)
                }
                if(response.ok){ console.log(json) }
            }
            saveLesson()
            if(ifCompleted == 1){
                document.querySelector(".quote-result-card").classList.remove('quote-result-card-hide');
                document.querySelector(".quote-result-card").classList.add('quote-result-card-show');
                document.querySelector("#did_know").innerHTML = stra;
            }
            
            
            if(object.length - 1 == firstVerse){ 
                getBible() 
                firstVerse = 0
            }  else {
                setFirstVerse(firstVerse++);
                document.querySelector(".main-progress-percent-book").style.width = Math.round((firstVerse/object.length)*100)+"%";

                sessionStorage.setItem("book-verse-"+object[firstVerse]["book"], object[firstVerse]["vid"]);
                str = [...object[firstVerse][version]];
                stra = object[firstVerse][version];

                strLength = str.length;
                //document.querySelector(".main-text").innerHTML = '';
                verse = str.map((v, index) => {
                    return '<div id="chr_'+index+'" className="verse">'+v+'</div>';
                })
                setFirstVerseText(verse.join(""));
                setMainTitle(object[firstVerse]['book_full_name']+' '+object[firstVerse]['chapter']+':'+object[firstVerse]['verse'])

            }
            
            if(!object[firstVerse]){
                console.log('frist ver NOT exit')
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            
            key = 0;
            console.log(key, strLength, "1")
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }
    
    //document.addEventListener("keydown", testCapsLock);
    //document.addEventListener("keyup", testCapsLock);
    const handleKeydownMain = (e) => { 
        if(key == 1) { start(); }
        console.log("scroll text", key)

        
        //nextk.current = stra[key+1];
        //nextk.current = stra[key+1];
        //console.log('ckk', nextk.current)
        if(e.keyCode === 16){ return; } //Shift Key  
        if(e.keyCode === 20){ //CapsLock
            caps = 1;
            console.log('key 20')

        } 
        
        if(e.code === "CapsLock"){
            let isCapsLockOn = e.getModifierState("CapsLock");
            if(isCapsLockOn) {
                caps =  1;
            } else {
                caps = 0;
            }
        }
        //CapsLockOverlay(e, caps);
        if(caps){
            console.log('caps on');
            document.querySelector(".overlay").classList.remove('overlay-hide');
            document.querySelector(".overlay").classList.add('overlay-show');
            //e.preventDefault();
            //e.stopPropagation();
            //return false;
        } else {
            document.querySelector(".overlay").classList.add('overlay-hide');
        }
        //CapsLockOverlay(e, caps);
        
           
        if(e.keyCode === 27){ return; } //Escape Key s
        if(e.keyCode === undefined){ return; }
        console.log(document.querySelector(".main-text").scrollHeight, 'jkjkjk')
        //console.log(e)
        const element = document.querySelector(".main-text");

        //element.scrollIntoView();
        //element.scrollIntoView(false);
        //element.scrollIntoView({ block: "center" });
        //element.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
        if(key == 136){
            element.scrollBy({
                top: 125,
                left: 0,
                behavior: "smooth",
              });
        } else if (key == 272){
            element.scrollBy({
                top: 200,
                left: 0,
                behavior: "smooth",
              });
        } else if (key == 400){
            element.scrollBy({
                top: 300,
                left: 0,
                behavior: "smooth",
              });
        }

        if(e.keyCode === 32){ 
            console.log('space bar'); //return;
            e.preventDefault();
         }
  
        KeyboardActiveKey(stra[key+1]);

        

        document.querySelector("#chr_0").classList.remove('cursor');
        document.querySelector(".text").classList.remove('tooltip');
        document.querySelector(".text").classList.add('tooltip-hide');
        //stats
        //let red = 0;
        let red = document.querySelectorAll(".red").length;
        
        accuracy = (100 - Math.round((red/key)*100));
        accuracy = isNaN(accuracy) ? 0 : accuracy;
        const avgWords = (key / 5)
        let wpm = Math.round(avgWords * (60 / seconds))
        wpm = (wpm == 'Infinity') ? 0 : wpm
        const avgNetWords = ((key-red) / 5)
        let nwpm = Math.round(avgNetWords * (60 / seconds))
        nwpm = (nwpm == 'Infinity') ? 0 : nwpm

        document.querySelector("#errorKey").innerText = red;
        document.querySelector("#accuracy").innerText = accuracy+'%';
        document.querySelector("#totalTime").innerText = seconds;
        document.querySelector("#gwpm").innerText = isNaN(wpm) ? 0 : wpm;
        document.querySelector("#nwpm").innerText = isNaN(nwpm) ? 0 : nwpm;

        // backspace typing
        if(e.keyCode == 8){ 
            if(key != 0){
                document.querySelector("#chr_"+key).classList.remove('cursor');
                --key; 
            }

            document.querySelector("#chr_"+key).classList.remove('red');
            document.querySelector("#chr_"+key).classList.remove('green');
            document.querySelector("#chr_"+key).classList.add('cursor');
            
            
            if(key == 0){
                return;
            }
            return;
        }
        console.log('Here2',e.key,stra[key], stra); 
        if(e.key == stra[key]){
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#keyAudio").play() }
            end();
            document.querySelector("#chr_"+key).classList.add('green');
            document.querySelector("#chr_"+key).classList.remove('cursor');
            console.log(e.key, stra[key])
            key++;
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }
            if(e.key != 'Enter'){
                //redFlag++; 
            }
            return;
        } 
        if(e.key != stra[key]){ 
            if(sessionStorage.getItem("settingsKeyboardSound") == "On") { document.querySelector("#errorKeyAudio").play() }
            if(stra[key] !== undefined){
                errorKeys.push(stra[key])
            }
            
            //console.log('error Key',e.key,stra[key], "keys:",errorKeys); 
            end();
            const el1 = document.querySelector("#chr_"+key);
            console.log(el1); 
            if (el1 == null) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            el1.classList.add('red');
            el1.classList.remove('cursor');
            console.log('Here', e.key)
            key++;
            if(key != strLength){
                document.querySelector("#chr_"+key).classList.add('cursor');
            }

            return;
        }
        
        
        
    }
    
    return (
    <div className="top">
        <div className="main column middle">
            
            <div className="main-title">
                <h4 className="bg-main-title">{mainTitle}</h4>
                
                <span className="material-symbols-outlined main-setting-icon " onClick={handleClickSettings}>add_circle</span>
            </div>
             
            <div className="main-text">
                { parse('<div className="text"><span class="tooltiptext main-tooltiptext">Start Typing!</span>'+firstVerseText+'</div>') }
                
            </div>
            <p className="blog-txt"><a  href="/blog/why-typing-bible">Why should I type Bible?</a></p>
            <div className="main-progress-bar-book progress-bar-striped nav-bg8 lesson-main-stats-section">
                <div className="main-progress-bar-text">
                    {bookFullName}: {v?(v-1):0} Verses Typed, Total Verses {totalBookVerse}, {Math.round(((v - 1)/totalBookVerse)*100)}% Complete
                </div>
                <div className={"main-progress-percent-book progress-bar-striped progress-bar-animated"}></div>
            </div>
            <div className="stats-main-bar-v">
                <StatsMainBarV />
                
            </div>
            {/*keyboard start*/}
                <Keyboard KeyboardFrom="main"></Keyboard>
            {/*keyboard end*/}
            
 

            {/* main setting modal*/}
            <MainSettings />
            
     


            <div className="quote-result-card">
                <div className="result-card-box">
                    <h3>Congratulations!!</h3>
                    <p>
                        <img className="star-l" src="../../star-congrats-1.svg" alt=""></img>
                        <img className="star-m" src="../../star-congrats-1.svg" alt=""></img>
                        <img className="star-r" src="../../star-congrats-1.svg" alt=""></img>
                    </p>
                    
                    { <p className="result-card-paraZ">Awesome! You Just Typed This Book!!
                     </p>  }
                     <p id="did_know" className="did-know">
                         
                    </p>
                    <p id="did_know" className="">
                        <a href="/books" className="button nav-bg8"> Click Here to Pick Another Book.</a>
                    </p>                   
                </div>
            </div>
        </div>
        <div className="column rside">
            <ins class="adsbygoogle hm-ad"
            data-ad-client="ca-pub-8526815875736406"
            data-ad-slot="8132562927"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>
    </div>
    )
}

export default Main;