export default function Terms() {
    return (
      <div className="page">
        <h2 className="page-title bg-main-title-99"><span className="material-symbols-outlined">note</span> Terms & Conditions</h2>
        <div className="page-text">
          <p>You may become a member ("user") of this site by affirming and stating that you have read, understood and agreed to all the following terms and conditions.</p>
          <p>In order to use any of the Services, you must have access to the world wide web, either directly or through devices that access web-based content.</p>
          <p>You agree to indemnify and hold the company, and its subsidiaries, affiliates, officers, employees, agents, co-branders or other partners, harmless from any claim or demand, including attorneys’ fees, made by any third party due to or arising out of content you submit, post to or transmit through the service, your use of the service, your connection to the service, your violation of the TOS, or your violation of any rights of another. You further agree that in the event of any action or claim brought against the company due to or arising out of any content you submit, post to or transmit through the service, your use of the service, your connection to the service, your violation of the TOS, or your violation of any rights of another, you will reimburse the company for its reasonable attorneys’ fees and costs incurred in the defense of such action or claim.</p>
          <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the service, use of the service</p>
          <p>You agree that the company may at any time, at its sole discretion, modify the Service (or any part thereof) with or without notice.</p>
          <p>We may suspend any user’s access to any or all Services without notice</p>
          <p>TYPLOGOS.com reserves the right to modify these Terms at any time.</p>
          <p>The service may provide, or third parties may provide, links to other world wide web sites or resources. Because the company has no control over such sites and resources, you acknowledge and agree that the company is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products, services, information, or other materials on or available from such sites or resources. You further acknowledge and agree that the company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by, or in connection with, use of or reliance on any such content, goods or services available on or through any such site or resource.</p>
          <p>We welcome feedback, comments, and suggestions for improvements to the platform. You may submit feedback by emailing us at typlogos@gmail.com</p>
        </div>
     </div>
    )
  }