import React, {useEffect, useState} from "react";
import {useNavigate } from "react-router-dom";

const Users = () => {
    let [users, setUsers] = useState(null)
    const navigate = useNavigate();
    const getUsers = async () => { 
        
        //const user = {email: emailSignup,password: passwordSignup}
        const token = sessionStorage.getItem('token')
        const response = await fetch('/api/users', {
            method: 'GET',
            //body: 'quote',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization':'Bearer '+token
            }
        })
        const json = await response.json();
        console.log(response.status)
        if(!response.ok){
            console.log(json)
            navigate("/page/notfound")
            
        }
        if(response.status == 400){
            navigate("/page/notfound")
            console.log("in to 400")
            return
        } console.log("in to 40011")
        if(response.ok){
            console.log(json)
            if(response.status == 400){
                navigate("/page/notfound")
                return
            }
            setUsers(json)

            try {
 
            } catch (err){
                console.log('catch error', err.message);
                navigate("/page/notfound")
            }
            
        }

    }
    useEffect(() => {
        getUsers();
    }, [])

    return (
        users ? <div className="main" style={{fontSize: "0.75rem"}}>
            <div className="main-title">Users List</div>
            <table style={{margin: "0 auto", lineHeight:"15px"}}>
                <thead>
                    <tr>
                    <th>No</th>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Type</th>
                    </tr>
                </thead>
                    {
                        users && <UsersList users={users}  />
                    }
            </table>
        </div>
        : <div className="main"><h3>Not authorized or Page not found!!</h3></div>
    )
}
const UsersList = (props) => {
    let users = props.users
    console.log("in the list", users)
    let i = 1
    return (
        <tbody>
        {
            users.map((u) => (
                
                <tr key={u._id}>
                    <td>{u.i} { i++ }</td>
                    <td>{u.email}</td>
                    <td>{u.email}</td>
                    <td>{u.type}</td>
                </tr>
             
            ))
           
        }
        </tbody>
        
    )
}
export default Users;